var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                display: "block",
                "margin-bottom": "10px",
                "font-size": "14px",
              },
            },
            [
              _c("font", { attrs: { color: "red" } }, [
                _vm._v("[" + _vm._s(_vm.$t("北京时间")) + "]"),
              ]),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.queryForm, inline: true },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("账户名"),
                    },
                    model: {
                      value: _vm.queryForm.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "userName", $$v)
                      },
                      expression: "queryForm.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("注单号"),
                    },
                    model: {
                      value: _vm.queryForm.sourceId,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "sourceId", $$v)
                      },
                      expression: "queryForm.sourceId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("局号"),
                    },
                    model: {
                      value: _vm.queryForm.roundId,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "roundId", $$v)
                      },
                      expression: "queryForm.roundId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      clearable: "",
                      "default-time": "12:00:00",
                      placeholder: _vm.$t("开始时间"),
                    },
                    model: {
                      value: _vm.queryForm.beginTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "beginTime", $$v)
                      },
                      expression: "queryForm.beginTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      clearable: "",
                      "default-time": "11:59:59",
                      placeholder: _vm.$t("结束时间"),
                    },
                    model: {
                      value: _vm.queryForm.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "endTime", $$v)
                      },
                      expression: "queryForm.endTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { placeholder: _vm.$t("时间类型") },
                      model: {
                        value: _vm.queryForm.timeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "timeType", $$v)
                        },
                        expression: "queryForm.timeType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("创建时间"), value: "create" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("结算时间"), value: "correct" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { clearable: "", placeholder: _vm.$t("游戏") },
                      model: {
                        value: _vm.queryForm.gameId,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "gameId", $$v)
                        },
                        expression: "queryForm.gameId",
                      },
                    },
                    _vm._l(_vm.gamesList, function (item, index) {
                      return _c("el-option", {
                        attrs: {
                          label: _vm.$t(item.name),
                          value: item.game_type,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.isAdmin
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("渠道"),
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryForm.channel,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "channel", $$v)
                            },
                            expression: "queryForm.channel",
                          },
                        },
                        _vm._l(_vm.channelList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("代理"),
                    },
                    model: {
                      value: _vm.queryForm.proxy,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "proxy", $$v)
                      },
                      expression: "queryForm.proxy",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("币种") },
                      model: {
                        value: _vm.queryForm.currency,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "currency", $$v)
                        },
                        expression: "queryForm.currency",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("全部币种"), value: 0 },
                      }),
                      _vm._l(_vm.currencyList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.onSearch },
                    },
                    [_vm._v(_vm._s(_vm.$t("搜索")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.onReset },
                    },
                    [_vm._v(_vm._s(_vm.$t("清空条件")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-download",
                        size: "mini",
                      },
                      on: { click: _vm.onOutput },
                    },
                    [_vm._v(_vm._s(_vm.$t("导出")))]
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("统计维度") } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupChannel,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupChannel", $$v)
                        },
                        expression: "queryForm.groupChannel",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("渠道")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupCurrency,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupCurrency", $$v)
                        },
                        expression: "queryForm.groupCurrency",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("币种")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupProxy,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupProxy", $$v)
                        },
                        expression: "queryForm.groupProxy",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("代理")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupUserName,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupUserName", $$v)
                        },
                        expression: "queryForm.groupUserName",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("用户名称")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupDate", $$v)
                        },
                        expression: "queryForm.groupDate",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("按日期")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "45px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.tableData.list,
            height: "480px",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "sourceId",
              label: _vm.$t("注单号"),
              "min-width": "120",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer", color: "#409EFF" },
                        on: {
                          click: function ($event) {
                            return _vm.onOpenDetail(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.sourceId))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "userName",
              label: _vm.$t("用户名"),
              "min-width": "130",
              sortable: "",
              "show-overflow-tooltip": "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "channel",
              label: _vm.$t("渠道"),
              "min-width": "90",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "agentName",
              label: _vm.$t("代理"),
              "min-width": "90",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "gameName",
              label: _vm.$t("游戏"),
              "min-width": "100",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.$t(scope.row.gameName)))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "currency",
              label: _vm.$t("币种"),
              "min-width": "120",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.renderCurrency(scope.row.currency))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "roundId",
              label: _vm.$t("局号"),
              "min-width": "180",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "roomId",
              label: _vm.$t("房号"),
              "min-width": "80",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "betTime",
              label: _vm.$t("创建时间"),
              "min-width": "150",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "amount",
              label: _vm.$t("投注额"),
              "min-width": "120",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.formatMoneyToLocal(Number(scope.row.amount)))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "commamount",
              label: _vm.$t("有效投注额"),
              "min-width": "160",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.formatMoneyToLocal(scope.row.commamount))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "userResult",
              label: _vm.$t("游戏输赢"),
              "min-width": "140",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.formatMoneyToLocal(scope.row.userResult))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "serviceCharge",
              label: _vm.$t("服务费/抽水"),
              "min-width": "140",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "result",
              label: _vm.$t("实际输赢"),
              "min-width": "140",
              sortable: "",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-content" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryForm.page,
              "page-sizes": [10, 20, 30, 40, 50, 100],
              "page-size": _vm.queryForm.limit,
              total: parseInt(_vm.tableData.total),
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "45px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.totalList,
            "show-summary": "",
            "summary-method": _vm.getTotal,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "CCYNAME",
              label: _vm.$t("币种"),
              "min-width": "120",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.renderCurrency(scope.row.CCYNAME))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rounds",
              label: _vm.$t("总局数"),
              "min-width": "140",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "amount",
              label: _vm.$t("投注总额"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatCurrency(scope.row.amount))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "commamount",
              label: _vm.$t("有效投注总额"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatCurrency(scope.row.commamount))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "userResult",
              label: _vm.$t("游戏输赢总额"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatCurrency(scope.row.userResult))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "serviceCharge",
              label: _vm.$t("服务费/抽水总额"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.formatCurrency(scope.row.serviceCharge))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "result",
              label: _vm.$t("实际输赢总额"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatCurrency(scope.row.result))),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }