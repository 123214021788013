"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _gameResult = require("./utils/gameResult");
//
//
//
//
//
//
var _default = exports.default = {
  name: 'App',
  data: function data() {
    return {
      isRouterRefresh: true
    };
  },
  provide: function provide() {
    return {
      reload: this.reload
    };
  },
  mounted: function mounted() {
    var langType = localStorage.getItem('lang') || 'zh';
    this.$store.dispatch('setLang', langType);

    // let a = translateGame('0', '15', 'en');  // 轮盘
    // let b = "[{\"fullNiu\":false,\"hasNiu\":false,\"niuIndex\":[],\"point\":0,\"ratio\":0.0},{\"fullNiu\":false,\"hasNiu\":true,\"isWin\":true,\"niuIndex\":[0,1,2],\"point\":3,\"ratio\":0.95,\"win\":true},{\"fullNiu\":false,\"hasNiu\":false,\"isWin\":false,\"niuIndex\":[],\"point\":0,\"ratio\":-1.0,\"win\":false},{\"fullNiu\":false,\"hasNiu\":true,\"isWin\":true,\"niuIndex\":[0,2,4],\"point\":8,\"ratio\":1.9,\"win\":true}]";
    // let c = "8-4-0;8-2-1;4-1-2;3-1-3;2-2-4;3-2-5";
    // let cc = bankerCard(c);

    var scriptList = document.getElementsByTagName('script');
    var version = '';
    for (var i = 0; i < scriptList.length; i++) {
      version = scriptList[i]['src'].includes('release') ? scriptList[i]['src'].split('release-')[1].replace('.js', '') : '0.0.0.0';
    }
  },
  methods: {
    reload: function reload() {
      var _this = this;
      this.isRouterRefresh = false;
      this.$nextTick(function () {
        _this.isRouterRefresh = true;
      });
    }
  }
};