"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _betFailReport = require("@/api/report/betFailReport");
var _common = require("@/api/common");
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 游戏记录查询
var _default = exports.default = {
  name: 'betFailReport',
  props: {},
  data: function data() {
    return {
      channelList: [],
      loading: false,
      tableData: {
        list: [],
        total: 0,
        totalPage: 1
      },
      queryForm: {
        page: 1,
        limit: 10,
        loginName: '',
        round: '',
        beginTime: '',
        endTime: '',
        channel: '',
        fail: '',
        timeType: 'create'
      }
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['isAdmin'])),
  mounted: function mounted() {
    this.getChannelList();
  },
  methods: (0, _defineProperty2.default)((0, _defineProperty2.default)({
    onSearch: function onSearch() {
      this.queryForm.page = 1;
      this.getList();
    },
    onReset: function onReset() {
      this.queryForm = {
        page: 1,
        limit: 10,
        loginName: '',
        round: '',
        beginTime: '',
        endTime: '',
        game: '',
        channel: '',
        fail: '',
        timeType: 'create'
      };
    },
    checkParams: function checkParams() {
      var _this$queryForm = this.queryForm,
        loginName = _this$queryForm.loginName,
        round = _this$queryForm.round,
        beginTime = _this$queryForm.beginTime,
        endTime = _this$queryForm.endTime;
      if (!loginName && !round && !round && (!beginTime || !endTime)) {
        this.$message.error(this.$t('用户名、注单号、局号、时间范围必填其一'));
        return false;
      }

      // let beginTimeT = lodash.cloneDeep(beginTime);
      // let endTimeT = lodash.cloneDeep(endTime);

      // let timeDiff = (new Date(endTimeT)).getTime() - (new Date(beginTimeT)).getTime();
      // let formatTimeDiff = timeDiff / (3600 * 1000 * 24);
      // if (formatTimeDiff > 2) {
      //     this.$message.error(this.$t('时间范围只能在2天内'));
      //     return false;
      // }
      return true;
    },
    getList: function getList() {
      var _this = this;
      this.loading = true;
      (0, _betFailReport.getList)(this.queryForm).then(function (res) {
        _this.loading = false;
        if (res.code == 200) {
          _this.tableData = {
            list: res.data,
            total: res.count,
            totalPage: 1
          };
        } else {
          _this.$message.error(_this.$t(res.message));
          _this.tableData = {
            list: [],
            total: 0,
            totalPage: 1
          };
        }
      }).catch(function (err) {
        _this.loading = false;
        _this.tableData = {
          list: [],
          total: 0,
          totalPage: 1
        };
      });
    },
    // 页码size 改变
    handleSizeChange: function handleSizeChange(size) {
      setTimeout(function () {
        window.scrollTo({
          left: 0,
          top: 0
        });
      }, 10);
      this.queryForm.limit = size;
      this.queryForm.page = 1;
      this.getList();
    },
    // 分页数据改变
    handleCurrentChange: function handleCurrentChange(val) {
      window.scrollTo({
        left: 0,
        top: 0
      });
      this.queryForm.page = val;
      this.getList();
    },
    getChannelList: function getChannelList() {
      var _this2 = this;
      (0, _common.getChannel)().then(function (res) {
        if (res.code == 200) {
          _this2.channelList = res.data;
        } else {
          _this2.$message.error(_this2.$t(res.message));
          _this2.channelList = [];
        }
      }).catch(function (err) {
        _this2.channelList = [];
      });
    },
    // 退款操作
    handleRefund: function handleRefund(item) {
      var _this3 = this;
      this.$confirm(this.$t('确定退款'), this.$t('信息'), {
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        type: 'warning'
      }).then(function () {
        (0, _betFailReport.betRefund)({
          id: item.id
        }).then(function (res) {
          if (res) {
            _this3.$message.success(_this3.$t('操作成功'));
            _this3.getList();
          } else {
            _this3.$message.error(_this3.$t(res.message));
          }
        });
      }).catch(function (err) {
        console.log(err);
      });
    }
  }, "handleRefund", function handleRefund(item) {
    var _this4 = this;
    this.$confirm(this.$t('确定退款'), this.$t('信息'), {
      confirmButtonText: this.$t('确定'),
      cancelButtonText: this.$t('取消'),
      type: 'warning'
    }).then(function () {
      (0, _betFailReport.betRefund)({
        id: item.id
      }).then(function (res) {
        if (res) {
          _this4.$message.success(_this4.$t('操作成功'));
          _this4.getList();
        } else {
          _this4.$message.error(_this4.$t(res.message));
        }
      });
    }).catch(function (err) {
      console.log(err);
    });
  }), "reconcileAgain", function reconcileAgain(item) {
    var _this5 = this;
    this.$confirm(this.$t('确定重推消息'), this.$t('信息'), {
      confirmButtonText: this.$t('确定'),
      cancelButtonText: this.$t('取消'),
      type: 'warning'
    }).then(function () {
      (0, _betFailReport.betFailReport)({
        id: item.id
      }).then(function (res) {
        if (res) {
          _this5.$message.success(_this5.$t('操作成功'));
          _this5.getList();
        } else {
          _this5.$message.error(_this5.$t(res.message));
        }
      });
    }).catch(function (err) {
      console.log(err);
    });
  })
};