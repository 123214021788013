"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.array.includes");
var _machinePrinterConfig = require("@/api/report/machinePrinterConfig");
var _lodash = _interopRequireDefault(require("lodash"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var _this;
var _default = exports.default = {
  name: 'machinePrinterConfig',
  props: {},
  data: function data() {
    return {
      formModel: {
        condition1: true,
        condition2: true,
        condition21: true,
        percent1: 0.91,
        gameCount1: 50,
        condition22: true,
        percent2: 0.1,
        gameCount2: 50,
        evCulValue: -0.0084,
        condition3: true,
        betTime: 20,
        condition4: true,
        onLineTime: 22,
        condition5: true,
        fastBetCount: 3,
        condition6: true,
        gameCount3: 2001,
        enable: true,
        remark: ''
      },
      dialog: false,
      loading: false,
      tableData: {
        list: [],
        total: 0,
        totalPage: 1
      }
    };
  },
  computed: {},
  watch: {},
  beforeCreate: function beforeCreate() {
    _this = this;
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    editHandle: function editHandle(row) {
      this.formModel = _lodash.default.cloneDeep(row);
      this.dialog = true;
    },
    getParams: function getParams(data) {
      var obj = {};
      var keyArr = [];
      for (var i in data) {
        if (keyArr.includes(i) && (data[i] instanceof String && !data[i] || data[i] instanceof Array && data[i].length == 0)) {
          obj[i] = '';
        } else {
          obj[i] = data[i];
        }
      }
      return obj;
    },
    parseParam: function parseParam(param) {
      var paramStr = '';
      Object.keys(param).forEach(function (key, index) {
        if (index === 0) {
          paramStr += key + "=" + encodeURIComponent(param[key]);
        } else {
          paramStr += "&" + key + "=" + encodeURIComponent(param[key]);
        }
      });
      return paramStr;
    },
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      (0, _machinePrinterConfig.getList)().then(function (res) {
        _this2.loading = false;
        if (res.code == 200) {
          _this2.tableData = {
            list: [res.data]
          };
        } else {
          _this2.$message.error(_this2.$t(res.message));
          _this2.tableData = {
            list: []
          };
        }
      }).catch(function (err) {
        _this2.loading = false;
        _this2.tableData = {
          list: []
        };
      });
    },
    cancelForm: function cancelForm() {
      this.dialog = false;
      this.formModel = {
        condition1: true,
        condition2: true,
        condition21: true,
        percent1: 0.91,
        gameCount1: 50,
        condition22: true,
        percent2: 0.1,
        gameCount2: 50,
        evCulValue: -0.0084,
        condition3: true,
        betTime: 20,
        condition4: true,
        onLineTime: 22,
        condition5: true,
        fastBetCount: 3,
        condition6: true,
        gameCount3: 2001,
        enable: true,
        remark: ''
      };
    },
    checkParams: function checkParams() {
      var _this3 = this;
      if (this.formModel.condition21 && (!this.formModel.percent1 || !this.formModel.gameCount1)) {
        this.$message.error(this.$t('EV投注百分比(大于等于)勾选后，百分比和局数必填'));
        return false;
      }
      if (this.formModel.condition22 && (!this.formModel.percent2 || !this.formModel.gameCount2)) {
        this.$message.error(this.$t('EV投注百分比(小于)勾选后，百分比和局数必填'));
        return false;
      }
      if (this.formModel.condition3 && !this.formModel.betTime) {
        this.$message.error(this.$t('投注时长勾选后，时长必填'));
        return false;
      }
      if (this.formModel.condition4 && !this.formModel.onLineTime) {
        this.$message.error(this.$t('在线时长勾选后，时长必填'));
        return false;
      }
      if (this.formModel.condition5 && !this.formModel.fastBetCount) {
        this.$message.error(this.$t('短投注次数勾选后，次数必填'));
        return false;
      }
      if (this.formModel.condition6 && !this.formModel.gameCount3) {
        this.$message.error(this.$t('当日局数勾选后，局数必填'));
        return false;
      }
      var arr = [];
      var keysArr = ['condition', 'evCulValue'];
      Object.keys(this.formModel).forEach(function (key) {
        if (key.includes(keysArr[0]) || key.includes(keysArr[1])) {
          arr.push(_this3.formModel[key] ? true : false);
        }
      });
      if (arr.includes(true)) {
        return true;
      } else {
        this.$message.error(this.$t('以上所有条件为并列条件，至少选择一个配置项'));
        return false;
      }
    },
    onSave: function onSave() {
      var _this4 = this;
      if (!this.checkParams()) {
        return;
      }
      var params = this.parseParam(this.getParams(this.formModel));
      this.loading = true;
      (0, _machinePrinterConfig.updateConfig)(params).then(function (res) {
        _this4.loading = false;
        if (res.code == 200) {
          _this4.cancelForm();
          _this4.getList();
        } else {
          _this4.$message.error(_this4.$t(res.message));
        }
      }).catch(function (err) {
        _this4.loading = false;
      });
    }
  }
};