"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSubAccount = addSubAccount;
exports.deleteSubAccount = deleteSubAccount;
exports.subAccountList = subAccountList;
exports.unLockSubAccount = unLockSubAccount;
exports.updatSubAccount = updatSubAccount;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取列表数据
function subAccountList(params) {
  return (0, _request.default)({
    url: "/yx/sonAccountMg/list",
    method: 'GET',
    params: params
  });
}

// 添加
function addSubAccount(params) {
  return (0, _request.default)({
    url: '/yx/sonAccountMg/add',
    method: 'POST',
    params: params
  });
}

// 修改
function updatSubAccount(params) {
  return (0, _request.default)({
    url: '/yx/sonAccountMg/update',
    method: 'POST',
    params: params
  });
}

// 删除
function deleteSubAccount(params) {
  return (0, _request.default)({
    url: '/yx/sonAccountMg/delete',
    method: 'POST',
    params: params
  });
}

// 解锁子账号
function unLockSubAccount(params) {
  return (0, _request.default)({
    url: '/yx/channelMg/unLock',
    method: 'GET',
    showLoading: true,
    params: params
  });
}