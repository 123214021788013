"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("./components");
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: "Layout",
  components: {
    AppMain: _components.AppMain,
    Navbar: _components.Navbar,
    Sidebar: _components.Sidebar,
    TagsView: _components.TagsView
  },
  data: function data() {
    return {
      isCollapse: false
    };
  },
  mounted: function mounted() {},
  methods: {
    changeCol: function changeCol(val) {
      this.isCollapse = val;
    }
  }
};