var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                display: "block",
                "margin-bottom": "10px",
                "font-size": "14px",
              },
            },
            [
              _c("font", { attrs: { color: "red" } }, [
                _vm._v("[" + _vm._s(_vm.$t("北京时间")) + "]"),
              ]),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.queryForm, inline: true },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("账户名"),
                    },
                    model: {
                      value: _vm.queryForm.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "userName", $$v)
                      },
                      expression: "queryForm.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("注单号"),
                    },
                    model: {
                      value: _vm.queryForm.note,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "note", $$v)
                      },
                      expression: "queryForm.note",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("局号"),
                    },
                    model: {
                      value: _vm.queryForm.round,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "round", $$v)
                      },
                      expression: "queryForm.round",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      clearable: "",
                      "default-time": "12:00:00",
                      placeholder: _vm.$t("开始时间"),
                    },
                    model: {
                      value: _vm.queryForm.beginTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "beginTime", $$v)
                      },
                      expression: "queryForm.beginTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      clearable: "",
                      "default-time": "11:59:59",
                      placeholder: _vm.$t("结束时间"),
                    },
                    model: {
                      value: _vm.queryForm.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "endTime", $$v)
                      },
                      expression: "queryForm.endTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { placeholder: _vm.$t("时间类型") },
                      model: {
                        value: _vm.queryForm.timeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "timeType", $$v)
                        },
                        expression: "queryForm.timeType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("创建时间"), value: "create" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("结算时间"), value: "correct" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 0, "false-label": 1 },
                      model: {
                        value: _vm.queryForm.gameRange,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "gameRange", $$v)
                        },
                        expression: "queryForm.gameRange",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("包含活动")))]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-cascader", {
                    attrs: {
                      placeholder: _vm.$t("请选择桌台"),
                      props: { multiple: true, label: "label" },
                      "collapse-tags": "",
                      clearable: "",
                      "show-all-levels": false,
                      options: _vm.tableIdsList,
                    },
                    model: {
                      value: _vm.queryForm.tableIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "tableIds", $$v)
                      },
                      expression: "queryForm.tableIds",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { placeholder: _vm.$t("游戏") },
                      model: {
                        value: _vm.queryForm.game,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "game", $$v)
                        },
                        expression: "queryForm.game",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("全部游戏"), value: "" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("百家乐"), value: "BACCARAT" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("龙虎"), value: "DRAGON-TIGER" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("轮盘"), value: "ROULETTE" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("骰宝"), value: "SIC-BO" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("牛牛"), value: "DOU-NIU" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("炸金花"), value: "WIN3CARDS" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isAdmin
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("渠道"),
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryForm.channel,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "channel", $$v)
                            },
                            expression: "queryForm.channel",
                          },
                        },
                        _vm._l(_vm.channelList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("代理"),
                    },
                    model: {
                      value: _vm.queryForm.proxy,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "proxy", $$v)
                      },
                      expression: "queryForm.proxy",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("币种") },
                      model: {
                        value: _vm.queryForm.currency,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "currency", $$v)
                        },
                        expression: "queryForm.currency",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("全部币种"), value: 0 },
                      }),
                      _vm._l(_vm.currencyList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.isAdmin
                ? _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        staticStyle: { width: "160px" },
                        attrs: {
                          clearable: "",
                          size: "small",
                          placeholder: "IP",
                        },
                        model: {
                          value: _vm.queryForm.ip,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "ip", $$v)
                          },
                          expression: "queryForm.ip",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdmin
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { placeholder: _vm.$t("风险分") },
                          model: {
                            value: _vm.queryForm.betRiskScore,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "betRiskScore", $$v)
                            },
                            expression: "queryForm.betRiskScore",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: _vm.$t("全部"), value: "" },
                          }),
                          _c("el-option", {
                            attrs: { label: _vm.$t("大于0"), value: 1 },
                          }),
                          _c("el-option", {
                            attrs: { label: _vm.$t("等于0"), value: 0 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.onSearch },
                    },
                    [_vm._v(_vm._s(_vm.$t("搜索")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.onReset },
                    },
                    [_vm._v(_vm._s(_vm.$t("清空条件")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-download",
                        size: "mini",
                      },
                      on: { click: _vm.onOutput },
                    },
                    [_vm._v(_vm._s(_vm.$t("导出")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "45px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.tableData.list,
            height: "480px",
          },
        },
        [
          _vm.isAdmin
            ? [
                _c("el-table-column", {
                  attrs: {
                    prop: "id",
                    label: _vm.$t("注单号"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            scope.row.result && scope.row.status !== "C"
                              ? _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#409EFF",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onOpenDetail(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(scope.row.id))]
                                )
                              : _c("span", [_vm._v(_vm._s(scope.row.id))]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    957405352
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "status",
                    label: _vm.$t("状态"),
                    "min-width": "100",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.getStatus(scope.row.status))),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1792734257
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "userName",
                    label: _vm.$t("用户名"),
                    "min-width": "200",
                    sortable: "",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "channel",
                    label: _vm.$t("渠道"),
                    "min-width": "100",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "agent",
                    label: _vm.$t("代理"),
                    "min-width": "100",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "gameType",
                    label: _vm.$t("游戏"),
                    "min-width": "120",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.getGameName(scope.row.gameType))
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3962082990
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "currency",
                    label: _vm.$t("币种"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.renderCurrency(scope.row.currency))
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3937835341
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "roundId",
                    label: _vm.$t("局号"),
                    "min-width": "180",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#409EFF",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onOpenVideo(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(_vm.shoeRound(scope.row)))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    684664177
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "createTime",
                    label: _vm.$t("创建时间"),
                    "min-width": "160",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "CORRECTTIME",
                    label: _vm.$t("结算时间"),
                    "min-width": "160",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "enterType",
                    label: _vm.$t("多台"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.$t(scope.row.enterType))),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2033831911
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "tableNum",
                    label: _vm.$t("桌号"),
                    "min-width": "160",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.getTableName(scope.row.tableNum))
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1452959328
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "result",
                    label: _vm.$t("结果"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.renderResult(scope.row))),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    978920906
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "betamount",
                    label: _vm.$t("投注额"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatMoneyToLocal(
                                    Number(scope.row.betamount)
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3018174047
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "validBetAmount",
                    label: _vm.$t("有效投注额"),
                    "min-width": "180",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatMoneyToLocal(
                                    scope.row.validBetAmount
                                  )
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3384552075
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "money",
                    label: _vm.$t("游戏输赢"),
                    "min-width": "160",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.formatMoneyToLocal(scope.row.money))
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1284456018
                  ),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "ip",
                    label: "IP",
                    "min-width": "200",
                    sortable: "",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "location",
                    label: _vm.$t("坐标"),
                    "min-width": "120",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "riskType",
                    label: _vm.$t("风险类型"),
                    "min-width": "120",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "betRiskScore",
                    label: _vm.$t("风险分数"),
                    "min-width": "140",
                    sortable: "",
                  },
                }),
              ]
            : [
                _c("el-table-column", {
                  attrs: {
                    prop: "id",
                    label: _vm.$t("注单号"),
                    "min-width": "160",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          scope.row.result && scope.row.status !== "C"
                            ? _c(
                                "span",
                                {
                                  staticStyle: {
                                    cursor: "pointer",
                                    color: "#409EFF",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onOpenDetail(scope.row)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(scope.row.id))]
                              )
                            : _c("span", [_vm._v(_vm._s(scope.row.id))]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "status",
                    label: _vm.$t("状态"),
                    "min-width": "100",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.getStatus(scope.row.status))),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "userName",
                    label: _vm.$t("用户名"),
                    "min-width": "200",
                    sortable: "",
                    "show-overflow-tooltip": "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "channel",
                    label: _vm.$t("渠道"),
                    "min-width": "100",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "agent",
                    label: _vm.$t("代理"),
                    "min-width": "100",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "gameType",
                    label: _vm.$t("游戏"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.getGameName(scope.row.gameType))),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "currency",
                    label: _vm.$t("币种"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.renderCurrency(scope.row.currency))
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "roundId",
                    label: _vm.$t("局号"),
                    "min-width": "180",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                cursor: "pointer",
                                color: "#409EFF",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onOpenVideo(scope.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.shoeRound(scope.row)))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "createTime",
                    label: _vm.$t("创建时间"),
                    "min-width": "160",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "CORRECTTIME",
                    label: _vm.$t("结算时间"),
                    "min-width": "160",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "enterType",
                    label: _vm.$t("多台"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t(scope.row.enterType))),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "tableNum",
                    label: _vm.$t("桌号"),
                    "min-width": "160",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.getTableName(scope.row.tableNum))
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "result",
                    label: _vm.$t("结果"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.renderResult(scope.row))),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "betamount",
                    label: _vm.$t("投注额"),
                    "min-width": "120",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatMoneyToLocal(
                                  Number(scope.row.betamount)
                                )
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "validBetAmount",
                    label: _vm.$t("有效投注额"),
                    "min-width": "180",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatMoneyToLocal(scope.row.validBetAmount)
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  attrs: {
                    prop: "money",
                    label: _vm.$t("游戏输赢"),
                    "min-width": "160",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.formatMoneyToLocal(scope.row.money))
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "pagination-content" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryForm.page,
              "page-sizes": [10, 20, 30, 40, 50, 100],
              "page-size": _vm.queryForm.limit,
              total: parseInt(_vm.tableData.total),
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "45px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.totalList,
            "show-summary": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "rounds",
              label: _vm.$t("总笔数"),
              "min-width": "140",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "currency",
              label: _vm.$t("币种"),
              "min-width": "140",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.renderCurrency(scope.row.currency))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "betamount",
              label: _vm.$t("投注总额"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatCurrency(scope.row.betamount))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "validbetamount",
              label: _vm.$t("洗码量总额"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.formatCurrency(scope.row.validbetamount))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "money",
              label: _vm.$t("游戏输赢总额"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatCurrency(scope.row.money))),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": true,
            visible: _vm.dialog,
            title: _vm.getGameName(_vm.dialogForm.gameType),
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c("div", { staticClass: "contaner", attrs: { id: "contaner2" } }, [
            _c("div", { attrs: { id: "betid2" } }),
            _c("div", { attrs: { id: "gameName2" } }),
            _c(
              "div",
              { staticStyle: { display: "none" }, attrs: { id: "firstCard2" } },
              [_c("label", [_vm._v(_vm._s(_vm.$t("头牌")) + ":")])]
            ),
            _c("div", [
              _c("label", [_vm._v(_vm._s(_vm.$t("牌局结果")) + ":")]),
            ]),
            _c("div", { attrs: { id: "cardlist2" } }),
            _c("div", { attrs: { id: "gameresult2" } }),
            _c("div", { staticStyle: { width: "100%", overflow: "auto" } }, [
              _c(
                "table",
                { staticClass: "pure-table", attrs: { id: "betpoint2" } },
                [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("#")]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("投注点")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("投注额")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("输赢额")))]),
                      _c("th", [_vm._v(_vm._s(_vm.$t("洗码量")))]),
                    ]),
                  ]),
                  _c("tbody"),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }