"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.object.keys");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find");
require("core-js/modules/es6.regexp.to-string");
var _tableWinReport = require("@/api/report/tableWinReport");
var _common = require("@/api/common");
var _gameProperties = require("@/utils/gameProperties");
var _downFiles = require("@/utils/downFiles");
var _elementUi = require("element-ui");
var _lodash = _interopRequireDefault(require("lodash"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _this;
var _default = exports.default = {
  name: 'tableWinReport',
  props: {},
  data: function data() {
    return {
      tableIdsList: [],
      channelList: [],
      currencyList: [],
      dateSearchList: [{
        label: this.$t('昨日'),
        value: 'yesterday'
      }, {
        label: this.$t('今日'),
        value: 'today'
      }, {
        label: this.$t('本周'),
        value: 'week'
      }, {
        label: this.$t('本月'),
        value: 'month'
      }, {
        label: this.$t('上周'),
        value: 'lastWeek'
      }, {
        label: this.$t('上月'),
        value: 'lastMonth'
      }],
      accountForm: {
        uName: '',
        betLimit: [],
        limit: []
      },
      loading: false,
      tableData: {
        list: [],
        total: 0,
        totalPage: 1
      },
      totalList: [],
      queryForm: {
        page: 1,
        limit: 10,
        table: '',
        channelId: '',
        startDate: '',
        endDate: '',
        currency: '',
        searchDate: '',
        activity: 1,
        // 未使用的活动参数
        groupTable: 0,
        groupDate: 0,
        groupChannel: 0,
        groupCurreny: 0
      }
    };
  },
  beforeCreate: function beforeCreate() {
    _this = this;
  },
  mounted: function mounted() {
    this.queryForm.page = 1;
    this.queryForm.startDate = this.formatTime(new Date(), 'Y-M-D');
    this.queryForm.endDate = this.formatTime(new Date(new Date().getTime() + 24 * 60 * 60 * 1000), 'Y-M-D');
    this.getList();
    this.getChannelList();
    this.getCurrencyList();
    this.getTableIdsList();
  },
  methods: {
    getTableName: _gameProperties.getTableName,
    renderCurrency: _gameProperties.renderCurrency,
    renderBanker: function renderBanker(winPercent) {
      if (winPercent && winPercent != null && typeof winPercent != 'undefined') {
        return winPercent + '%';
      } else {
        return 0 + '%';
      }
    },
    onDateChange: function onDateChange(val) {
      if (val == 'yesterday') {
        this.queryForm.startDate = this.getHour() >= 12 ? this.getRecentDay(-1) : this.getRecentDay(-2);
        this.queryForm.endDate = this.getHour() >= 12 ? this.getRecentDay(0) : this.getRecentDay(-1);
      }
      if (val == 'today') {
        this.queryForm.startDate = this.getHour() >= 12 ? this.getRecentDay(0) : this.getRecentDay(-1);
        this.queryForm.endDate = this.getHour() >= 12 ? this.getRecentDay(1) : this.getRecentDay(0);
      }
      if (val == 'lastWeek') {
        this.queryForm.startDate = this.week(7);
        this.queryForm.endDate = this.week(0);
      }
      if (val == 'week') {
        this.queryForm.startDate = this.week(0);
        this.queryForm.endDate = this.week(-7);
      }
      if (val == 'lastMonth') {
        this.queryForm.startDate = this.month(-1)[0];
        this.queryForm.endDate = this.month(-1)[1];
      }
      if (val == 'month') {
        this.queryForm.startDate = this.month(0)[0];
        this.queryForm.endDate = this.month(0)[1];
      }
    },
    getHour: function getHour() {
      var now = new Date();
      return now.getHours();
    },
    getRecentDay: function getRecentDay(day) {
      var today = new Date();
      var targetday_millsecods = today.getTime() + day * 24 * 60 * 60 * 1000;
      today.setTime(targetday_millsecods);
      var year = today.getFullYear();
      var month = today.getMonth();
      var date = today.getDate();
      month = this.doHandle(month + 1);
      date = this.doHandle(date);
      return year + "-" + month + "-" + date;
    },
    week: function week(n) {
      var now = new Date();
      var day = now.getDay(); //返回星期几的某一天;
      n = day == 0 ? n + 6 : n + (day - 1);
      now.setDate(now.getDate() - n);
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var date = now.getDate();
      var s = year + "-" + (month < 10 ? '0' + month : month) + "-" + (date < 10 ? '0' + date : date);
      return s;
    },
    month: function month(AddMonthCount) {
      //起止日期数组  
      var startStop = new Array();
      //获取当前时间  
      var currentDate = new Date();
      var month = currentDate.getMonth() + AddMonthCount;
      if (month < 0) {
        var n = parseInt(-month / 12);
        month += n * 12;
        currentDate.setFullYear(currentDate.getFullYear() - n);
      }
      currentDate = new Date(currentDate.setMonth(month));
      //获得当前月份0-11  
      var currentMonth = currentDate.getMonth();
      //获得当前年份4位年  
      var currentYear = currentDate.getFullYear();
      //获得上一个月的第一天  
      var currentMonthFirstDay = new Date(currentYear, currentMonth, 1);
      //获得上一月的最后一天  
      var currentMonthLastDay = new Date(currentYear, currentMonth + 1, 1);
      //添加至数组  
      startStop.push(this.getDateStr3(currentMonthFirstDay));
      startStop.push(this.getDateStr3(currentMonthLastDay));
      //返回  
      return startStop;
    },
    doHandle: function doHandle(month) {
      var m = month;
      if (month.toString().length == 1) {
        m = "0" + month;
      }
      return m;
    },
    getDateStr3: function getDateStr3(date) {
      var year = "";
      var month = "";
      var day = "";
      var now = date;
      year = "" + now.getFullYear();
      if (now.getMonth() + 1 < 10) {
        month = "0" + (now.getMonth() + 1);
      } else {
        month = "" + (now.getMonth() + 1);
      }
      if (now.getDate() < 10) {
        day = "0" + now.getDate();
      } else {
        day = "" + now.getDate();
      }
      return year + "-" + month + "-" + day;
    },
    onSelectAll: function onSelectAll() {
      this.queryForm.channelId = this.channelList.map(function (val) {
        return val;
      });
    },
    onCounterElection: function onCounterElection() {
      var old = this.queryForm.channelId;
      this.queryForm.channelId = this.channelList.filter(function (val) {
        var one = old.find(function (item) {
          return item == val;
        });
        if (!one) {
          return val;
        }
      });
    },
    onSearch: function onSearch() {
      this.queryForm.page = 1;
      this.getList();
    },
    onOutput: function onOutput() {
      var _this2 = this;
      var loading = _elementUi.Loading.service({
        fullscreen: true
      });
      var params = this.parseParam(this.getParams());
      (0, _tableWinReport.output)(params).then(function (res) {
        if (res.code == 200) {
          var columns = _this2.createColums();
          var data = _this2.editData(res.data);
          (0, _downFiles.downFiles)(data, columns, 'tableWinReport');
        } else {
          _this2.$message.error(_this2.$t(res.message));
        }
      }).catch(function (err) {}).finally(function () {
        _this2.$nextTick(function () {
          loading.close();
        });
      });
    },
    editData: function editData(data) {
      var _this3 = this;
      if (!data) return [];
      var arr = [];
      data.forEach(function (item) {
        var model = _objectSpread({}, item);
        if (model.tableNum) model.tableNum = (0, _gameProperties.getTableName)(model.tableNum);
        if (model.amountSum) model.amountSum = _this3.toDecimal(model.amountSum);
        if (model.commamountSum) model.commamountSum = _this3.toDecimal(model.commamountSum);
        if (model.resultSum) model.resultSum = _this3.toDecimal(model.resultSum);
        if (model.currency) model.currency = (0, _gameProperties.renderCurrency)(model.currency);
        if (model.winPercent) model.winPercent = _this3.renderBanker(model.winPercent);
        arr.push(model);
      });
      return arr;
    },
    createColums: function createColums() {
      var normal = [{
        "label": this.$t('桌台号'),
        "key": "tableNum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('渠道'),
        "key": "channel",
        "control_type": "TEXT"
      }, {
        "label": this.$t('币种'),
        "key": "currency",
        "control_type": "TEXT"
      }, {
        "label": this.$t('日期'),
        "key": "date",
        "control_type": "TEXT"
      }, {
        "label": this.$t('笔数'),
        "key": "totalSum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('投注额'),
        "key": "amountSum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('洗码量'),
        "key": "commamountSum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('输赢金额'),
        "key": "resultSum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('获利率'),
        "key": "winPercent",
        "control_type": "TEXT"
      }];
      return normal;
    },
    parseParam: function parseParam(param) {
      var paramStr = '';
      Object.keys(param).forEach(function (key, index) {
        if (index === 0) {
          paramStr += key + "=" + encodeURIComponent(param[key]);
        } else {
          paramStr += "&" + key + "=" + encodeURIComponent(param[key]);
        }
      });
      return paramStr;
    },
    toDecimal: function toDecimal(x) {
      var a = parseFloat(x);
      if (isNaN(a)) {
        return false;
      }
      var f = Math.round(x * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf('.');
      if (rs < 0) {
        rs = s.length;
        s += '.';
      }
      while (s.length <= rs + 2) {
        s += '0';
      }
      return s;
    },
    getParams: function getParams() {
      var obj = {};
      var keyArr = ['startDate', 'endDate', 'channelId', 'table', 'searchDate'];
      for (var i in this.queryForm) {
        if (keyArr.includes(i) && (!this.queryForm[i] || this.queryForm[i] instanceof Array && this.queryForm[i].length == 0)) {
          obj[i] = '';
        } else {
          obj[i] = this.queryForm[i];
        }
      }
      if (obj['table']) {
        var str = obj['table'];
        var arr = str.toString().split(',');
        var arr1 = [];
        arr.forEach(function (item, index) {
          if (index % 2 == 1) {
            arr1.push(item);
          }
        });
        obj['table'] = arr1;
      }
      obj['channelId'] = obj['channelId'].toString();
      obj['table'] = obj['table'].toString();
      return obj;
    },
    formatNumber: function formatNumber(n) {
      n = n.toString();
      return n[1] ? n : '0' + n;
    },
    formatTime: function formatTime(time, format) {
      if (!time) {
        return;
      }
      var newArr = [];
      var formatArr = ['Y', 'M', 'D', 'h', 'm', 's'];
      newArr.push(time.getFullYear() ? time.getFullYear() : '');
      newArr.push(this.formatNumber(time.getMonth() + 1));
      newArr.push(this.formatNumber(time.getDate()));
      newArr.push(this.formatNumber(time.getHours()));
      newArr.push(this.formatNumber(time.getMinutes()));
      newArr.push(this.formatNumber(time.getSeconds()));
      for (var i in newArr) {
        format = format.replace(formatArr[i], newArr[i]);
      }
      return format;
    },
    getTableIdsList: function getTableIdsList() {
      var _this4 = this;
      (0, _common.getTableIds)().then(function (res) {
        if (res.code == 200) {
          _this4.tableIdsList = (0, _gameProperties.deepgreeTree)(_lodash.default.cloneDeep(res.data));
        } else {
          _this4.$message.error(_this4.$t(res.message));
          _this4.tableIdsList = [];
        }
      }).catch(function (err) {
        _this4.tableIdsList = [];
      });
    },
    getList: function getList() {
      var _this5 = this;
      this.loading = true;
      var pa = this.getParams();
      var params = this.parseParam(pa);
      (0, _tableWinReport.getList)(params).then(function (res) {
        _this5.loading = false;
        if (res.code == 200) {
          var form = new FormData();
          for (var i in pa) {
            form.append(i, pa[i]);
          }
          _this5.getSumList(form);
          _this5.tableData = {
            list: res.data,
            total: res.count,
            totalPage: 1
          };
        } else {
          _this5.$message.error(_this5.$t(res.message));
          _this5.tableData = {
            list: [],
            total: 0,
            totalPage: 1
          };
        }
      }).catch(function (err) {
        _this5.loading = false;
        _this5.tableData = {
          list: [],
          total: 0,
          totalPage: 1
        };
      });
    },
    getSumList: function getSumList(params) {
      var _this6 = this;
      (0, _tableWinReport.getSumList)(params).then(function (res) {
        if (res.code == 200) {
          _this6.totalList = res.data;
        } else {
          _this6.$message.error(_this6.$t(res.message));
          _this6.totalList = [];
        }
      }).catch(function (err) {
        _this6.totalList = [];
      });
    },
    // 页码size 改变
    handleSizeChange: function handleSizeChange(size) {
      setTimeout(function () {
        window.scrollTo({
          left: 0,
          top: 0
        });
      }, 10);
      this.queryForm.limit = size;
      this.queryForm.page = 1;
      this.getList();
    },
    // 分页数据改变
    handleCurrentChange: function handleCurrentChange(val) {
      window.scrollTo({
        left: 0,
        top: 0
      });
      this.queryForm.page = val;
      this.getList();
    },
    getChannelList: function getChannelList() {
      var _this7 = this;
      (0, _common.getChannel)().then(function (res) {
        if (res.code == 200) {
          _this7.channelList = res.data;
        } else {
          _this7.$message.error(_this7.$t(res.message));
          _this7.channelList = [];
        }
      }).catch(function (err) {
        _this7.channelList = [];
      });
    },
    getCurrencyList: function getCurrencyList() {
      var _this8 = this;
      (0, _common.getCurrency)().then(function (res) {
        if (res.code == 200) {
          _this8.currencyList = res.data.map(function (val) {
            val.name = (0, _gameProperties.renderCurrency)(val.name);
            return val;
          });
        } else {
          _this8.$message.error(_this8.$t(res.message));
          _this8.currencyList = [];
        }
      }).catch(function (err) {
        _this8.currencyList = [];
      });
    }
  }
};