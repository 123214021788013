"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getGameResult = getGameResult;
exports.getList = getList;
exports.getSumList = getSumList;
exports.output = output;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取列表数据
function getList(params) {
  return (0, _request.default)({
    url: '/yx/gameResultQuery/list?' + params,
    method: 'GET'
  });
}

// 获取统计数据
function getSumList(data) {
  return (0, _request.default)({
    url: '/yx/gameResultQuery/sum',
    method: 'POST',
    data: data
  });
}

// 获取统计数据
function getGameResult(data) {
  return (0, _request.default)({
    url: '/yx/gameResultQuery/history?gid=' + data.gid + '&gameNum=' + data.gameNum,
    method: 'GET'
  });
}

// 导出
function output(data) {
  return (0, _request.default)({
    url: '/yx/gameResultQuery/excel?' + data,
    method: 'POST'
  });
}