"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.regexp.split");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _store = _interopRequireDefault(require("../store"));
var _auth = require("@/utils/auth");
var _loading = require("@/utils/loading");
var _settings = _interopRequireDefault(require("@/settings"));
var _errCode = _interopRequireDefault(require("./errCode"));
var _lang = _interopRequireDefault(require("../lang"));
function getLanguage() {
  return localStorage.getItem('lang') || 'zh'; // set locale
}
var showMsg = 0;
// 创建axios实例
var service = _axios.default.create({
  withCredentials: true,
  // baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/as', // api 的 base_url
  timeout: _settings.default.timeout // 请求超时时间
});
_axios.default.defaults.withCredentials = true;
// request拦截器
service.interceptors.request.use(function (config) {
  var reqUrl = config.url.split('/');
  if (process.env.NODE_ENV == 'production') {
    config.baseURL = process.env.VUE_APP_BASE_API;
  } else if (reqUrl[1] == 'as') {
    config.baseURL = '/as';
  } else if (reqUrl[1] == 'report') {
    config.baseURL = '/report';
  }
  if ((0, _auth.getToken)()) {
    config.headers['Authorization'] = "Bearer ".concat((0, _auth.getToken)());
  }
  if (config.showLoading) {
    (0, _loading.showLoading)();
  }
  config.headers['Content-Type'] = ' application/json';
  // config.headers.common['lang'] = 'zh-EN';
  config.headers.common['lang'] = getLanguage();
  return config;
}, function (error) {
  (0, _loading.hideLoading)();
  Promise.reject(error);
});

// response 拦截器
service.interceptors.response.use(function (response) {
  (0, _loading.hideLoading)();
  var code = response.status;
  if (code < 200 || code > 300) {
    return Promise.reject(response.message);
  } else {
    //  未登录或者登录状态失效
    if (response.data.code == '401' || response.data.code == '10001' || response.data.code == '10002') {
      showMsg += 1;
      if (showMsg == 1) {
        (0, _elementUi.MessageBox)({
          title: _lang.default.tc('温馨提示'),
          message: _lang.default.tc('登录失效,请重新登录')
        }).then(function () {
          showMsg = 0;
          _store.default.dispatch('initTab');
          _store.default.dispatch('clearRouters');
          _store.default.dispatch('LoginInvalid').then(function () {
            location.reload();
          });
        }).catch(function () {
          showMsg = 0;
        });
      }
    } else if (response.data.code && _errCode.default.toString().includes(response.data.code)) {
      _elementUi.Message.error(_lang.default.tc(response.data.message));
      return Promise.reject(response.data.message);
    } else {
      return response.data;
    }
  }
}, function (error) {
  (0, _loading.hideLoading)();
  var errorRes = error.response;
  var msg = errorRes.data.message ? errorRes.data.message : _lang.default.tc('操作失败,请稍后重试');
  var code = 0;
  try {
    code = errorRes.data.code;
    if (code == '401') {
      showMsg += 1;
      if (showMsg == 1) {
        (0, _elementUi.MessageBox)({
          title: _lang.default.tc('温馨提示'),
          message: _lang.default.tc(msg)
        }).then(function () {
          showMsg = 0;
          _store.default.dispatch('initTab');
          _store.default.dispatch('clearRouters');
          _store.default.dispatch('LoginInvalid').then(function () {
            location.reload();
          }).catch(function () {
            showMsg = 0;
          });
          ;
        });
      }
    } else if (code == 4011) {
      (0, _elementUi.MessageBox)({
        title: _lang.default.tc('温馨提示'),
        message: _lang.default.tc(msg)
      }).then(function () {
        showMsg = 0;
        _store.default.dispatch('initTab');
        _store.default.dispatch('clearRouters');
        _store.default.dispatch('LogOut').then(function () {
          location.reload();
        }).catch(function () {
          showMsg = 0;
        });
        ;
      });
    } else if (response.data.code && _errCode.default.includes(response.data.code)) {
      _elementUi.Message.error(_lang.default.tc(msg));
    } else {
      _elementUi.Message.error(_lang.default.tc(msg));
    }
    return Promise.reject(errorRes);
  } catch (e) {
    _elementUi.Message.error(_lang.default.tc(msg));
    return Promise.reject(errorRes);
  }
});
var _default = exports.default = service;