var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                display: "block",
                "margin-bottom": "10px",
                "font-size": "14px",
              },
            },
            [
              _c("font", { attrs: { color: "red" } }, [
                _vm._v("[" + _vm._s(_vm.$t("北京时间")) + "]"),
              ]),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.queryForm, inline: true },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("账户名"),
                    },
                    model: {
                      value: _vm.queryForm.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "userName", $$v)
                      },
                      expression: "queryForm.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("注单号"),
                    },
                    model: {
                      value: _vm.queryForm.betId,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "betId", $$v)
                      },
                      expression: "queryForm.betId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("局号"),
                    },
                    model: {
                      value: _vm.queryForm.roundId,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "roundId", $$v)
                      },
                      expression: "queryForm.roundId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      clearable: "",
                      "default-time": "12:00:00",
                      placeholder: _vm.$t("开始时间"),
                    },
                    model: {
                      value: _vm.queryForm.beginTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "beginTime", $$v)
                      },
                      expression: "queryForm.beginTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      clearable: "",
                      "default-time": "11:59:59",
                      placeholder: _vm.$t("结束时间"),
                    },
                    model: {
                      value: _vm.queryForm.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "endTime", $$v)
                      },
                      expression: "queryForm.endTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { placeholder: _vm.$t("游戏") },
                      on: { change: _vm.gameChange },
                      model: {
                        value: _vm.queryForm.gameName,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "gameName", $$v)
                        },
                        expression: "queryForm.gameName",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("百家乐"), value: "BACCARAT" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("炸金花"), value: "WIN3CARDS" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.queryForm.gameName == "BACCARAT"
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { placeholder: _vm.$t("投注类型") },
                          model: {
                            value: _vm.queryForm.betPoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "betPoint", $$v)
                            },
                            expression: "queryForm.betPoint",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("全部"),
                              value: "allSelect",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.BANKER"),
                              value: "BANKER",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.PLAYER"),
                              value: "PLAYER",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.TILE"),
                              value: "TILE",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.BP"),
                              value: "BP",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.PP"),
                              value: "PP",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.SS"),
                              value: "SS",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.BIG"),
                              value: "BIG",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.SMALL"),
                              value: "SMALL",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.BN"),
                              value: "BN",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.PN"),
                              value: "PN",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.AP"),
                              value: "AP",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.PFP"),
                              value: "PFP",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.BDB"),
                              value: "BDB",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.PDB"),
                              value: "PDB",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.LS"),
                              value: "LS",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.queryForm.gameName == "WIN3CARDS"
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "150px" },
                          attrs: { placeholder: _vm.$t("投注类型") },
                          model: {
                            value: _vm.queryForm.betPoint,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "betPoint", $$v)
                            },
                            expression: "queryForm.betPoint",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("全部"),
                              value: "allSelect",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.BOMB"),
                              value: "BOMB",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.STRAIGHT_FLUSH"),
                              value: "STRAIGHT_FLUSH",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.FLUSH"),
                              value: "FLUSH",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.STRAIGHT"),
                              value: "STRAIGHT",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.DRAGON"),
                              value: "DRAGON",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.PHOENIX"),
                              value: "PHOENIX",
                            },
                          }),
                          _c("el-option", {
                            attrs: {
                              label: _vm.$t("bettingType.PAIR8"),
                              value: "PAIR8",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "150px" },
                      attrs: { placeholder: _vm.$t("下注方式") },
                      model: {
                        value: _vm.queryForm.enterType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "enterType", $$v)
                        },
                        expression: "queryForm.enterType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("全部"), value: "allSelect" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("大厅"), value: "1" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("换桌"), value: "2" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("多台"), value: "3" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("桌台内"), value: "4" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px" },
                      attrs: { placeholder: _vm.$t("客户端类型") },
                      model: {
                        value: _vm.queryForm.clientType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "clientType", $$v)
                        },
                        expression: "queryForm.clientType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("全部"), value: "allSelect" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("白鹭PC"), value: "0" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("白鹭H5"), value: "1" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("cocos-pc"), value: "2" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("cocos-h5"), value: "3" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("渠道"),
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.queryForm.channel,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "channel", $$v)
                        },
                        expression: "queryForm.channel",
                      },
                    },
                    _vm._l(_vm.channelList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item, value: item },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("币种") },
                      model: {
                        value: _vm.queryForm.CCYID,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "CCYID", $$v)
                        },
                        expression: "queryForm.CCYID",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("全部币种"), value: 0 },
                      }),
                      _vm._l(_vm.currencyList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("代理"),
                    },
                    model: {
                      value: _vm.queryForm.agentName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "agentName", $$v)
                      },
                      expression: "queryForm.agentName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.onSearch },
                    },
                    [_vm._v(_vm._s(_vm.$t("搜索")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.onReset },
                    },
                    [_vm._v(_vm._s(_vm.$t("清空条件")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-download",
                        size: "mini",
                      },
                      on: { click: _vm.onOutput },
                    },
                    [_vm._v(_vm._s(_vm.$t("导出")))]
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("统计维度") } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupTableId,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupTableId", $$v)
                        },
                        expression: "queryForm.groupTableId",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("台号")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupBetPoint,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupBetPoint", $$v)
                        },
                        expression: "queryForm.groupBetPoint",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("投注类型")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupDate", $$v)
                        },
                        expression: "queryForm.groupDate",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("投注日期")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupClientType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupClientType", $$v)
                        },
                        expression: "queryForm.groupClientType",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("客户端类型")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupEnterType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupEnterType", $$v)
                        },
                        expression: "queryForm.groupEnterType",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("下注方式")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "45px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.tableData.list,
            height: "480px",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "userName",
              label: _vm.$t("玩家账号"),
              "min-width": "160",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "agentName",
              label: _vm.$t("代理"),
              "min-width": "160",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "companyName",
              label: _vm.$t("渠道"),
              "min-width": "160",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "currency",
              label: _vm.$t("币种"),
              "min-width": "140",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.renderCurrency(scope.row.currency))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "tableNum",
              label: _vm.$t("台号"),
              "min-width": "160",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getTableName(scope.row.tableNum))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "betId",
              label: _vm.$t("投注编号"),
              "min-width": "160",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "groupFor",
              label: _vm.$t("投注日期"),
              "min-width": "160",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "betTime",
              label: _vm.$t("投注时间"),
              "min-width": "160",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "correctTime",
              label: _vm.$t("派彩时间"),
              "min-width": "160",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "gameName",
              label: _vm.$t("游戏"),
              "min-width": "120",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getGameName(scope.row.gameName))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "roundId",
              label: _vm.$t("局号"),
              "min-width": "160",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "id",
              label: _vm.$t("靴/局数"),
              "min-width": "160",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.shoeNum
                      ? _c("span", [
                          _vm._v(
                            _vm._s(scope.row.shoeNum) +
                              "-" +
                              _vm._s(scope.row.gamblingNum)
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "winPoint",
              label: _vm.$t("游戏结果"),
              "min-width": "160",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.renderResult(
                            Object.assign(
                              {},
                              { result: scope.row.winPoint },
                              scope.row
                            )
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: _vm.$t("状态"),
              "min-width": "100",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getStatus(scope.row.status))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "betPoint",
              label: _vm.$t("投注类型"),
              "min-width": "160",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.getBetPoint(scope.row)))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "amount",
              label: _vm.$t("投注金额"),
              "min-width": "160",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "pointResult",
              label: _vm.$t("输赢"),
              "min-width": "160",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "commAmount",
              label: _vm.$t("有效投注"),
              "min-width": "160",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "clientType",
              label: _vm.$t("客户端"),
              "min-width": "160",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.setClientType(scope.row.clientType))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "enterType",
              label: _vm.$t("下注方式"),
              "min-width": "160",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.setBetType(scope.row.enterType))),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-content" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryForm.page,
              "page-sizes": [10, 20, 30, 40, 50, 100],
              "page-size": _vm.queryForm.limit,
              total: parseInt(_vm.tableData.total),
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "45px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.totalList,
            "show-summary": "",
            "summary-method": _vm.getTotal,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "rounds",
              label: _vm.$t("总笔数"),
              "min-width": "200",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "CCYNAME",
              label: _vm.$t("币种"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.renderCurrency(scope.row.CCYNAME))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "betAmount",
              label: _vm.$t("投注总额"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatCurrency(scope.row.betAmount))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "validbetamount",
              label: _vm.$t("洗码量总额"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.formatCurrency(scope.row.validbetamount))
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "money",
              label: _vm.$t("游戏输赢总额"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.formatCurrency(scope.row.money))),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }