"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _serverMsg = require("@/utils/serverMsg");
var _vuex = require("vuex");
var _menu = require("@/api/system/menu");
var _IconSelect = _interopRequireDefault(require("@/components/IconSelect"));
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Menu',
  components: {
    Treeselect: _vueTreeselect.default,
    IconSelect: _IconSelect.default
  },
  data: function data() {
    return {
      icon: '',
      loading: true,
      dialogVisible: false,
      editType: 0,
      // 0 新增 1编辑
      options: [],
      permission: {
        add: ['admin', 'menu:add'],
        edit: ['admin', 'menu:edit'],
        del: ['admin', 'menu:del']
      },
      form: {
        id: '',
        parentId: '',
        name: '',
        url: '',
        permission: '',
        icon: '',
        type: 0,
        sort: 0,
        visible: 1
      },
      rules: {
        name: [{
          required: true,
          message: this.$i18n.t('systemMenu.from_tips_title'),
          trigger: 'blur'
        }],
        url: [{
          required: true,
          message: this.$i18n.t('systemMenu.from_tips_address'),
          trigger: 'blur'
        }]
      },
      parentData: [],
      tableData: []
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['userInfo'])),
  mounted: function mounted() {
    // 获取菜单树形结构
    this.getMenusTreeList();
  },
  methods: {
    submit: function submit() {
      var _this = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          _this.form.permission = _this.form.url;
          crudMenu.add(_this.form).then(function (res) {
            if (res.code == 200) {
              _this.dialogVisible = false;
              _this.$alert((0, _serverMsg.serverMsg)(res.code, res.message), {
                title: _this.$i18n.t('systemMenu.wxts'),
                type: 'warning'
              }).then(function (res) {
                _this.getMenusTree();
              });
            } else {
              _this.$alert((0, _serverMsg.serverMsg)(res.code, res.message), {
                title: _this.$i18n.t('systemMenu.wxts'),
                type: 'warning'
              });
            }
          });
        }
      });
    },
    //  获取菜单树形结构
    getMenusTreeList: function getMenusTreeList() {
      var _this2 = this;
      this.loading = true;
      (0, _menu.getMenusTree)(this.userInfo.id).then(function (res) {
        _this2.loading = false;
        if (res.code == 200) {
          var options_str = JSON.stringify(res.data);
          var options_str_replace = options_str.replace(/title/g, "label").replace(/childs/g, 'children');
          var table_str = options_str.replace(/title/g, "name").replace(/childs/g, 'children');
          // this.options = JSON.parse(options_str_replace);
          // this.options.unshift({ id: '0', label: this.$i18n.t('systemMenu.form_tree'), children: [] })
          _this2.tableData = JSON.parse(table_str);
        }
      });
    },
    // 选中图标
    selected: function selected(name) {
      this.form.icon = name;
    },
    // 新增表单
    // 编辑菜单
    edit: function edit() {
      var _this3 = this;
      this.$refs['form'].validate(function (valid) {
        if (valid) {
          crudMenu.edit(_this3.form).then(function (res) {
            if (res.code == 200) {
              _this3.dialogVisible = false;
              _this3.$alert((0, _serverMsg.serverMsg)(res.code, res.message), {
                title: _this3.$i18n.t('systemMenu.wxts'),
                type: 'warning'
              }).then(function (res) {
                _this3.getMenusTree();
              });
            } else {
              _this3.$alert((0, _serverMsg.serverMsg)(res.code, res.message), {
                title: _this3.$i18n.t('systemMenu.wxts'),
                type: 'warning'
              });
            }
          });
        }
      });
    },
    // 添加按钮
    addMenu: function addMenu(type, item) {
      this.editType = type;
      if (type == 0) {
        this.form = {
          id: '',
          parentId: '0',
          name: '',
          url: '',
          permission: '',
          icon: '',
          type: 0,
          sort: 0,
          visible: 1
        };
      } else {
        this.form = {
          id: item.id,
          parentId: item.parentId,
          name: item.name,
          url: item.href,
          permission: item.perms,
          icon: item.icon,
          type: item.type,
          sort: item.orderNum,
          visible: item.visible
        };
      }
      this.dialogVisible = true;
    },
    //  删除菜单
    delItem: function delItem(item) {
      var _this4 = this;
      this.$confirm(this.$i18n.t('systemMenu.alert_del'), this.$i18n.t('systemMenu.wxts'), {
        confirmButtonText: this.$i18n.t('systemMenu.form_sure'),
        cancelButtonText: this.$i18n.t('systemMenu.form_cancel'),
        type: 'warning'
      }).then(function () {
        crudMenu.del(item.id).then(function (res) {
          if (res.code == 200) {
            _this4.getMenusTree();
          } else {
            _this4.$alert((0, _serverMsg.serverMsg)(res.code, res.message), {
              title: _this4.$i18n.t('systemMenu.wxts'),
              type: 'warning'
            });
          }
        });
      }).catch(function (err) {});
    }
  }
};