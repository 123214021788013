"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRouterMap = void 0;
var _typeof2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _index = _interopRequireDefault(require("../layout/index"));
var _login = _interopRequireDefault(require("@/views/login"));
var _ = _interopRequireDefault(require("@/views/features/404"));
var _2 = _interopRequireDefault(require("@/views/features/401"));
var _home = _interopRequireDefault(require("@/views/home"));
var _index2 = _interopRequireDefault(require("@/views/system/role/index.vue"));
var _index3 = _interopRequireDefault(require("@/views/system/menu/index.vue"));
var _index4 = _interopRequireDefault(require("@/views/setting/notice/index.vue"));
var _updatePass = _interopRequireDefault(require("@/views/user/updatePass.vue"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
_vue.default.use(_vueRouter.default);

// 登录页面
// 404页面
// 401页面
// 重定向页面
// 首页
//角色管理
//菜单管理
// 通告管理

// 修改密码

var channelMng = function channelMng() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/account/channelMng/index.vue'));
  });
}; // 账号管理-渠道管理
var agencyMng = function agencyMng() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/account/agencyMng/index.vue'));
  });
}; // 账号管理-代理管理
var subUserList = function subUserList() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/account/subUserList/index.vue'));
  });
}; // 账号管理-子账号管理
var userMng = function userMng() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/account/userMng/index.vue'));
  });
}; // 账号管理-用户管理
var blackListMng = function blackListMng() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/account/blackListMng/index.vue'));
  });
}; // 账号管理-IP黑名单

var exchangeRate = function exchangeRate() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/exchangeRate/index.vue'));
  });
}; // 报表查询-汇率查询
var transferInquiry = function transferInquiry() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/transferInquiry/index.vue'));
  });
}; // 报表查询-转账查询
var blockchainBetting = function blockchainBetting() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/blockchainBetting/index.vue'));
  });
}; // 报表查询-区块链注单查询
var winReport = function winReport() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/winReport/index.vue'));
  });
}; // 报表查询-输赢报表
var tableWinReport = function tableWinReport() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/tableWinReport/index.vue'));
  });
}; // 报表查询-台桌输赢报表
var gameRecord = function gameRecord() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/gameRecord/index.vue'));
  });
}; // 报表查询-游戏记录查询
var vgGameRecord = function vgGameRecord() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/vgGameRecord/index.vue'));
  });
}; // 报表查询-棋牌捕鱼游戏记录查询
var liveGameRecord = function liveGameRecord() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/liveGameRecord/index.vue'));
  });
}; // 报表查询-直播游戏记录查询
var liveGiftInquiry = function liveGiftInquiry() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/liveGiftInquiry/index.vue'));
  });
}; // 报表查询-直播礼物查询
var gameResultsReport = function gameResultsReport() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/gameResultsReport/index.vue'));
  });
}; // 报表查询-游戏结果报表
var playerBettingAnalysis = function playerBettingAnalysis() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/playerBettingAnalysis/index.vue'));
  });
}; // 报表查询-玩家投注分析
var userInformation = function userInformation() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/userInformation/index.vue'));
  });
}; // 报表查询-用户信息查询
var userAssociatedIP = function userAssociatedIP() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/userAssociatedIP/index.vue'));
  });
}; // 报表查询-用户关联IP查询
var sameIPUsers = function sameIPUsers() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/sameIPUsers/index.vue'));
  });
}; // 报表查询-同IP用户查询
var newGameRecord = function newGameRecord() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/newGameRecord/index.vue'));
  });
}; // 报表查询-新游戏记录查询
var machinePrinterConfig = function machinePrinterConfig() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/machinePrinterConfig/index.vue'));
  });
}; // 报表查询-机打配置表
var machineBlacklist = function machineBlacklist() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/machineBlacklist/index.vue'));
  });
}; // 报表查询-机打黑名单查询
var betReportList = function betReportList() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/betReportList/index.vue'));
  });
}; // 报表查询-投注记录
var betFailReport = function betFailReport() {
  return Promise.resolve().then(function () {
    return _interopRequireWildcard(require('@/views/report/betFailReport/index.vue'));
  });
}; // 报表查询-无缝钱包退款

var constantRouterMap = exports.constantRouterMap = [{
  path: '/login',
  meta: {
    title: '首页',
    noCache: true
  },
  component: _login.default,
  hidden: true
}, {
  path: '/404',
  component: _.default,
  hidden: true
}, {
  path: '/401',
  component: _2.default,
  hidden: true
}, {
  path: '/redirect',
  component: _index.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: _2.default
  }]
}, {
  path: '/',
  component: _index.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: _home.default,
    name: 'Home',
    meta: {
      title: '首页',
      icon: 'index',
      affix: true,
      noCache: true
    }
  }]
}, {
  path: '/system',
  component: _index.default,
  meta: {
    title: '菜单管理'
  },
  redirect: 'noredirect',
  children: [{
    path: 'role',
    component: _index2.default,
    name: 'Roles',
    meta: {
      title: '角色管理'
    }
  }, {
    path: 'menu',
    component: _index3.default,
    name: 'Menus',
    meta: {
      title: '菜单管理'
    }
  }]
}, {
  path: '/setting',
  component: _index.default,
  meta: {
    title: '系统配置'
  },
  redirect: 'noredirect',
  children: [{
    path: 'notice',
    component: _index4.default,
    name: 'Notice',
    meta: {
      title: '通告管理'
    }
  }]
}, {
  path: '/user',
  component: _index.default,
  meta: {
    title: '用户管理'
  },
  redirect: 'noredirect',
  children: [{
    path: 'updatePass',
    component: _updatePass.default,
    name: 'UpdatePass',
    meta: {
      title: '修改密码'
    }
  }]
}, {
  path: '/account',
  component: _index.default,
  meta: {
    title: '账号管理',
    icon: 'index'
    // activeMenu: true
  },
  redirect: 'noredirect',
  children: [{
    path: '/account/channelMng',
    component: channelMng,
    name: 'channelMng',
    meta: {
      title: '渠道管理'
    }
  }, {
    path: '/account/agencyMng',
    component: agencyMng,
    name: 'agencyMng',
    meta: {
      title: '代理管理'
    }
  }, {
    path: '/account/subUserList',
    component: subUserList,
    name: 'subUserList',
    meta: {
      title: '子账号管理'
    }
  }, {
    path: '/account/userMng',
    component: userMng,
    name: 'userMng',
    meta: {
      title: '用户管理'
    }
  }, {
    path: '/account/blackListMng',
    component: blackListMng,
    name: 'blackListMng',
    meta: {
      title: 'IP黑名单管理'
    }
  }]
}, {
  path: '/report',
  component: _index.default,
  meta: {
    title: '报表查询',
    icon: 'index'
  },
  redirect: 'noredirect',
  children: [{
    path: '/report/exchangeRate',
    component: exchangeRate,
    name: 'exchangeRate',
    meta: {
      title: '汇率查询'
    }
  }, {
    path: '/report/transferInquiry',
    component: transferInquiry,
    name: 'transferInquiry',
    meta: {
      title: '转账查询'
    }
  }, {
    path: '/report/blockChainReport',
    component: blockchainBetting,
    name: 'blockchainBetting',
    meta: {
      title: '区块链注单查询'
    }
  }, {
    path: '/report/winReport',
    component: winReport,
    name: 'winReport',
    meta: {
      title: '输赢报表'
    }
  }, {
    path: '/report/tableWinReport',
    component: tableWinReport,
    name: 'tableWinReport',
    meta: {
      title: '桌台输赢报表'
    }
  }, {
    path: '/report/gameRecord',
    component: gameRecord,
    name: 'gameRecord',
    meta: {
      title: '游戏记录查询'
    }
  }, {
    path: '/report/gameLogVG',
    component: vgGameRecord,
    name: 'vgGameRecord',
    meta: {
      title: '棋牌捕鱼游戏记录查询'
    }
  }, {
    path: '/report/liveGameRecord',
    component: liveGameRecord,
    name: 'liveGameRecord',
    meta: {
      title: '直播游戏记录查询'
    }
  }, {
    path: '/report/liveGiftInquiry',
    component: liveGiftInquiry,
    name: 'liveGiftInquiry',
    meta: {
      title: '直播礼物查询'
    }
  }, {
    path: '/report/gameResultsReport',
    component: gameResultsReport,
    name: 'gameResultsReport',
    meta: {
      title: '游戏结果报表'
    }
  }, {
    path: '/report/playerBettingAnalysis',
    component: playerBettingAnalysis,
    name: 'playerBettingAnalysis',
    meta: {
      title: '玩家投注分析'
    }
  }, {
    path: '/report/userInformation',
    component: userInformation,
    name: 'userInformation',
    meta: {
      title: '用户信息查询'
    }
  }, {
    path: '/report/userAssociatedIP',
    component: userAssociatedIP,
    name: 'userAssociatedIP',
    meta: {
      title: '用户关联IP查询'
    }
  }, {
    path: '/report/sameIPUsers',
    component: sameIPUsers,
    name: 'sameIPUsers',
    meta: {
      title: '同IP用户查询'
    }
  }, {
    path: '/report/newGameRecord',
    component: newGameRecord,
    name: 'newGameRecord',
    meta: {
      title: '新游戏记录查询'
    }
  }, {
    path: '/report/machinePrinterConfig',
    component: machinePrinterConfig,
    name: 'machinePrinterConfig',
    meta: {
      title: '机打配置表'
    }
  }, {
    path: '/report/machineBlacklist',
    component: machineBlacklist,
    name: 'machineBlacklist',
    meta: {
      title: '机打黑名单查询'
    }
  }, {
    path: '/report/betReport',
    component: betReportList,
    name: 'betReportList',
    meta: {
      title: '注点查询'
    }
  }, {
    path: '/report/betFailReport',
    component: betFailReport,
    name: 'betFailReport',
    meta: {
      title: '无缝钱包异常注单列表'
    }
  }]
}, {
  path: '/dataSourceMonitoring',
  component: _index.default,
  meta: {
    title: '数据源监控',
    icon: 'index'
  },
  redirect: 'noredirect',
  children: []
}];
var _default = exports.default = new _vueRouter.default({
  mode: 'history',
  scrollBehavior: function scrollBehavior() {
    return {
      y: 0
    };
  },
  routes: constantRouterMap
});