"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.string.starts-with");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.to-string");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _liveGameRecord = require("@/api/report/liveGameRecord");
var _common = require("@/api/common");
var _gameProperties = require("@/utils/gameProperties");
var _showDetail = _interopRequireDefault(require("../../components/showDetail.vue"));
var _downFiles = require("@/utils/downFiles");
var _elementUi = require("element-ui");
var _vuex = require("vuex");
var _lodash = _interopRequireDefault(require("lodash"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _this;
// 游戏记录查询
var _default = exports.default = {
  components: {
    showDetail: _showDetail.default
  },
  name: 'liveGameRecord',
  props: {},
  data: function data() {
    return {
      dialog: false,
      tableIdsList: [],
      channelList: [],
      currencyList: [],
      accountForm: {
        uName: '',
        betLimit: [],
        limit: []
      },
      loading: false,
      tableData: {
        list: [],
        total: 0,
        totalPage: 1
      },
      totalList: [],
      queryForm: {
        page: 1,
        limit: 10,
        userName: '',
        note: '',
        round: '',
        beginTime: '',
        endTime: '',
        tableIds: '',
        game: '',
        channel: '',
        proxy: '',
        currency: '',
        betRiskScore: '',
        ip: '',
        gameRange: 0,
        timeType: 'create'
      },
      dialogForm: {}
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['isAdmin'])),
  watch: {},
  beforeCreate: function beforeCreate() {
    _this = this;
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    getTableName: _gameProperties.getTableName,
    formatMoneyToLocal: _gameProperties.formatMoneyToLocal,
    formatCurrency: _gameProperties.formatCurrency,
    getGameName: _gameProperties.getGameName,
    getStatus: _gameProperties.getStatus,
    goodRoadType: _gameProperties.goodRoadType,
    renderResult: _gameProperties.renderResult,
    renderCurrency: _gameProperties.renderCurrency,
    handleClose: function handleClose(done) {
      this.dialogForm = {};
      done();
    },
    toDecimal: function toDecimal(x) {
      var a = parseFloat(x);
      if (isNaN(a)) {
        return false;
      }
      var f = Math.round(x * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf('.');
      if (rs < 0) {
        rs = s.length;
        s += '.';
      }
      while (s.length <= rs + 2) {
        s += '0';
      }
      return s;
    },
    init: function init() {
      this.queryForm.page = 1;
      var _this$$route$params = this.$route.params,
        gid = _this$$route$params.gid,
        userName = _this$$route$params.userName,
        channel = _this$$route$params.channel;
      if (gid || userName || channel) {
        this.queryForm.round = gid || '';
        this.queryForm.userName = userName || '';
        this.queryForm.channel = channel || '';
        this.getList();
      }
      this.getChannelList();
      this.getCurrencyList();
      this.getTableIdsList();
    },
    renderBanker: function renderBanker(row) {
      if (row.winPercent && row.winPercent != null && typeof row.winPercent != 'undefined') {
        return row.winPercent + '%';
      } else {
        return 0 + '%';
      }
    },
    onOpenDetail: function onOpenDetail(row) {
      var _this2 = this;
      this.dialogForm = row;
      this.dialogForm.key = new Date().getTime();
      this.isShow = false;
      this.$nextTick(function () {
        _this2.isShow = true;
      });
      this.dialog = true;
      this.setDialogData();
    },
    onOutput: function onOutput() {
      var _this3 = this;
      var loading = _elementUi.Loading.service({
        fullscreen: true
      });
      var params = this.parseParam(this.getParams());
      (0, _liveGameRecord.output)(params).then(function (res) {
        if (res.code == 200) {
          var columns = _this3.createColums();
          var data = _this3.editData(res.data);
          (0, _downFiles.downFiles)(data, columns, 'gameRecord');
        } else {
          _this3.$message.error(_this3.$t(res.message));
        }
      }).catch(function (err) {}).finally(function () {
        _this3.$nextTick(function () {
          loading.close();
        });
      });
    },
    onOpenVideo: function onOpenVideo(row) {
      var COMPINFO = row.COMPINFO,
        SHOENUM = row.SHOENUM,
        GAMBLINGNUM = row.GAMBLINGNUM,
        LOBBYID = row.LOBBYID,
        tableNum = row.tableNum;
      var tn = '';
      try {
        tn = tableNum.replace(/#/, "_");
      } catch (e) {
        tn = tableNum;
      }
      var language = '&language=' + (localStorage.getItem('lang') == 'zh' ? 'sc' : 'en-us');
      window.open("https://www.yaxin868.com/public/playBackWindow.html?roundid=" + COMPINFO + "&snum=" + SHOENUM + "&gnum=" + GAMBLINGNUM + "&cl=zh-cn" + "&hallId=" + LOBBYID + "&tableId=" + tn.replace(/#/, "_") + language);
    },
    onSearch: function onSearch() {
      this.queryForm.page = 1;
      this.getList();
    },
    onReset: function onReset() {
      this.queryForm = {
        page: 1,
        limit: 10,
        userName: '',
        note: '',
        round: '',
        beginTime: '',
        endTime: '',
        tableIds: '',
        game: '',
        channel: '',
        proxy: '',
        currency: '',
        betRiskScore: '',
        ip: '',
        gameRange: 0,
        timeType: 'create'
      };
    },
    editData: function editData(data) {
      var _this4 = this;
      if (!data) return [];
      var arr = [];
      data.forEach(function (item) {
        var model = _objectSpread({}, item);
        if (model.status) model.status = (0, _gameProperties.getStatus)(model.status);
        if (model.goodRoad) model.goodRoad = (0, _gameProperties.goodRoadType)(model.goodRoad);
        if (model.gameType) model.gameType = (0, _gameProperties.getGameName)(model.gameType);
        if (model.currency) model.currency = (0, _gameProperties.renderCurrency)(model.currency);
        if (model.result) model.result = (0, _gameProperties.renderResult)(model);
        if (model.tableNum) model.tableNum = (0, _gameProperties.getTableName)(model.tableNum);
        if (model.enterType) model.enterType = _this4.$t(model.enterType) || '';
        arr.push(model);
      });
      return arr;
    },
    createColums: function createColums() {
      var admin = [{
        "label": this.$t('注单号'),
        "key": "id",
        "control_type": "TEXT"
      }, {
        "label": this.$t('状态'),
        "key": "status",
        "control_type": "TEXT"
      }, {
        "label": this.$t('用户名'),
        "key": "userName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('渠道'),
        "key": "channel",
        "control_type": "TEXT"
      }, {
        "label": this.$t('代理'),
        "key": "agent",
        "control_type": "TEXT"
      }, {
        "label": this.$t('游戏'),
        "key": "gameType",
        "control_type": "TEXT"
      }, {
        "label": this.$t('币种'),
        "key": "currency",
        "control_type": "TEXT"
      }, {
        "label": this.$t('局号'),
        "key": "roundId",
        "control_type": "TEXT"
      }, {
        "label": this.$t('创建时间'),
        "key": "createTime",
        "control_type": "TEXT"
      }, {
        "label": this.$t('结算时间'),
        "key": "cORRECTTIME",
        "control_type": "TEXT"
      }, {
        "label": this.$t('多台'),
        "key": "enterType",
        "control_type": "TEXT"
      }, {
        "label": this.$t('桌号'),
        "key": "tableNum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('结果'),
        "key": "result",
        "control_type": "TEXT"
      }, {
        "label": this.$t('投注额'),
        "key": "betamount",
        "control_type": "TEXT"
      }, {
        "label": this.$t('有效投注额'),
        "key": "validBetAmount",
        "control_type": "TEXT"
      }, {
        "label": this.$t('游戏输赢'),
        "key": "money",
        "control_type": "TEXT"
      }, {
        "label": 'IP',
        "key": "ip",
        "control_type": "TEXT"
      }, {
        "label": this.$t('坐标'),
        "key": "location",
        "control_type": "TEXT"
      }, {
        "label": this.$t('风险类型'),
        "key": "riskType",
        "control_type": "TEXT"
      }, {
        "label": this.$t('风险分数'),
        "key": "betRiskScore",
        "control_type": "TEXT"
      }];
      var normal = [{
        "label": this.$t('注单号'),
        "key": "id",
        "control_type": "TEXT"
      }, {
        "label": this.$t('状态'),
        "key": "status",
        "control_type": "TEXT"
      }, {
        "label": this.$t('用户名'),
        "key": "userName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('渠道'),
        "key": "channel",
        "control_type": "TEXT"
      }, {
        "label": this.$t('代理'),
        "key": "agent",
        "control_type": "TEXT"
      }, {
        "label": this.$t('游戏'),
        "key": "gameType",
        "control_type": "TEXT"
      }, {
        "label": this.$t('币种'),
        "key": "currency",
        "control_type": "TEXT"
      }, {
        "label": this.$t('局号'),
        "key": "roundId",
        "control_type": "TEXT"
      }, {
        "label": this.$t('创建时间'),
        "key": "createTime",
        "control_type": "TEXT"
      }, {
        "label": this.$t('结算时间'),
        "key": "cORRECTTIME",
        "control_type": "TEXT"
      }, {
        "label": this.$t('多台'),
        "key": "enterType",
        "control_type": "TEXT"
      }, {
        "label": this.$t('桌号'),
        "key": "tableNum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('结果'),
        "key": "result",
        "control_type": "TEXT"
      }, {
        "label": this.$t('投注额'),
        "key": "betamount",
        "control_type": "TEXT"
      }, {
        "label": this.$t('有效投注额'),
        "key": "validBetAmount",
        "control_type": "TEXT"
      }, {
        "label": this.$t('游戏输赢'),
        "key": "money",
        "control_type": "TEXT"
      }];
      return this.isAdmin ? admin : normal;
    },
    parseParam: function parseParam(param) {
      var paramStr = '';
      Object.keys(param).forEach(function (key, index) {
        if (index === 0) {
          paramStr += key + "=" + encodeURIComponent(param[key]);
        } else {
          paramStr += "&" + key + "=" + encodeURIComponent(param[key]);
        }
      });
      return paramStr;
    },
    getParams: function getParams() {
      var obj = {};
      var keyArr = ['beginTime', 'endTime', 'channel', 'tableIds', 'game', 'betRiskScore'];
      for (var i in this.queryForm) {
        if (keyArr.includes(i) && (!this.queryForm[i] || this.queryForm[i] instanceof Array && this.queryForm[i].length == 0)) {
          obj[i] = '';
        } else {
          obj[i] = this.queryForm[i];
        }
      }
      if (obj['tableIds']) {
        var str = obj['tableIds'];
        var arr = str.toString().split(',');
        var arr1 = [];
        arr.forEach(function (item, index) {
          if (index % 2 == 1) {
            arr1.push(item);
          }
        });
        obj['tableIds'] = arr1;
      }
      obj['channel'] = obj['channel'].toString();
      obj['tableIds'] = obj['tableIds'].toString();
      if (!this.isAdmin) {
        delete obj['channel'];
        delete obj['ip'];
        delete obj['betRiskScore'];
      }
      return obj;
    },
    checkParams: function checkParams() {
      var _this$queryForm = this.queryForm,
        userName = _this$queryForm.userName,
        note = _this$queryForm.note,
        round = _this$queryForm.round,
        beginTime = _this$queryForm.beginTime,
        endTime = _this$queryForm.endTime;
      if (!userName && !note && !round && (!beginTime || !endTime)) {
        this.$message.error(this.$t('用户名、注单号、局号、时间范围必填其一'));
        return false;
      }
      var beginTimeT = _lodash.default.cloneDeep(beginTime);
      var endTimeT = _lodash.default.cloneDeep(endTime);
      var timeDiff = new Date(endTimeT).getTime() - new Date(beginTimeT).getTime();
      var formatTimeDiff = timeDiff / (3600 * 1000 * 24);
      if (formatTimeDiff > 2) {
        this.$message.error(this.$t('时间范围只能在2天内'));
        return false;
      }
      return true;
    },
    getList: function getList() {
      var _this5 = this;
      if (!this.checkParams()) {
        return;
      }
      this.loading = true;
      var pa = this.getParams();
      var params = this.parseParam(pa);
      (0, _liveGameRecord.getList)(params).then(function (res) {
        _this5.loading = false;
        if (res.code == 200) {
          var form = new FormData();
          for (var i in pa) {
            form.append(i, pa[i]);
          }
          _this5.getSumList(form);
          _this5.tableData = {
            list: res.data,
            total: res.count,
            totalPage: 1
          };
        } else {
          _this5.$message.error(_this5.$t(res.message));
          _this5.tableData = {
            list: [],
            total: 0,
            totalPage: 1
          };
        }
      }).catch(function (err) {
        _this5.loading = false;
        _this5.tableData = {
          list: [],
          total: 0,
          totalPage: 1
        };
      });
    },
    getSumList: function getSumList(params) {
      var _this6 = this;
      (0, _liveGameRecord.getSumList)(params).then(function (res) {
        if (res.code == 200) {
          _this6.totalList = res.data;
        } else {
          _this6.$message.error(_this6.$t(res.message));
          _this6.totalList = [];
        }
      }).catch(function (err) {
        _this6.totalList = [];
      });
    },
    // 页码size 改变
    handleSizeChange: function handleSizeChange(size) {
      setTimeout(function () {
        window.scrollTo({
          left: 0,
          top: 0
        });
      }, 10);
      this.queryForm.limit = size;
      this.queryForm.page = 1;
      this.getList();
    },
    // 分页数据改变
    handleCurrentChange: function handleCurrentChange(val) {
      window.scrollTo({
        left: 0,
        top: 0
      });
      this.queryForm.page = val;
      this.getList();
    },
    getChannelList: function getChannelList() {
      var _this7 = this;
      (0, _common.getChannel)().then(function (res) {
        if (res.code == 200) {
          _this7.channelList = res.data;
        } else {
          _this7.$message.error(_this7.$t(res.message));
          _this7.channelList = [];
        }
      }).catch(function (err) {
        _this7.channelList = [];
      });
    },
    getCurrencyList: function getCurrencyList() {
      var _this8 = this;
      (0, _common.getCurrency)().then(function (res) {
        if (res.code == 200) {
          _this8.currencyList = res.data.map(function (val) {
            val.name = (0, _gameProperties.renderCurrency)(val.name);
            return val;
          });
        } else {
          _this8.$message.error(_this8.$t(res.message));
          _this8.currencyList = [];
        }
      }).catch(function (err) {
        _this8.currencyList = [];
      });
    },
    getTableIdsList: function getTableIdsList() {
      var _this9 = this;
      (0, _common.getTableIds)().then(function (res) {
        if (res.code == 200) {
          _this9.tableIdsList = (0, _gameProperties.deepgreeTree)(_lodash.default.cloneDeep(res.data));
        } else {
          _this9.$message.error(_this9.$t(res.message));
          _this9.tableIdsList = [];
        }
      }).catch(function (err) {
        _this9.tableIdsList = [];
      });
    },
    setDialogData: function setDialogData() {
      var _this10 = this;
      var GameTypeMap = {
        "BACCARAT": "百家乐",
        "DRAGON-TIGER": "龙虎",
        "ROULETTE": "轮盘",
        "DOU-NIU": "斗牛",
        "SIC-BO": "骰宝",
        "WIN3CARDS": "炸金花"
      };
      var BaccaratBetPoint = {
        PLAYER: this.$t("闲"),
        BANKER: this.$t("庄"),
        TILE: this.$t("和"),
        SS: this.$t("超级六"),
        PP: this.$t("闲对"),
        BP: this.$t("庄对"),
        BIG: this.$t("大"),
        SMALL: this.$t("小"),
        AP: this.$t("任意对子"),
        PFP: this.$t("完美对子"),
        BN: this.$t("庄例牌"),
        PN: this.$t("闲例牌"),
        BDB: this.$t("庄龙宝"),
        PDB: this.$t("闲龙宝"),
        LS: this.$t("幸运六")
      };
      var WIN3CARDS = {
        PHOENIX: this.$t("凤"),
        TILE: this.$t("和"),
        DRAGON: this.$t("龙")
      };
      var WIN3CARDSBetPoint = {
        BOMB: this.$t("豹子"),
        STRAIGHT_FLUSH: this.$t("同花顺"),
        FLUSH: this.$t("同花"),
        STRAIGHT: this.$t("顺子"),
        DRAGON: this.$t("龙"),
        PHOENIX: this.$t("凤"),
        PAIR8: this.$t("对8以上")
      };
      var WIN3CARDSMORE = {
        KILLER: this.$t("豹子杀手"),
        HIGH_CARD: this.$t("散牌"),
        ONE_PAIR: this.$t("对子"),
        STRAIGHT: this.$t("顺子"),
        FLUSH: this.$t("同花"),
        STRAIGHT_FLUSH: this.$t("同花顺"),
        BOMB: this.$t("豹子")
      };
      var DTBetPoint = {
        PLAYER: this.$t("虎"),
        BANKER: this.$t("龙"),
        TILE: this.$t("和"),
        PE: this.$t("虎双"),
        BE: this.$t("龙双"),
        PO: this.$t("虎单"),
        BO: this.$t("龙单"),
        PR: this.$t("虎红"),
        BR: this.$t("龙红"),
        PB: this.$t("虎红"),
        BB: this.$t("龙红")
      };
      var RTBetPoint = {
        RoEven: this.$t("双"),
        RoOdd: this.$t("单"),
        Ro12s1: this.$t("第一打"),
        Ro12s2: this.$t("第二打"),
        Ro12s3: this.$t("第三打"),
        RoRow1: this.$t("第一列"),
        RoRow2: this.$t("第二列"),
        RoRow3: this.$t("第三列"),
        RoSm: this.$t("小"),
        RoBig: this.$t("大"),
        RoSm1: this.$t("小  1-18 "),
        RoBig1: this.$t("大  19-36"),
        RoBak: this.$t("黑"),
        RoRed: this.$t("红"),
        RedBak: this.$t("红 / 黑"),
        BigSm: this.$t("大 / 小"),
        TaxiList: this.$t("打 / 列"),
        OddEven: this.$t("单 / 双"),
        Direct_Bet: this.$t("直接注"),
        Marks_Bet: this.$t("分注"),
        Street_Bet: this.$t("街注"),
        Three_Several: this.$t("三数"),
        Corner_Bet: this.$t("角注"),
        Four_Bet: this.$t("四个号码"),
        Line_Bet: this.$t("线注"),
        Make_Bet: this.$t("列注"),
        Bet_Dozen: this.$t("下注一打"),
        RoSi88: this.$t("直注0"),
        RoSi01: this.$t("直注1"),
        RoSi02: this.$t("直注2"),
        RoSi03: this.$t("直注3"),
        RoSi04: this.$t("直注4"),
        RoSi05: this.$t("直注5"),
        RoSi06: this.$t("直注6"),
        RoSi07: this.$t("直注7"),
        RoSi08: this.$t("直注8"),
        RoSi09: this.$t("直注9"),
        RoSi10: this.$t("直注10"),
        RoSi11: this.$t("直注11"),
        RoSi12: this.$t("直注12"),
        RoSi13: this.$t("直注13"),
        RoSi14: this.$t("直注14"),
        RoSi15: this.$t("直注15"),
        RoSi16: this.$t("直注16"),
        RoSi17: this.$t("直注17"),
        RoSi18: this.$t("直注18"),
        RoSi19: this.$t("直注19"),
        RoSi20: this.$t("直注20"),
        RoSi21: this.$t("直注21"),
        RoSi22: this.$t("直注22"),
        RoSi23: this.$t("直注23"),
        RoSi24: this.$t("直注24"),
        RoSi25: this.$t("直注25"),
        RoSi26: this.$t("直注26"),
        RoSi27: this.$t("直注27"),
        RoSi28: this.$t("直注28"),
        RoSi29: this.$t("直注29"),
        RoSi30: this.$t("直注30"),
        RoSi31: this.$t("直注31"),
        RoSi32: this.$t("直注32"),
        RoSi33: this.$t("直注33"),
        RoSi34: this.$t("直注34"),
        RoSi35: this.$t("直注35"),
        RoSi36: this.$t("直注36"),
        RoT_12: this.$t("三数0,1,2"),
        RoT_23: this.$t("三数0,2,3"),
        Ro_123: this.$t("四个号0,1,2,3"),
        RoCol1: this.$t("线注 1,2,3,4,5,6"),
        RoCol2: this.$t("线注4,5,6,7,8,9 "),
        RoCol3: this.$t("线注7,8,9,10,11,12"),
        RoCol4: this.$t("线注10,11,12,13,14,15"),
        RoCol5: this.$t("线注13,14,15,16,17,18"),
        RoCol6: this.$t("线注16,17,18,19,20,21"),
        RoCol7: this.$t("线注19,20,21,22,23,24"),
        RoCol8: this.$t("线注22,23,24,25,26,27"),
        RoCol9: this.$t("线注25,26,27,28,29,30"),
        RoCol0: this.$t("线注28,29,30,31,32,33"),
        RoCola: this.$t("线注31,32,33,34,35,36"),
        Ro1245: this.$t("角注 1,2,4,5"),
        Ro2356: this.$t("角注2,3,5,6"),
        Ro4578: this.$t("角注4,5,7,8"),
        Ro5689: this.$t("角注5,6,8,9"),
        Ro780a: this.$t("角注7,8,10,11"),
        Ro89ab: this.$t("角注8,9,11,12"),
        Ro0acd: this.$t("角注10,11,13,14"),
        Roabde: this.$t("角注11,12,14,15"),
        Rocdfg: this.$t("角注13,14,16,17"),
        Rodegh: this.$t("角注14,15,17,18"),
        Rofgij: this.$t("角注16,17,19,20"),
        Roghjk: this.$t("角注17,18,20,21"),
        Roijlm: this.$t("角注19,20,22,23"),
        Rojkmn: this.$t("角注20,21,23,24"),
        Rolmop: this.$t("角注22,23,25,26"),
        Romnpq: this.$t("角注23,24,26,27"),
        Rooprs: this.$t("角注25,26,28,29"),
        Ropqst: this.$t("角注26,27,29,30"),
        Rorsuv: this.$t("角注28,29,31,32"),
        Rostvw: this.$t("角注29,30,32,33"),
        Rouvxy: this.$t("角注31,32,34,35"),
        Rovwyz: this.$t("角注32,33,35,36"),
        RoT123: this.$t("街注1,2,3 "),
        RoT456: this.$t("街注4,5,6"),
        RoT789: this.$t("街注7,8,9"),
        RoT0ab: this.$t("街注10,11,12"),
        RoTcde: this.$t("街注13,14,15"),
        RoTfgh: this.$t("街注16,17,18"),
        RoTijk: this.$t("街注19,20,21"),
        RoTlmn: this.$t("街注22,23,24"),
        RoTopq: this.$t("街注25,26,27"),
        RoTrst: this.$t("街注28,29,30"),
        RoTuvw: this.$t("街注31,32,33"),
        RoTxyz: this.$t("街注34,35,36"),
        RoDu12: this.$t("分注1,2"),
        RoDu23: this.$t("分注2,3"),
        RoDu45: this.$t("分注4,5"),
        RoDu56: this.$t("分注5,6"),
        RoDu78: this.$t("分注7,8"),
        RoDu89: this.$t("分注8,9"),
        RoDu0a: this.$t("分注10,11"),
        RoDuab: this.$t("分注11,12"),
        RoDucd: this.$t("分注13,14"),
        RoDude: this.$t("分注14,15"),
        RoDufg: this.$t("分注16,17"),
        RoDugh: this.$t("分注17,18"),
        RoDuij: this.$t("分注19,20"),
        RoDujk: this.$t("分注20,21"),
        RoDulm: this.$t("分注22,23"),
        RoDumn: this.$t("分注23,24"),
        RoDuop: this.$t("分注25,26"),
        RoDupq: this.$t("分注26,27"),
        RoDurs: this.$t("分注28,29"),
        RoDust: this.$t("分注29,30"),
        RoDuuv: this.$t("分注31,32"),
        RoDuvw: this.$t("分注32,33"),
        RoDuxy: this.$t("分注34,35"),
        RoDuyz: this.$t("分注35,36"),
        RoDu_1: this.$t("分注0,1"),
        RoDu_2: this.$t("分注0,2"),
        RoDu_3: this.$t("分注0,3"),
        RoDu14: this.$t("分注1,4"),
        RoDu25: this.$t("分注2,5"),
        RoDu36: this.$t("分注3,6"),
        RoDu47: this.$t("分注4,7"),
        RoDu58: this.$t("分注5,8"),
        RoDu69: this.$t("分注6,9"),
        RoDu70: this.$t("分注7,10"),
        RoDu8a: this.$t("分注8,11"),
        RoDu9b: this.$t("分注9,12"),
        RoDu0c: this.$t("分注10,13"),
        RoDuad: this.$t("分注11,14"),
        RoDube: this.$t("分注12,15"),
        RoDucf: this.$t("分注13,16"),
        RoDudg: this.$t("分注14,17"),
        RoDueh: this.$t("分注15,18"),
        RoDufi: this.$t("分注16,19"),
        RoDugj: this.$t("分注17,20"),
        RoDuhk: this.$t("分注18,21"),
        RoDuil: this.$t("分注19,22"),
        RoDujm: this.$t("分注20,23"),
        RoDukn: this.$t("分注21,24"),
        RoDulo: this.$t("分注22,25"),
        RoDump: this.$t("分注23,26"),
        RoDunq: this.$t("分注24,27"),
        RoDuor: this.$t("分注25,28"),
        RoDups: this.$t("分注26,29"),
        RoDuqt: this.$t("分注27,30"),
        RoDuru: this.$t("分注28,31"),
        RoDusv: this.$t("分注29,32"),
        RoDutw: this.$t("分注30,33"),
        RoDuux: this.$t("分注31,34"),
        RoDuvy: this.$t("分注32,35"),
        RoDuwz: this.$t("分注33,36")
      };
      var NiuniuPoint = {
        PLAYER1: this.$t("闲1"),
        FAN_PLAYER1: this.$t("闲1翻倍"),
        PLAYER2: this.$t("闲2"),
        FAN_PLAYER2: this.$t("闲2翻倍"),
        PLAYER3: this.$t("闲3"),
        FAN_PLAYER3: this.$t("闲3翻倍")
      };
      var SicboPoint = {
        DiTri0: this.$t("全围骰"),
        DiTri1: this.$t("围骰1"),
        DiTri2: this.$t("围骰2"),
        DiTri3: this.$t("围骰3"),
        DiTri4: this.$t("围骰4"),
        DiTri5: this.$t("围骰5"),
        DiTri6: this.$t("围骰6"),
        DiDou1: this.$t("逢双1"),
        DiDou2: this.$t("逢双2"),
        DiDou3: this.$t("逢双3"),
        DiDou4: this.$t("逢双4"),
        DiDou5: this.$t("逢双5"),
        DiDou6: this.$t("逢双6"),
        DiTo04: this.$t("4点"),
        DiTo05: this.$t("5点"),
        DiTo06: this.$t("6点"),
        DiTo07: this.$t("7点"),
        DiTo08: this.$t("8点"),
        DiTo09: this.$t("9点"),
        DiTo10: this.$t("10点"),
        DiTo11: this.$t("11点"),
        DiTo12: this.$t("12点"),
        DiTo13: this.$t("13点"),
        DiTo14: this.$t("14点"),
        DiTo15: this.$t("15点"),
        DiTo16: this.$t("16点"),
        DiTo17: this.$t("17点"),
        DiTw12: this.$t("组合1-2"),
        DiTw13: this.$t("组合1-3"),
        DiTw14: this.$t("组合1-4"),
        DiTw15: this.$t("组合1-5"),
        DiTw16: this.$t("组合1-6"),
        DiTw23: this.$t("组合2-3"),
        DiTw24: this.$t("组合2-4"),
        DiTw25: this.$t("组合2-5"),
        DiTw26: this.$t("组合2-6"),
        DiTw34: this.$t("组合3-4"),
        DiTw35: this.$t("组合3-5"),
        DiTw36: this.$t("组合3-6"),
        DiTw45: this.$t("组合4-5"),
        DiTw46: this.$t("组合4-6"),
        DiTw56: this.$t("组合5-6"),
        DiOne1: this.$t("单1"),
        DiOne2: this.$t("单2"),
        DiOne3: this.$t("单3"),
        DiOne4: this.$t("单4"),
        DiOne5: this.$t("单5"),
        DiOne6: this.$t("单6"),
        DiEven: this.$t("双"),
        DiOdd: this.$t("单"),
        DiBig: this.$t("大"),
        DiSm: this.$t("小")
      };
      if (Object.keys(this.dialogForm).length === 0) {
        this.$message.error(this.$t('参数错误'));
        return;
      }
      try {
        setTimeout(function () {
          var id = _this10.dialogForm.id;
          var betpoint = _this10.dialogForm.betpoint;
          var cardstr = _this10.dialogForm.CARDRESULT;
          var gametype = _this10.dialogForm.gameType;
          var gameresult = _this10.dialogForm.WINPOINT;
          var jp = _this10.dialogForm.jp;
          var gameName = GameTypeMap[gametype];
          var eBetid = document.getElementById("betid2");
          eBetid.innerHTML = "<label>" + _this10.$t('注单号') + ":</label> " + id;
          var eGameName = document.getElementById("gameName2");
          eGameName.innerHTML = "<label>" + _this10.$t('游戏类型') + ":</label> " + _this10.$t(gameName);
          var firstCard = document.getElementById("firstCard2");
          var eCardlist = document.getElementById("cardlist2");
          var eGameresult = document.getElementById("gameresult2");
          var eBetpoint = document.getElementById("betpoint2");
          var betPointArr = betpoint.substring(0, betpoint.length - 1).split("#");
          firstCard.innerHTML = '';
          eGameName.innerHTML = '';
          eCardlist.innerHTML = '';
          eGameresult.innerHTML = '';
          eBetpoint.getElementsByTagName("tbody")[0].innerHTML = '';
          if (gameName == "百家乐") {
            var cardArr = cardstr.split(";");
            var p1 = _this10.GetCardImgName(cardArr[0]);
            var b1 = _this10.GetCardImgName(cardArr[1]);
            var p2 = _this10.GetCardImgName(cardArr[2]);
            var b2 = _this10.GetCardImgName(cardArr[3]);
            var p3 = _this10.GetCardImgName(cardArr[4]);
            var b3 = _this10.GetCardImgName(cardArr[5]);
            var sumvalue = _this10.BaccaratCardSum(cardstr);
            var pg1 = "<label>" + _this10.$t('庄') + "(" + sumvalue[0] + "):</label>";
            var pg2 = "<label>" + _this10.$t('闲') + "(" + sumvalue[1] + "):</label>";
            var picP1 = require("../../../assets/imagesPk/".concat(p1, ".png"));
            var picP2 = require("../../../assets/imagesPk/".concat(p2, ".png"));
            var picB1 = require("../../../assets/imagesPk/".concat(b1, ".png"));
            var picB2 = require("../../../assets/imagesPk/".concat(b2, ".png"));
            eCardlist.innerHTML += pg2;
            if (p3) {
              var picP3 = require("../../../assets/imagesPk/".concat(p3, ".png"));
              eCardlist.innerHTML += "<image src=" + picP3 + " style='transform: rotate(90deg);padding-left: 13px;padding-right: 15px;'></image>";
            }
            eCardlist.innerHTML += "<image src=" + picP1 + "></image>";
            eCardlist.innerHTML += "<image src=" + picP2 + "></image>";
            eCardlist.innerHTML += pg1;
            eCardlist.innerHTML += "<image src=" + picB1 + "></image>";
            eCardlist.innerHTML += "<image src=" + picB2 + "></image>";
            if (b3) {
              var picB3 = require("../../../assets/imagesPk/".concat(b3, ".png"));
              eCardlist.innerHTML += "<image src=" + picB3 + " style='transform: rotate(90deg);padding-left: 13px;padding-right: 15px;'></image>";
            }
            var winPoint = gameresult.split(",");
            for (var i = 0; i < winPoint.length; i++) {
              eGameresult.innerHTML += "<label>" + _this10.$t('开彩') + ": </label><label  style='color: red'>" + BaccaratBetPoint[winPoint[i]] + "</label>";
            }
            for (var _i = 0; _i < betPointArr.length; _i++) {
              var arr = betPointArr[_i].split("@");
              var name = arr[0].split(":")[0];
              var amount = arr[0].split(":")[1];
              var winlost = arr[1];
              var commamount = arr[2];
              var tr = document.createElement("tr");
              tr.innerHTML += "<td>" + (_i + 1) + "</td>";
              var pointName = BaccaratBetPoint[name];
              if (name == "BANKER" && jp == 1) {
                pointName = BaccaratBetPoint[name] + "（" + _this10.$t('免') + "）";
              }
              tr.innerHTML += "<td>" + pointName + "</td>";
              tr.innerHTML += "<td>" + amount + "</td>";
              tr.innerHTML += "<td>" + winlost + "</td>";
              tr.innerHTML += "<td>" + commamount + "</td>";
              eBetpoint.getElementsByTagName("tbody")[0].append(tr);
            }
          } else if (gameName == "炸金花") {
            var _cardArr = cardstr.split(";");
            var _p = _this10.GetCardImgName(_cardArr[0]);
            var _b = _this10.GetCardImgName(_cardArr[1]);
            var _p2 = _this10.GetCardImgName(_cardArr[2]);
            var _b2 = _this10.GetCardImgName(_cardArr[3]);
            var _p3 = _this10.GetCardImgName(_cardArr[4]);
            var _b3 = _this10.GetCardImgName(_cardArr[5]);
            var dCardType = _this10.dialogForm.dCardType.split('-');
            var pCardType = _this10.dialogForm.pCardType.split('-');
            var _pg, _pg2;
            if (dCardType[0] == 'HIGH_CARD' || dCardType[0] == 'ONE_PAIR') {
              _pg = "<label>" + _this10.$t('龙') + "(" + WIN3CARDSMORE[dCardType[0]] + (0, _gameProperties.exchangeCard)(dCardType[1]) + "):</label>";
            } else {
              _pg = "<label>" + _this10.$t('龙') + "(" + WIN3CARDSMORE[dCardType[0]] + "):</label>";
            }
            if (pCardType[0] == 'HIGH_CARD' || pCardType[0] == 'ONE_PAIR') {
              _pg2 = "<label>" + _this10.$t('凤') + "(" + WIN3CARDSMORE[pCardType[0]] + (0, _gameProperties.exchangeCard)(pCardType[1]) + "):</label>";
            } else {
              _pg2 = "<label>" + _this10.$t('凤') + "(" + WIN3CARDSMORE[pCardType[0]] + "):</label>";
            }
            var _picP = require("../../../assets/imagesPk/".concat(_p, ".png"));
            var _picP2 = require("../../../assets/imagesPk/".concat(_p2, ".png"));
            var _picP3 = require("../../../assets/imagesPk/".concat(_p3, ".png"));
            var _picB = require("../../../assets/imagesPk/".concat(_b, ".png"));
            var _picB2 = require("../../../assets/imagesPk/".concat(_b2, ".png"));
            var _picB3 = require("../../../assets/imagesPk/".concat(_b3, ".png"));
            eCardlist.innerHTML += _pg;
            eCardlist.innerHTML += "<image src=" + _picP + "></image>";
            eCardlist.innerHTML += "<image src=" + _picP2 + "></image>";
            eCardlist.innerHTML += "<image src=" + _picP3 + " style='margin-right:10px;'></image>";
            eCardlist.innerHTML += _pg2;
            eCardlist.innerHTML += "<image src=" + _picB + "></image>";
            eCardlist.innerHTML += "<image src=" + _picB2 + "></image>";
            eCardlist.innerHTML += "<image src=" + _picB3 + "></image>";
            var _winPoint = gameresult;
            eGameresult.innerHTML += "<label>" + _this10.$t('开彩') + ": </label><label  style='color: red'>" + WIN3CARDS[_winPoint] + "</label>";
            for (var _i2 = 0; _i2 < betPointArr.length; _i2++) {
              var _arr = betPointArr[_i2].split("@");
              var _name = _arr[0].split(":")[0];
              var _amount = _arr[0].split(":")[1];
              var _winlost = _arr[1];
              var _commamount = _arr[2];
              var _tr = document.createElement("tr");
              _tr.innerHTML += "<td>" + (_i2 + 1) + "</td>";
              var _pointName = WIN3CARDSBetPoint[_name];
              _tr.innerHTML += "<td>" + _pointName + "</td>";
              _tr.innerHTML += "<td>" + _amount + "</td>";
              _tr.innerHTML += "<td>" + _winlost + "</td>";
              _tr.innerHTML += "<td>" + _commamount + "</td>";
              eBetpoint.getElementsByTagName("tbody")[0].append(_tr);
            }
          } else if (gameName == "龙虎") {
            var _cardArr2 = cardstr.split(";");
            var _p4 = _this10.GetCardImgName(_cardArr2[0]);
            var _b4 = _this10.GetCardImgName(_cardArr2[1]);
            var _pg3 = "<label>" + _this10.$t('龙') + ":</label>";
            var _pg4 = "<label>" + _this10.$t('虎') + ":</label>";
            var _picP4 = require("../../../assets/imagesPk/".concat(_p4, ".png"));
            var _picB4 = require("../../../assets/imagesPk/".concat(_b4, ".png"));
            eCardlist.innerHTML += _pg3;
            eCardlist.innerHTML += "<image src=" + _picB4 + "></image>";
            eCardlist.innerHTML += _pg4;
            eCardlist.innerHTML += "<image src=" + _picP4 + "></image>";
            var _winPoint2 = gameresult.split(",");
            for (var _i3 = 0; _i3 < _winPoint2.length; _i3++) {
              eGameresult.innerHTML += "<label>" + _this10.$t('开彩') + ":</label> <label  style='color: red'>" + DTBetPoint[_winPoint2[_i3]] + "</label>";
            }
            for (var _i4 = 0; _i4 < betPointArr.length; _i4++) {
              var _arr2 = betPointArr[_i4].split("@");
              var _name2 = _arr2[0].split(":")[0];
              var _amount2 = _arr2[0].split(":")[1];
              var _winlost2 = _arr2[1];
              var _commamount2 = _arr2[2];
              var _tr2 = document.createElement("tr");
              _tr2.innerHTML += "<td>" + (_i4 + 1) + "</td>";
              _tr2.innerHTML += "<td>" + DTBetPoint[_name2] + "</td>";
              _tr2.innerHTML += "<td>" + _amount2 + "</td>";
              _tr2.innerHTML += "<td>" + _winlost2 + "</td>";
              _tr2.innerHTML += "<td>" + _commamount2 + "</td>";
              eBetpoint.getElementsByTagName("tbody")[0].append(_tr2);
            }
          } else if (gameName == "轮盘") {
            eCardlist.innerHTML += "<label style='color: red'>" + cardstr + "</label>";
            var _pg5 = "<label>" + _this10.$t('开奖结果') + ":&nbsp;</label>";
            for (var _i5 = 0; _i5 < betPointArr.length; _i5++) {
              var _arr3 = betPointArr[_i5].split("@");
              var _name3 = _arr3[0].split(":")[0];
              var _amount3 = _arr3[0].split(":")[1];
              var _winlost3 = _arr3[1];
              var _commamount3 = _arr3[2];
              var _tr3 = document.createElement("tr");
              _tr3.innerHTML += "<td>" + (_i5 + 1) + "</td>";
              _tr3.innerHTML += "<td>" + RTBetPoint[_name3] + "</td>";
              _tr3.innerHTML += "<td>" + _amount3 + "</td>";
              _tr3.innerHTML += "<td>" + _winlost3 + "</td>";
              _tr3.innerHTML += "<td>" + _commamount3 + "</td>";
              eBetpoint.getElementsByTagName("tbody")[0].append(_tr3);
            }
          } else if (gameName == "斗牛") {
            var winpoint = eval(gameresult);
            var _cardArr3 = cardstr.split(";");
            firstCard.innerHTML += "<image style='margin-left: 8%;' src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[0]), ".png")) + "></image>";
            firstCard.style.display = "block";
            var text1 = "<li><label style='color: red;width: 60px;display: inline-block;'>" + _this10.$t("庄") + "(" + _this10.niuniuResult(winpoint[0]) + ")" + "</label>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[1]), ".png")) + "></image>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[2]), ".png")) + "></image>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[3]), ".png")) + "></image>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[4]), ".png")) + "></image>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[5]), ".png")) + "></image>" + "</li>";
            eCardlist.innerHTML += text1;
            var text2 = "<li><label style='color: red;width: 60px;display: inline-block;'>" + _this10.$t("闲1") + "(" + _this10.niuniuResult(winpoint[1]) + ")" + "</label>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[6]), ".png")) + "></image>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[7]), ".png")) + "></image>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[8]), ".png")) + "></image>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[9]), ".png")) + "></image>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[10]), ".png")) + "></image>" + "</li>";
            eCardlist.innerHTML += text2;
            var text3 = "<li><label style='color: red;width: 60px;display: inline-block;'>" + _this10.$t("闲2") + "(" + _this10.niuniuResult(winpoint[2]) + ")" + "</label>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[11]), ".png")) + "></image>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[12]), ".png")) + "></image>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[13]), ".png")) + "></image>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[14]), ".png")) + "></image>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[15]), ".png")) + "></image>" + "</li>";
            eCardlist.innerHTML += text3;
            var text4 = "<li><label style='color: red;width: 60px;display: inline-block;'>" + _this10.$t("闲3") + "(" + _this10.niuniuResult(winpoint[3]) + ")" + "</label>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[16]), ".png")) + "></image>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[17]), ".png")) + "></image>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[18]), ".png")) + "></image>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[19]), ".png")) + "></image>" + "<image src=" + require("../../../assets/imagesPk/".concat(_this10.GetCardImgName(_cardArr3[20]), ".png")) + "></image>" + "</li>";
            eCardlist.innerHTML += text4;
            for (var _i6 = 0; _i6 < betPointArr.length; _i6++) {
              var _arr4 = betPointArr[_i6].split("@");
              var _name4 = _arr4[0].split(":")[0];
              if (_name4.startsWith("YAJIN")) {
                continue;
              }
              var _amount4 = _arr4[0].split(":")[1];
              var _winlost4 = _arr4[1];
              var _commamount4 = _arr4[2];
              var _tr4 = document.createElement("tr");
              _tr4.innerHTML += "<td>" + (_i6 + 1) + "</td>";
              _tr4.innerHTML += "<td>" + NiuniuPoint[_name4] + "</td>";
              _tr4.innerHTML += "<td>" + _amount4 + "</td>";
              _tr4.innerHTML += "<td>" + _winlost4 + "</td>";
              _tr4.innerHTML += "<td>" + _commamount4 + "</td>";
              eBetpoint.getElementsByTagName("tbody")[0].append(_tr4);
            }
          } else if (gameName == "骰宝") {
            eCardlist.innerHTML += "<label style='color: red'>" + cardstr + "</label>";
            for (var _i7 = 0; _i7 < betPointArr.length; _i7++) {
              var _arr5 = betPointArr[_i7].split("@");
              var _name5 = _arr5[0].split(":")[0];
              var _amount5 = _arr5[0].split(":")[1];
              var _winlost5 = _arr5[1];
              var _commamount5 = _arr5[2];
              var _tr5 = document.createElement("tr");
              _tr5.innerHTML += "<td>" + (_i7 + 1) + "</td>";
              _tr5.innerHTML += "<td>" + SicboPoint[_name5] + "</td>";
              _tr5.innerHTML += "<td>" + _amount5 + "</td>";
              _tr5.innerHTML += "<td>" + _winlost5 + "</td>";
              _tr5.innerHTML += "<td>" + _commamount5 + "</td>";
              eBetpoint.getElementsByTagName("tbody")[0].append(_tr5);
            }
          }
        }, 100);
      } catch (e) {
        throw new Error(e);
      }
    },
    niuniuResult: function niuniuResult(obj) {
      if (obj["fullNiu"]) {
        return this.$t("五公牛");
      } else if (obj["hasNiu"] && obj["point"] == 0) {
        return this.$t("牛牛");
      } else if (obj["hasNiu"]) {
        return this.$t("牛") + obj["point"];
      } else {
        return this.$t("无牛");
      }
    },
    //1(S)黑桃SPADE，2(H)红心HEART，3(C)梅花CLUB，4(D)菱形DIAMOND
    GetCardImgName: function GetCardImgName(str) {
      if (!str) {
        return null;
      }
      var arr = str.split("-");
      if (arr[0] == 0) {
        return null;
      }
      if (arr[1] == 1) {
        return "S" + arr[0];
      } else if (arr[1] == 2) {
        return "H" + arr[0];
      } else if (arr[1] == 3) {
        return "C" + arr[0];
      } else if (arr[1] == 4) {
        return "D" + arr[0];
      }
    },
    BaccaratCardSum: function BaccaratCardSum(str) {
      if (!str) {
        return null;
      }
      var arr = str.split(";");
      var value = [];
      for (var i = 0; i < arr.length; i++) {
        var card = arr[i].split("-");
        value.push(card[0]);
      }
      var player = parseInt(value[0] > 10 ? 10 : value[0]) + parseInt(value[2] > 10 ? 10 : value[2]) + parseInt(value[4] ? value[4] > 10 ? 10 : value[4] : 0);
      var banker = parseInt(value[1] > 10 ? 10 : value[1]) + parseInt(value[3] > 10 ? 10 : value[3]) + parseInt(value[5] ? value[5] > 10 ? 10 : value[5] : 0);
      return [banker % 10, player % 10];
    },
    // 靴局展示
    shoeRound: function shoeRound(item) {
      var SHOENUM = item.SHOENUM || 'x';
      var GAMBLINGNUM = item.GAMBLINGNUM || 'x';
      var title = "".concat(this.$t('X靴Y局').replace('X', SHOENUM).replace('Y', GAMBLINGNUM), "(").concat(item.roundId, ")");
      return title;
    }
  }
};