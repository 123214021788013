var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.queryForm, inline: true },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("账户名"),
                    },
                    model: {
                      value: _vm.queryForm.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "userName", $$v)
                      },
                      expression: "queryForm.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { placeholder: _vm.$t("游戏") },
                      model: {
                        value: _vm.queryForm.gameId,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "gameId", $$v)
                        },
                        expression: "queryForm.gameId",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("全部"), value: "" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("百家乐"), value: 1000 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("龙虎斗"), value: 1001 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("21点百家乐"), value: 1002 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("数字哈希"), value: 1003 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("双尾哈希"), value: 1004 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("炸金花"), value: 1005 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("牛牛"), value: 1006 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("幸运德州"), value: 1007 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("轮盘"), value: 1008 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("骰宝"), value: 1009 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("极限跳伞"), value: 2000 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("极限跳伞") + 2, value: 2001 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("局号"),
                    },
                    model: {
                      value: _vm.queryForm.roundId,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "roundId", $$v)
                      },
                      expression: "queryForm.roundId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("订单ID"),
                    },
                    model: {
                      value: _vm.queryForm.sourceId,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "sourceId", $$v)
                      },
                      expression: "queryForm.sourceId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { placeholder: _vm.$t("渠道"), filterable: "" },
                      model: {
                        value: _vm.queryForm.channel,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "channel", $$v)
                        },
                        expression: "queryForm.channel",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("全部"), value: "" },
                      }),
                      _vm._l(_vm.channelList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item, value: item },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("代理"),
                    },
                    model: {
                      value: _vm.queryForm.agentName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "agentName", $$v)
                      },
                      expression: "queryForm.agentName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: _vm.$t("开始时间"),
                    },
                    model: {
                      value: _vm.queryForm.beginTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "beginTime", $$v)
                      },
                      expression: "queryForm.beginTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: _vm.$t("结束时间"),
                    },
                    model: {
                      value: _vm.queryForm.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "endTime", $$v)
                      },
                      expression: "queryForm.endTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("注单号"),
                    },
                    model: {
                      value: _vm.queryForm.id,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "id", $$v)
                      },
                      expression: "queryForm.id",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.onSearch },
                    },
                    [_vm._v(_vm._s(_vm.$t("搜索")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-download",
                        size: "mini",
                      },
                      on: { click: _vm.onOutput },
                    },
                    [_vm._v(_vm._s(_vm.$t("导出")))]
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("统计维度") } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupChannel,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupChannel", $$v)
                        },
                        expression: "queryForm.groupChannel",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("渠道")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupCurrency,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupCurrency", $$v)
                        },
                        expression: "queryForm.groupCurrency",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("币种")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupProxy,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupProxy", $$v)
                        },
                        expression: "queryForm.groupProxy",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("代理")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupUserName,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupUserName", $$v)
                        },
                        expression: "queryForm.groupUserName",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("用户名称")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupDate", $$v)
                        },
                        expression: "queryForm.groupDate",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("按日期")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "45px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.tableData.list,
          },
        },
        [
          [
            _c("el-table-column", {
              attrs: {
                prop: "userName",
                label: _vm.$t("账户名"),
                "min-width": "100",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "tableName",
                label: _vm.$t("桌号"),
                "min-width": "100",
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.$t(scope.row.tableName)))]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "gameName",
                label: _vm.$t("游戏"),
                "min-width": "100",
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.$t(scope.row.gameName)))]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "currency",
                label: _vm.$t("币种"),
                "min-width": "140",
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.renderCurrency(scope.row.currency))),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "channel",
                label: _vm.$t("渠道"),
                "min-width": "100",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "agentName",
                label: _vm.$t("代理"),
                "min-width": "100",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "id",
                label: _vm.$t("注单号"),
                "min-width": "130",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "roundId",
                label: _vm.$t("局号"),
                "min-width": "100",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "amount",
                label: _vm.$t("总下注"),
                "min-width": "100",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "commamount",
                label: _vm.$t("洗码量"),
                "min-width": "100",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "result",
                label: _vm.$t("总派彩"),
                "min-width": "100",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "userResult",
                label: _vm.$t("收入"),
                "min-width": "100",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "sourceId",
                label: _vm.$t("订单ID"),
                "min-width": "140",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "betDate",
                label: _vm.$t("日期"),
                "min-width": "140",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "betTime",
                label: _vm.$t("投注时间"),
                "min-width": "140",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                label: _vm.$t("操作"),
                "min-width": "100",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      !_vm.isDimen
                        ? _c(
                            "div",
                            {
                              staticClass: "btn-self-danger",
                              on: {
                                click: function ($event) {
                                  return _vm.detailHandle(scope.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("详情")) + " ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "pagination-content" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryForm.page,
              "page-sizes": [10, 20, 30, 40, 50],
              "page-size": _vm.queryForm.limit,
              total: parseInt(_vm.tableData.total),
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "45px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.totalList,
            "show-summary": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "CCYNAME",
              label: _vm.$t("币种"),
              "min-width": "100",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.renderCurrency(scope.row.CCYNAME))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rounds",
              label: _vm.$t("总笔数"),
              "min-width": "140",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "betAmount",
              label: _vm.$t("投注总额"),
              "min-width": "200",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "validbetamount",
              label: _vm.$t("洗码量总额"),
              "min-width": "200",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "money",
              label: _vm.$t("游戏输赢总额"),
              "min-width": "200",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "realProfitability",
              label: _vm.$t("实际盈利率"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.formatPercent(
                            (scope.row.money / scope.row.validbetamount) * 100
                          ) + "%"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.dialog,
            title: _vm.$t("押注详情"),
            width: "1100px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c("div", { staticStyle: { height: "600px" } }, [
            _c("iframe", {
              staticStyle: { width: "100%", height: "100%", border: "none" },
              attrs: { src: _vm.modelUrl },
            }),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }