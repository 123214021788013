var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "45px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.tableData.list,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "id",
              label: _vm.$t("序号"),
              "min-width": "100",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "percent1",
              label: _vm.$t("EV投注百分比(大于等于)"),
              "min-width": "220",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "gameCount1",
              label: _vm.$t("局数"),
              "min-width": "150",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "percent2",
              label: _vm.$t("EV投注百分比(小于)"),
              "min-width": "200",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "gameCount2",
              label: _vm.$t("局数"),
              "min-width": "160",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "evCulValue",
              label: _vm.$t("EV加权值"),
              "min-width": "140",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "betTime",
              label: _vm.$t("投注时长"),
              "min-width": "140",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "onLineTime",
              label: _vm.$t("在线时长"),
              "min-width": "130",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "fastBetCount",
              label: _vm.$t("短投注次数"),
              "min-width": "180",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "gameCount3",
              label: _vm.$t("单日局数"),
              "min-width": "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "enable",
              label: _vm.$t("状态"),
              "min-width": "100",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        style: {
                          color: scope.row.enable == true ? "green" : "red",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            scope.row.enable == true
                              ? _vm.$t("启用")
                              : _vm.$t("禁用")
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "opeTimeStr",
              label: _vm.$t("操作时间"),
              "min-width": "140",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "remark",
              label: _vm.$t("备注"),
              "min-width": "120",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("操作"), "min-width": "100" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "btn-self-success",
                        on: {
                          click: function ($event) {
                            return _vm.editHandle(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("编辑")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.dialog,
            title: _vm.$t("编辑"),
            width: "800px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.$t("配置条件")))]),
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                model: _vm.formModel,
                size: "small",
                "label-width": "180px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("非按键投注"), prop: "condition1" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.formModel.condition1,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModel, "condition1", $$v)
                        },
                        expression: "formModel.condition1",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("非按键投注")))]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("EV会员"), prop: "condition2" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.formModel.condition2,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModel, "condition2", $$v)
                        },
                        expression: "formModel.condition2",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("EV会员")))]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("EV投注百分比(大于等于)"),
                    prop: "percent1",
                  },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.formModel.condition21,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModel, "condition21", $$v)
                        },
                        expression: "formModel.condition21",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("EV投注百分比")))]
                  ),
                  _vm.formModel.condition21
                    ? [
                        _c("el-input", {
                          staticStyle: { width: "140px", "margin-left": "6px" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.formModel.percent1,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "percent1", $$v)
                            },
                            expression: "formModel.percent1",
                          },
                        }),
                        _c("span", { staticStyle: { "margin-left": "6px" } }, [
                          _vm._v(">=90%"),
                        ]),
                        _c("span", { staticStyle: { "margin-left": "6px" } }, [
                          _vm._v(_vm._s(_vm.$t("局数"))),
                        ]),
                        _c("el-input", {
                          staticStyle: { width: "140px", "margin-left": "6px" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.formModel.gameCount1,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "gameCount1", $$v)
                            },
                            expression: "formModel.gameCount1",
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("EV投注百分比(小于)"),
                    prop: "percent2",
                  },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.formModel.condition22,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModel, "condition22", $$v)
                        },
                        expression: "formModel.condition22",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("EV投注百分比")))]
                  ),
                  _vm.formModel.condition22
                    ? [
                        _c("el-input", {
                          staticStyle: { width: "140px", "margin-left": "6px" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.formModel.percent2,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "percent2", $$v)
                            },
                            expression: "formModel.percent2",
                          },
                        }),
                        _c("span", { staticStyle: { "margin-left": "6px" } }, [
                          _vm._v("<=90%"),
                        ]),
                        _c("span", { staticStyle: { "margin-left": "6px" } }, [
                          _vm._v(_vm._s(_vm.$t("局数"))),
                        ]),
                        _c("el-input", {
                          staticStyle: { width: "140px", "margin-left": "6px" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.formModel.gameCount2,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "gameCount2", $$v)
                            },
                            expression: "formModel.gameCount2",
                          },
                        }),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("EV加权值"), prop: "evCulValue" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "140px" },
                    attrs: { clearable: "" },
                    model: {
                      value: _vm.formModel.evCulValue,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModel, "evCulValue", $$v)
                      },
                      expression: "formModel.evCulValue",
                    },
                  }),
                  _c("span", { staticStyle: { "margin-left": "6px" } }, [
                    _vm._v(">=0.85%"),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("投注时长"), prop: "betTime" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.formModel.condition3,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModel, "condition3", $$v)
                        },
                        expression: "formModel.condition3",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("投注时长")))]
                  ),
                  _vm.formModel.condition3
                    ? [
                        _c("el-input", {
                          staticStyle: { width: "140px", "margin-left": "6px" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.formModel.betTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "betTime", $$v)
                            },
                            expression: "formModel.betTime",
                          },
                        }),
                        _c("span", { staticStyle: { "margin-left": "6px" } }, [
                          _vm._v(_vm._s(_vm.$t("小时"))),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("在线时长"), prop: "onLineTime" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.formModel.condition4,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModel, "condition4", $$v)
                        },
                        expression: "formModel.condition4",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("在线时长")))]
                  ),
                  _vm.formModel.condition4
                    ? [
                        _c("el-input", {
                          staticStyle: { width: "140px", "margin-left": "6px" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.formModel.onLineTime,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "onLineTime", $$v)
                            },
                            expression: "formModel.onLineTime",
                          },
                        }),
                        _c("span", { staticStyle: { "margin-left": "6px" } }, [
                          _vm._v(_vm._s(_vm.$t("小时"))),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("短投注次数"), prop: "fastBetCount" },
                },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.formModel.condition5,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModel, "condition5", $$v)
                        },
                        expression: "formModel.condition5",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("短投注次数")))]
                  ),
                  _vm.formModel.condition5
                    ? [
                        _c("el-input", {
                          staticStyle: { width: "140px", "margin-left": "6px" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.formModel.fastBetCount,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "fastBetCount", $$v)
                            },
                            expression: "formModel.fastBetCount",
                          },
                        }),
                        _c("span", { staticStyle: { "margin-left": "6px" } }, [
                          _vm._v(">=3"),
                          _c(
                            "i",
                            {
                              staticStyle: {
                                "font-style": "normal",
                                color: "red",
                              },
                            },
                            [
                              _vm._v(
                                "[" +
                                  _vm._s(_vm.$t("一天中1秒内2个以上非同桌")) +
                                  "]"
                              ),
                            ]
                          ),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("当日局数"), prop: "gameCount3" } },
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.formModel.condition6,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModel, "condition6", $$v)
                        },
                        expression: "formModel.condition6",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("当日局数")))]
                  ),
                  _vm.formModel.condition6
                    ? [
                        _c("el-input", {
                          staticStyle: { width: "140px", "margin-left": "6px" },
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.formModel.gameCount3,
                            callback: function ($$v) {
                              _vm.$set(_vm.formModel, "gameCount3", $$v)
                            },
                            expression: "formModel.gameCount3",
                          },
                        }),
                        _c("span", { staticStyle: { "margin-left": "6px" } }, [
                          _vm._v(">2000"),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _c(
                "p",
                {
                  staticStyle: {
                    color: "red",
                    "text-indent": "100px",
                    "border-top": "solid 1px #ededed",
                    "padding-top": "20px",
                  },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("提示") +
                        ": " +
                        _vm.$t("以上所有条件为并列条件，至少选择一个配置项")
                    )
                  ),
                ]
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("状态"), prop: "enable" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formModel.enable,
                        callback: function ($$v) {
                          _vm.$set(_vm.formModel, "enable", $$v)
                        },
                        expression: "formModel.enable",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: true } }, [
                        _vm._v(_vm._s(_vm.$t("启用"))),
                      ]),
                      _c("el-radio", { attrs: { label: false } }, [
                        _vm._v(_vm._s(_vm.$t("禁用"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("备注"), prop: "remark" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "400px" },
                    attrs: {
                      placeholder: _vm.$t("6-12位英文+数字"),
                      clearable: "",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.formModel.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.formModel, "remark", $$v)
                      },
                      expression: "formModel.remark",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelForm } }, [
                _vm._v(_vm._s(_vm.$t("取消"))),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSave } },
                [_vm._v(_vm._s(_vm.$t("保存")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }