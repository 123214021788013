"use strict";

var _typeof = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.downFiles = downFiles;
require("core-js/modules/web.dom.iterable");
var XLSX = _interopRequireWildcard(require("xlsx"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function downFiles(data, columns) {
  var fileName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'test';
  try {
    var styleAmount = {
      alignment: {
        vertical: "center",
        horizontal: "right"
      },
      numFmt: '#,##0.00'
    };
    var styleDate = {
      alignment: {
        vertical: "center",
        horizontal: "right"
      },
      numFmt: 'yyyy/m/d'
    };
    var newData = formatData(data, columns);
    var wb = XLSX.utils.book_new();
    var ws = XLSX.utils.json_to_sheet(newData);
    var colWidth = columns.map(function (column) {
      return {
        wch: column.label.length * 3 + 2
      };
    });
    ws['!cols'] = colWidth;
    for (var i = 1; i <= data.length; i++) {
      for (var j = 0; j < columns.length; j++) {
        var cell = ws[XLSX.utils.encode_cell({
          r: i,
          c: j
        })];
        if (cell && cell.v === null) {
          cell.t = 's';
          cell.v = '';
        } else if (cell && cell.v !== null && columns[j].control_type === 'TEXT_WITH_AMOUNT') {
          cell.t = 'n';
          cell.s = styleAmount;
        } else if (cell && cell.v !== null && columns[j].control_type === 'DATEBOX') {
          cell.t = 'd';
          cell.s = styleDate;
        }
      }
    }
    XLSX.utils.book_append_sheet(wb, ws, fileName);
    XLSX.writeFile(wb, fileName + '.xlsx');
  } catch (e) {
    console.log(e);
    throw new Error(e);
  }
}
function formatData(data, columns) {
  var newData = [];
  data.forEach(function (item) {
    var obj = {};
    columns.forEach(function (column) {
      obj[column.label] = item[column.key];
    });
    newData.push(obj);
  });
  return newData;
}