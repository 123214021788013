"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addItem = addItem;
exports.getList = getList;
exports.updateItem = updateItem;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取列表数据
function getList(params) {
  return (0, _request.default)({
    url: "/yx/ipBlackListManagement/list?page=".concat(params.page, "&limit=").concat(params.limit, "&address=").concat(params.address),
    method: 'GET'
  });
}

// 添加
function addItem(params) {
  return (0, _request.default)({
    url: '/yx/ipBlackListManagement/add',
    method: 'POST',
    params: params
  });
}

// 修改
function updateItem(params) {
  return (0, _request.default)({
    url: '/yx/ipBlackListManagement/update',
    method: 'POST',
    params: params
  });
}