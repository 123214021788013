"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addAgency = addAgency;
exports.agencyManageList = agencyManageList;
exports.deleteAgency = deleteAgency;
exports.getChannel = getChannel;
exports.unLockAgency = unLockAgency;
exports.updateAgency = updateAgency;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取列表数据
function agencyManageList(params) {
  return (0, _request.default)({
    url: '/yx/proxyMg/list',
    method: 'GET',
    params: params
  });
}

// 添加
function addAgency(params) {
  return (0, _request.default)({
    url: '/yx/proxyMg/add',
    method: 'POST',
    params: params
  });
}

// 修改
function updateAgency(params) {
  return (0, _request.default)({
    url: '/yx/proxyMg/update',
    method: 'POST',
    params: params
  });
}

// 删除
function deleteAgency(params) {
  return (0, _request.default)({
    url: '/yx/proxyMg/delete',
    method: 'POST',
    params: params
  });
}

// 获取可选代理
function getChannel() {
  return (0, _request.default)({
    url: '/yx/channelMg/getChannel',
    method: 'GET'
  });
}

// 解锁代理
function unLockAgency(params) {
  return (0, _request.default)({
    url: '/yx/channelMg/unLock',
    method: 'GET',
    showLoading: true,
    params: params
  });
}