"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.renderZhResult = exports.renderZhPlay = exports.renderTableNmae = exports.renderResult = exports.renderPlay = exports.renderCurrency = exports.goodRoadType = exports.getcreate_time = exports.getZhTableName = exports.getTimePicker = exports.getTableName = exports.getStatus = exports.getGameName = exports.getBetPoint = exports.gameUtil = exports.gameProperties = exports.formatPercent = exports.formatMoneyToLocal = exports.formatCurrency = exports.exchangeCard = exports.deepgreeTree = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.regexp.replace");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
var _lang = _interopRequireDefault(require("../lang"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
var lang = localStorage.getItem('lang');
var gameProperties = exports.gameProperties = {
  bunildGameSelect: function bunildGameSelect(selectId) {
    var select = document.getElementById(selectId);
    var res = JSON.parse(localStorage.getItem('gametype'));
    for (var i = 0; i < res.length; i++) {
      if (!res[i].act || res[i].act !== 1) {
        var option = new Option(res[i].name, res[i].id);
        select.options.add(option);
      }
    }
  },
  bunildAllGameSelect: function bunildAllGameSelect(selectId) {
    var select = document.getElementById(selectId);
    var res = JSON.parse(localStorage.getItem('gametype'));
    for (var i = 0; i < res.length; i++) {
      var option = new Option(res[i].name, res[i].id);
      select.options.add(option);
    }
  },
  bunildActSelect: function bunildActSelect(selectId) {
    var select = document.getElementById(selectId);
    var res = JSON.parse(localStorage.getItem('gametype'));
    for (var i = 0; i < res.length; i++) {
      if (res[i].act && res[i].act == 1) {
        var option = new Option(res[i].name, res[i].id);
        select.options.add(option);
      }
    }
  },
  bunildChannelSelect: function bunildChannelSelect(selectId) {
    var select = document.getElementById(selectId);
    var res = JSON.parse(localStorage.getItem('channel'));
    for (var i = 0; i < res.length; i++) {
      var option = new Option(res[i], res[i]);
      select.options.add(option);
    }
  },
  bunildRatioSelect: function bunildRatioSelect(selectId) {
    var select = document.getElementById(selectId);
    var res = ['10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'];
    for (var i = 0; i < res.length; i++) {
      var option = new Option(res[i], res[i]);
      select.options.add(option);
    }
  },
  getTableGameOp: function getTableGameOp() {
    return [{
      label: _lang.default.tc('百家乐'),
      value: 'BACCARAT'
    }, {
      label: _lang.default.tc('龙虎'),
      value: 'DRAGON-TIGER'
    }, {
      label: _lang.default.tc('轮盘'),
      value: 'ROULETTE'
    }, {
      label: _lang.default.tc('斗牛'),
      value: 'DOU-NIU'
    }, {
      label: _lang.default.tc('骰宝'),
      value: 'SIC-BO'
    }];
  },
  dateFormat: function dateFormat(date) {
    date = new Date(date);
    var dateStr = date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2) + "-" + ("00" + date.getDate()).slice(-2) + " " + ("00" + date.getHours()).slice(-2) + ":" + ("00" + date.getMinutes()).slice(-2) + ":" + ("00" + date.getSeconds()).slice(-2);
    return dateStr;
  },
  dateAdjustByBeiJing: function dateAdjustByBeiJing(match_time) {
    var isMiniSecond = (match_time + "").length >= 13;
    var date = new Date(isMiniSecond ? match_time : match_time * 1000);
    var localTime = isMiniSecond ? match_time : match_time * 1000;
    var localOffset = date.getTimezoneOffset() * 60000; //获得当地时间bai偏移的毫du秒数
    var utc = localTime + localOffset; //utc即GMT时间
    var offset = 8; //以北京时间为例zhi，东10区
    var gtm = utc + 3600000 * offset;
    return new Date(gtm);
  },
  dateAdjustByGenLin: function dateAdjustByGenLin(match_time) {
    var isMiniSecond = (match_time + "").length >= 13;
    var date = new Date(isMiniSecond ? match_time : match_time * 1000);
    var localTime = isMiniSecond ? match_time : match_time * 1000;
    var localOffset = date.getTimezoneOffset() * 60000; //获得当地时间bai偏移的毫du秒数
    var utc = localTime + localOffset; //utc即GMT时间
    var offset = 0; //以北京时间为例zhi，东10区
    var gtm = utc + 3600000 * offset;
    return gtm;
  },
  getGameRole: function getGameRole(gametype, isbanker) {
    if ([1, 9, 34, 49].indexOf(gametype) > -1) {
      return isbanker == 1 ? "地主" : "农民";
    } else if ([2, 3, 4, 11, 12, 14, 20, 23, 24, 26, 25, 33, 47, 44, 50, 52, 39].indexOf(gametype) > -1) {
      return isbanker == 1 ? "庄" : "闲";
    }
    return "-";
  }
};
var _deepgreeTree = exports.deepgreeTree = function deepgreeTree(data) {
  var arr = [];
  if (Array.isArray(data)) {
    data.forEach(function (item) {
      if (item.children) {
        item.children = _deepgreeTree(item.children);
      }
      arr.push(_objectSpread(_objectSpread({}, item), {}, {
        label: renderTableNmae(item.name)
      }));
    });
  }
  return arr;
};
var renderCurrency = exports.renderCurrency = function renderCurrency(name) {
  if (!name) return '';

  // 中文不需要转化
  if (lang == 'zh') return name;
  var currency = name;
  try {
    currency = currency.replace(/[\u4e00-\u9fa5]/g, '').replace('(K)', '');
    return currency;
  } catch (e) {
    console.log(e);
    return currency;
  }
};
var renderTableNmae = exports.renderTableNmae = function renderTableNmae(name) {
  // 中文不需要转化

  if (lang == 'zh') return name;
  var resultTmp = name;
  try {
    var keyArr = ['龙虎', '斗牛', '牛牛', '百家乐', '新', '厅', '亚洲', '轮盘', '骰宝', '咪牌', '性感', '欧洲', '多台', '炸金花'];
    keyArr.forEach(function (key) {
      if (resultTmp.includes(key)) {
        var reg = eval("/(" + key + ")/gi");
        resultTmp = resultTmp.replace(reg, _lang.default.tc(key));
      }
    });
    return resultTmp;
  } catch (e) {
    console.log(e);
    return resultTmp;
  }
};
var renderPlay = exports.renderPlay = function renderPlay(result, tableNum) {
  var resultTmp = result;
  try {
    var keyArr = ['闲', '庄', '输', '赢'];
    keyArr.forEach(function (key) {
      if (resultTmp.includes(key)) {
        var reg = eval("/(" + key + ")/gi");
        resultTmp = resultTmp.replace(reg, _lang.default.tc(key));
      }
    });
    if (tableNum && (tableNum.includes('BACCARAT') || tableNum.includes('DRAGON-TIGER') || tableNum.includes('WIN3CARDS'))) {
      resultTmp = resultTmp.replace(/11/gi, 'J').replace(/12/gi, 'Q').replace(/13/gi, 'K');
      var arr = resultTmp.split(';');
      var a = arr.map(function (val) {
        if (val == 1) {
          return 'A';
        } else {
          return val;
        }
      });
      resultTmp = a.join(';');
    }
    if (tableNum && tableNum.includes('DOU-NIU')) {
      resultTmp = resultTmp.replace(/11/gi, 'J').replace(/12/gi, 'Q').replace(/13/gi, 'K');
      var _arr = [];
      var arr1 = resultTmp.split(':');
      arr1.forEach(function (val, index) {
        if (index % 2 == 0 || index == 0) {
          _arr.push(val + ':');
        } else {
          var arr2 = val.split(';');
          var _a = arr2.map(function (item) {
            if (item == 1) {
              return 'A';
            } else {
              return item;
            }
          });
          if (index < arr1.length - 1) {
            _arr.push(_a.join(';') + ':');
          } else {
            _arr.push(_a.join(';'));
          }
        }
      });
      resultTmp = _arr.join('');
    }
    return resultTmp;
  } catch (e) {
    console.log(e);
    return resultTmp;
  }
};
var renderZhPlay = exports.renderZhPlay = function renderZhPlay(result, tableNum) {
  var resultTmp = result;
  try {
    var reg1 = /(闲)/gi;
    var reg2 = /(庄)/gi;
    var reg3 = /(输)/gi;
    var reg4 = /(赢)/gi;
    if (resultTmp != '' && resultTmp != null && resultTmp != undefined) {
      if (reg1.test(resultTmp)) {
        resultTmp = resultTmp.replace(reg1, _lang.default.tc('闲'));
      }
      if (reg2.test(resultTmp)) {
        resultTmp = resultTmp.replace(reg2, _lang.default.tc('庄'));
      }
      if (reg3.test(resultTmp)) {
        resultTmp = resultTmp.replace(reg3, _lang.default.tc('输'));
      }
      if (reg4.test(resultTmp)) {
        resultTmp = resultTmp.replace(reg4, _lang.default.tc('赢'));
      }
    }
    if (tableNum && (tableNum.includes('百家乐') || tableNum.includes('龙虎'))) {
      resultTmp = resultTmp.replace(/11/gi, 'J').replace(/12/gi, 'Q').replace(/13/gi, 'K');
      var arr = resultTmp.split(';');
      var a = arr.map(function (val) {
        if (val == 1) {
          return 'A';
        } else {
          return val;
        }
      });
      resultTmp = a.join(';');
    }
    if (tableNum.includes('斗牛')) {
      resultTmp = resultTmp.replace(/11/gi, 'J').replace(/12/gi, 'Q').replace(/13/gi, 'K');
      var _arr2 = [];
      var arr1 = resultTmp.split(':');
      arr1.forEach(function (val, index) {
        if (index % 2 == 0 || index == 0) {
          _arr2.push(val + ':');
        } else {
          var arr2 = val.split(';');
          var _a2 = arr2.map(function (item) {
            if (item == 1) {
              return 'A';
            } else {
              return item;
            }
          });
          if (index < arr1.length - 1) {
            _arr2.push(_a2.join(';') + ':');
          } else {
            _arr2.push(_a2.join(';'));
          }
        }
      });
      resultTmp = _arr2.join('');
    }
    return resultTmp;
  } catch (e) {
    console.log(e);
    return resultTmp;
  }
};
var renderZhResult = exports.renderZhResult = function renderZhResult(row) {
  var result = row.result,
    tableNum = row.tableNum;
  var resultTmp = result;
  if (tableNum && tableNum.includes('龙虎')) {
    if (resultTmp.includes("龙")) {
      resultTmp = resultTmp.replace(/(龙)/gi, _lang.default.tc("龙"));
    }
    if (resultTmp.includes("虎")) {
      resultTmp = resultTmp.replace(/(虎)/gi, _lang.default.tc("虎"));
    }
    if (resultTmp.includes("和")) {
      resultTmp = resultTmp.replace(/(和)/gi, _lang.default.tc("和"));
    }
  } else if (tableNum && tableNum.includes('百家乐')) {
    if (resultTmp.includes("庄")) {
      resultTmp = resultTmp.replace(/(庄)/gi, _lang.default.tc("庄"));
    }
    if (resultTmp.includes("闲")) {
      resultTmp = resultTmp.replace(/(闲)/gi, _lang.default.tc("闲"));
    }
    if (resultTmp.includes("和")) {
      resultTmp = resultTmp.replace(/(和)/gi, _lang.default.tc("和"));
    }
  } else if (tableNum && tableNum.includes('斗牛')) {
    resultTmp = renderZhPlay(resultTmp, tableNum);
  } else if (tableNum && tableNum.includes('炸金花')) {
    if (resultTmp.includes("龙")) {
      resultTmp = resultTmp.replace(/(龙)/gi, _lang.default.tc("龙"));
    }
    if (resultTmp.includes("凤")) {
      resultTmp = resultTmp.replace(/(凤)/gi, _lang.default.tc("凤"));
    }
    if (resultTmp.includes("和")) {
      resultTmp = resultTmp.replace(/(和)/gi, _lang.default.tc("和"));
    }
  } else {
    resultTmp = result;
  }
  return resultTmp;
};
var exchangeCard = exports.exchangeCard = function exchangeCard(data) {
  if (data == 1) {
    return 'A';
  } else {
    return data.replace(/11/gi, 'J').replace(/12/gi, 'Q').replace(/13/gi, 'K');
  }
};
var renderResult = exports.renderResult = function renderResult(row) {
  var result = row.result,
    tableNum = row.tableNum;
  var resultTmp = '';
  if (tableNum && tableNum.includes('DRAGON-TIGER')) {
    if (result == "BANKER") {
      resultTmp = _lang.default.tc("龙");
    } else if (result == "PLAYER") {
      resultTmp = _lang.default.tc("虎");
    } else if (result == "TILE") {
      resultTmp = _lang.default.tc("和");
    }
  } else if (tableNum && tableNum.includes('BACCARAT')) {
    if (result == "BANKER") {
      resultTmp = _lang.default.tc("庄");
    } else if (result == "PLAYER") {
      resultTmp = _lang.default.tc("闲");
    } else if (result == "TILE") {
      resultTmp = _lang.default.tc("和");
    }
  } else if (tableNum && tableNum.includes('DOU-NIU')) {
    resultTmp = renderPlay(result, tableNum);
  } else if (tableNum && tableNum.includes('WIN3CARDS')) {
    if (result == "DRAGON") {
      resultTmp = _lang.default.tc("龙");
    } else if (result == "PHOENIX") {
      resultTmp = _lang.default.tc("凤");
    } else if (result == "TILE") {
      resultTmp = _lang.default.tc("和");
    }
  } else {
    resultTmp = result;
  }
  return resultTmp;
};
var goodRoadType = exports.goodRoadType = function goodRoadType(str) {
  var goodroadcount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  var tableNum = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
  str = str.toString();
  if (tableNum) {
    if (tableNum.includes('BACCARAT')) {
      if (str == "-2") {
        return "";
      } else if (str == "-1") {
        return _lang.default.tc("非好路");
      } else if (str == "0") {
        return _lang.default.tc("长庄") + goodroadcount;
      } else if (str == "1") {
        return _lang.default.tc("长闲") + goodroadcount;
      } else if (str == "2") {
        return _lang.default.tc("拍拍黐");
      } else if (str == "3") {
        return _lang.default.tc("大路单跳") + goodroadcount;
      } else if (str == "4") {
        return _lang.default.tc("一厅两房(庄)");
      } else if (str == "5") {
        return _lang.default.tc("一厅两房(闲)");
      } else if (str == "6") {
        return _lang.default.tc("逢庄黐");
      } else if (str == "7") {
        return _lang.default.tc("逢闲黐");
      } else if (str == "8") {
        return _lang.default.tc("隔黐庄");
      } else if (str == "9") {
        return _lang.default.tc("隔黐闲");
      }
    } else {
      return '-';
    }
  }
  if (typeof str == 'undefined') {
    return "";
  }
  if (str == "-2") {
    return "";
  } else if (str == "-1") {
    return _lang.default.tc("非好路");
  } else if (str == "0") {
    return _lang.default.tc("长庄") + goodroadcount;
  } else if (str == "1") {
    return _lang.default.tc("长闲") + goodroadcount;
  } else if (str == "2") {
    return _lang.default.tc("拍拍黐");
  } else if (str == "3") {
    return _lang.default.tc("大路单跳") + goodroadcount;
  } else if (str == "4") {
    return _lang.default.tc("一厅两房(庄)");
  } else if (str == "5") {
    return _lang.default.tc("一厅两房(闲)");
  } else if (str == "6") {
    return _lang.default.tc("逢庄黐");
  } else if (str == "7") {
    return _lang.default.tc("逢闲黐");
  } else if (str == "8") {
    return _lang.default.tc("隔黐庄");
  } else if (str == "9") {
    return _lang.default.tc("隔黐闲");
  }
  return "";
};
var getStatus = exports.getStatus = function getStatus(status) {
  if (status == "A") {
    return _lang.default.tc('已派彩');
  } else if (status == "C") {
    return _lang.default.tc('取消结果');
  } else if (status == "B") {
    return _lang.default.tc('未结算');
  } else if (status == "R") {
    return _lang.default.tc('重新结算');
  } else {
    return status || '';
  }
};
var getGameName = exports.getGameName = function getGameName(gameType) {
  if (gameType == "BACCARAT") {
    return _lang.default.tc('百家乐');
  } else if (gameType == "DRAGON-TIGER") {
    return _lang.default.tc('龙虎');
  } else if (gameType == "ROULETTE") {
    return _lang.default.tc('轮盘');
  } else if (gameType == "DOU-NIU") {
    return _lang.default.tc('斗牛');
  } else if (gameType == "SIC-BO") {
    return _lang.default.tc('骰宝');
  } else if (gameType == "WIN3CARDS") {
    return _lang.default.tc('炸金花');
  }
  return gameType;
};
var getZhTableName = exports.getZhTableName = function getZhTableName(str) {
  var tableNum = str;
  if (typeof str == 'undefined') {
    return '';
  }
  if (str.includes("百家乐")) {
    tableNum = tableNum.replace('百家乐', _lang.default.tc("百家乐"));
  } else if (str.includes("龙虎")) {
    tableNum = tableNum.replace('龙虎', _lang.default.tc("龙虎"));
  } else if (str.includes("轮盘")) {
    tableNum = tableNum.replace('轮盘', _lang.default.tc("轮盘"));
  } else if (str.includes("斗牛")) {
    tableNum = tableNum.replace('斗牛', _lang.default.tc("斗牛"));
  } else if (str.includes("骰宝")) {
    tableNum = tableNum.replace('骰宝', _lang.default.tc("骰宝"));
  } else if (str.includes("炸金花")) {
    tableNum = tableNum.replace('炸金花', _lang.default.tc("炸金花"));
  }
  return tableNum;
};
var getTableName = exports.getTableName = function getTableName(str) {
  var tableNum = str;
  if (typeof str == 'undefined') {
    return '';
  }
  if (str.includes("BACCARAT")) {
    tableNum = tableNum.replace('BACCARAT', _lang.default.tc("百家乐"));
  } else if (str.includes("DRAGON-TIGER")) {
    tableNum = tableNum.replace('DRAGON-TIGER', _lang.default.tc("龙虎"));
  } else if (str.includes("ROULETTE")) {
    tableNum = tableNum.replace('ROULETTE', _lang.default.tc("轮盘"));
  } else if (str.includes("DOU-NIU")) {
    tableNum = tableNum.replace('DOU-NIU', _lang.default.tc("斗牛"));
  } else if (str.includes("SIC-BO")) {
    tableNum = tableNum.replace('SIC-BO', _lang.default.tc("骰宝"));
  } else if (str.includes("WIN3CARDS")) {
    tableNum = tableNum.replace('WIN3CARDS', _lang.default.tc("炸金花"));
  }
  return tableNum;
};
var formatPercent = exports.formatPercent = function formatPercent(num) {
  num = num.toString().replace(/\$|\,/g, '');
  if (isNaN(num)) num = "0";
  var sign = num == (num = Math.abs(num));
  num = Math.floor(num * 100 + 0.50000000001);
  var cents = num % 100;
  num = Math.floor(num / 100).toString();
  if (cents < 10) cents = "0" + cents;
  for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
  return (sign ? '' : '-') + num + '.' + cents;
};
var getTimePicker = exports.getTimePicker = function getTimePicker() {
  return {
    shortcuts: [{
      text: _lang.default.tc('最近一周'),
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: _lang.default.tc('最近一个月'),
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: _lang.default.tc('最近三个月'),
      onClick: function onClick(picker) {
        var end = new Date();
        var start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }]
  };
};
var getcreate_time = exports.getcreate_time = function getcreate_time(times) {
  var time = dateAdjustByBeiJing(times);
  return adjustNum(time.getFullYear()) + "-" + adjustNum(time.getMonth() + 1) + "-" + adjustNum(time.getDate())
  // + "-" +'  '+
  // adjustNum(time.getHours())+':'+
  // adjustNum(time.getMinutes())+':'+
  // adjustNum(time.getSeconds())
  ;
};
function adjustNum(num) {
  return num >= 10 ? num : "0" + num;
}
function dateAdjustByBeiJing(match_time) {
  var isMiniSecond = (match_time + "").length >= 13;
  var date = new Date(isMiniSecond ? match_time : match_time * 1000);
  var localTime = isMiniSecond ? match_time : match_time * 1000;
  var localOffset = date.getTimezoneOffset() * 60000; //获得当地时间bai偏移的毫du秒数
  var utc = localTime + localOffset; //utc即GMT时间
  var offset = 8; //以北京时间为例zhi，东10区
  var gtm = utc + 3600000 * offset;
  return new Date(gtm);
}
var formatMoneyToLocal = exports.formatMoneyToLocal = function formatMoneyToLocal(money) {
  //暂时不做太严格的判断
  var num = Number(money);
  return num;
};
var formatCurrency = exports.formatCurrency = function formatCurrency(num) {
  if (!num || num == 0) return num;
  num = num.toString().replace(/\$|\,/g, '');
  if (isNaN(num)) num = "0";
  var sign = num == (num = Math.abs(num));
  num /= curNow;
  num = Math.floor(num * 100 + 0.50000000001);
  var cents = num % 100;
  num = Math.floor(num / 100).toString();
  if (cents < 10) cents = "0" + cents;
  for (var i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++) num = num.substring(0, num.length - (4 * i + 3)) + ',' + num.substring(num.length - (4 * i + 3));
  return (sign ? '' : '-') + num + '.' + cents;
};
var curNow = 1;
var gameUtil = exports.gameUtil = {
  changeCurrency: function changeCurrency(num) {
    //curNow = num;
  }
  /**
   * 游戏点数转化为元为单位的实际金额
   * @param money
   */

  /**
   * 格式化数字并保留2位小数
   * @param num
   * @returns {string}
   */
};

// 百家乐 龙虎 骰宝 ，牛牛，轮盘 ，投注点翻译
var getBetPoint = exports.getBetPoint = function getBetPoint(row) {
  var betPoint = row.betPoint,
    tableNum = row.tableNum;
  if (!betPoint) {
    return '';
  }
  var resultTmp = '';
  if (tableNum && tableNum.includes('DRAGON-TIGER')) {
    if (betPoint == "BANKER") {
      resultTmp = _lang.default.tc("龙");
    } else if (betPoint == "PLAYER") {
      resultTmp = _lang.default.tc("虎");
    } else {
      resultTmp = _lang.default.t("bettingType.".concat(betPoint));
    }
  } else if (tableNum && tableNum.includes('BACCARAT')) {
    if (betPoint == "BANKER") {
      resultTmp = _lang.default.tc("庄");
    } else if (betPoint == "PLAYER") {
      resultTmp = _lang.default.tc("闲");
    } else {
      resultTmp = _lang.default.t("bettingType.".concat(betPoint));
    }
  } else {
    resultTmp = _lang.default.t("bettingType.".concat(betPoint));
  }
  return resultTmp;
};