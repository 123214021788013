"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _rsaEncrypt = require("@/utils/rsaEncrypt");
var _auth = require("@/utils/auth");
var _login = require("@/api/login");
var _jsCookie = _interopRequireDefault(require("js-cookie"));
var _index = _interopRequireDefault(require("@/layout/index"));
var _vuex = require("vuex");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Login',
  mixins: [_index.default],
  data: function data() {
    return {
      lang: 'zh',
      codeUrl: '',
      loginForm: {
        username: '',
        password: '',
        verifyCode: ''
      },
      loading: false,
      options: [{
        value: 'zh',
        label: '中文简体'
      }, {
        value: 'en',
        label: 'English'
      }, {
        value: 'th',
        label: 'ภาษาไทย'
      }, {
        value: 'vi',
        label: 'Tiếng Việt'
      }, {
        value: 'ko',
        label: '한국어'
      }]
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapGetters)(['langType'])), {}, {
    showLang: {
      get: function get() {
        return this.langType;
      },
      set: function set() {}
    }
  }),
  created: function created() {
    //  获取验证码
    this.getCode();
    // 获取用户名密码等Cookie
    this.getCookie();
  },
  mounted: function mounted() {
    window.addEventListener('keyup', this.handleEnterKey);
  },
  beforeDestroy: function beforeDestroy() {
    window.removeEventListener('keyup', this.handleEnterKey);
  },
  methods: {
    getCookie: function getCookie() {
      var username = _jsCookie.default.get('_DxdK_xvVQ'); // 用户名
      var password = _jsCookie.default.get('Fvbp_ZtQ'); // 密码

      this.loginForm = {
        username: username === undefined ? '' : (0, _rsaEncrypt.getcrypt)(username),
        password: password === undefined ? '' : (0, _rsaEncrypt.getcrypt)(password),
        code: ''
      };
    },
    handleEnterKey: function handleEnterKey(e) {
      if (e.code && e.code == 'Enter') {
        this.handleLogin();
      }
    },
    getCode: function getCode() {
      var _this = this;
      (0, _login.getCodeImg)().then(function (res) {
        var url = window.URL.createObjectURL(new Blob([res], {
          type: 'image/jpeg'
        }));
        _this.codeUrl = url;
      });
    },
    handleLogin: function handleLogin() {
      var _this2 = this;
      if (!this.loginForm.verifyCode) {
        this.$message.error(this.$t('login.code_name'));
        return false;
      }
      if (!this.loginForm.username) {
        this.$message.error(this.$t('login.tips_name'));
        return false;
      }
      if (!this.loginForm.password) {
        this.$message.error(this.$t('login.tips_pwd'));
        return false;
      }
      var user = {
        username: this.loginForm.username,
        password: (0, _rsaEncrypt.encryptWithRsa)(this.loginForm.password),
        verifyCode: this.loginForm.verifyCode
      };
      this.loading = true;
      (0, _login.login)(user).then(function (res) {
        if (res.code == 200) {
          (0, _auth.setToken)(res.data.token, true);
          _jsCookie.default.remove('username');
          _jsCookie.default.remove('password');
          _this2.$store.dispatch('setUserInfo', res.data.userInfo);
          _this2.loading = false;
          _this2.$router.push({
            path: '/'
          });
        } else {
          _this2.loading = false;
          _this2.getCode();
        }
      }).catch(function (err) {
        _this2.loading = false;
        _this2.getCode();
      });
    },
    // 语言选择
    langChange: function langChange(type) {
      this.$i18n.locale = type;
      localStorage.setItem('lang', type);
      this.$store.dispatch('setLang', type);
    }
  }
};