"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _exchangeRate = require("@/api/report/exchangeRate");
var _vuex = require("vuex");
var _lodash = _interopRequireDefault(require("lodash"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _this;
var _default = exports.default = {
  name: 'exchangeRate',
  props: {},
  data: function data() {
    return {
      editType: '',
      dialog: false,
      uList: [],
      cList: [],
      formModel: {},
      titleName: '',
      isShow: false,
      lang: localStorage.getItem('lang')
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['userInfo'])),
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    validateInfo: function validateInfo(rule, value, callback) {
      if (value === '') {
        callback(new Error(this.$t('请输入')));
      } else if (parseFloat(value) <= 0) {
        callback(new Error(this.$t('请输入正确的值')));
      } else {
        callback();
      }
    },
    renderKey: function renderKey(key) {
      if (this.lang == 'zh') return key;
      var name = key;
      try {
        name = name.replace(/[\u4e00-\u9fa5]/g, '').replace('(K)', '');
        return name;
      } catch (e) {
        console.log(e);
        return name;
      }
    },
    onSave: function onSave() {
      var _this2 = this;
      this.$refs['addForm'].validate(function (valid) {
        var params = {
          exchangeRate: {} // USD CNY
        };
        var arr = [];
        if (_this2.editType == 'USD') {
          arr = _this2.uList.map(function (item) {
            item.rate = _this2.formModel[item.name];
            return item;
          });
          params.exchangeRate['USD'] = arr;
        } else if (_this2.editType == 'CNY') {
          arr = _this2.cList.map(function (item) {
            item.rate = _this2.formModel[item.name];
            return item;
          });
          params.exchangeRate['CNY'] = arr;
        } else {
          throw new Error('错误的保存类型！');
        }
        (0, _exchangeRate.update)(params).then(function (res) {
          if (res.code == 200) {
            _this2.getList();
            _this2.dialog = false;
          } else {
            _this2.$message.error(_this2.$t(res.message));
          }
        }).catch(function (err) {});
      });
    },
    onEditC: function onEditC() {
      this.editType = 'CNY';
      this.titleName = this.$t('人民币汇率');
      var obj = {};
      _lodash.default.cloneDeep(this.cList).forEach(function (item) {
        obj[item.name] = item.rate;
      });
      this.formModel = obj;
      this.dialog = true;
    },
    onEditU: function onEditU() {
      this.editType = 'USD';
      this.titleName = this.$t('美元汇率');
      var obj = {};
      _lodash.default.cloneDeep(this.uList).forEach(function (item) {
        obj[item.name] = item.rate;
      });
      this.formModel = obj;
      this.dialog = true;
    },
    getList: function getList() {
      var _this3 = this;
      (0, _exchangeRate.getList)().then(function (res) {
        if (res.code == 200) {
          _this3.uList = res.data.USD;
          _this3.cList = res.data.CNY;
        } else {
          _this3.$message.error(_this3.$t(res.message));
          _this3.uList = [];
          _this3.cList = [];
        }
      }).catch(function (err) {
        _this3.uList = [];
        _this3.cList = [];
      });
    },
    cancelForm: function cancelForm() {
      this.dialog = false;
    }
  }
};