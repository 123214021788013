var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "contaner", attrs: { id: "contaner" } }, [
      _c("div", { attrs: { id: "betid" } }),
      _c("div", { attrs: { id: "gameName" } }),
      _c(
        "div",
        { staticStyle: { display: "none" }, attrs: { id: "firstCard" } },
        [_c("label", [_vm._v(_vm._s(_vm.$t("头牌")) + ":")])]
      ),
      _c("div", [_c("label", [_vm._v(_vm._s(_vm.$t("牌局结果")) + ":")])]),
      _c("div", { attrs: { id: "cardlist" } }),
      _c("div", { attrs: { id: "gameresult" } }),
      _c("div", { staticStyle: { width: "100%", overflow: "auto" } }, [
        _c("table", { staticClass: "pure-table", attrs: { id: "betpoint" } }, [
          _c("thead", [
            _c("tr", [
              _c("th", [_vm._v("#")]),
              _c("th", [_vm._v(_vm._s(_vm.$t("投注点")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("投注额")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("输赢额")))]),
              _c("th", [_vm._v(_vm._s(_vm.$t("洗码量")))]),
            ]),
          ]),
          _c("tbody"),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }