"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _userMng = require("@/api/account/userMng");
var _elementUi = require("element-ui");
var _gameProperties = require("@/utils/gameProperties");
var _lodash = _interopRequireDefault(require("lodash"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _this;
var _default = exports.default = {
  name: 'channelMng',
  props: {},
  data: function data() {
    return {
      channelList: [],
      curLimitList: [],
      accountForm: {
        uName: '',
        betLimit: [],
        limit: []
      },
      dialog: false,
      loading: false,
      tableData: {
        list: [],
        total: 0,
        totalPage: 1
      },
      totalList: [],
      queryForm: {
        page: 1,
        limit: 10,
        beginTime: '',
        endTime: '',
        userName: '',
        status: '',
        parentId: '',
        agentName: ''
      },
      rules: {
        limit: [{
          type: 'array',
          required: true,
          message: _this.$t('请至少选择一个'),
          trigger: 'change'
        }]
      }
    };
  },
  computed: {},
  watch: {},
  beforeCreate: function beforeCreate() {
    _this = this;
  },
  mounted: function mounted() {
    this.queryForm.page = 1;
    this.getList();
    this.getChannelList();
  },
  methods: {
    getcreate_time: _gameProperties.getcreate_time,
    renderCurrency: _gameProperties.renderCurrency,
    onSearch: function onSearch() {
      this.queryForm.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      var params = _objectSpread({}, this.queryForm);
      if (!params.beginTime) {
        params.beginTime = '';
      }
      if (!params.endTime) {
        params.endTime = '';
      }
      if (!params.parentId) {
        params.parentId = '';
      }
      (0, _userMng.userManageList)(params).then(function (res) {
        _this2.loading = false;
        if (res.code == 200) {
          _this2.tableData = {
            list: res.data,
            total: res.count,
            totalPage: 1
          };
          _this2.totalList = res.message && JSON.parse(res.message).all;
        } else {
          _this2.$message.error(_this2.$t(res.message));
          _this2.tableData = {
            list: [],
            total: 0,
            totalPage: 1
          };
          _this2.totalList = [];
        }
      }).catch(function (err) {
        _this2.loading = false;
        _this2.tableData = {
          list: [],
          total: 0,
          totalPage: 1
        };
        _this2.totalList = [];
      });
    },
    handleColloge: function handleColloge(row) {
      var _this3 = this;
      this.$confirm(row.pointSwitch == 1 ? this.$t('确认是否需要启用此用户') : this.$t('确认是否需要停用此用户') + ":" + row.uName, row.pointSwitch == 1 ? this.$t('启用') : this.$t('停用'), {
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        type: 'warning'
      }).then(function () {
        (0, _userMng.changeStatus)({
          id: row.uName,
          state: row.pointSwitch == 1 ? 0 : 2
        }).then(function (res) {
          if (res) {
            _this3.$message.success(_this3.$t('操作成功'));
            _this3.getList();
          } else {
            _this3.$message.error(_this3.$t(res.message));
          }
        });
      }).catch(function (err) {});
    },
    // 页码size 改变
    handleSizeChange: function handleSizeChange(size) {
      setTimeout(function () {
        window.scrollTo({
          left: 0,
          top: 0
        });
      }, 10);
      this.queryForm.limit = size;
      this.queryForm.page = 1;
      this.getList();
    },
    // 分页数据改变
    handleCurrentChange: function handleCurrentChange(val) {
      window.scrollTo({
        left: 0,
        top: 0
      });
      this.queryForm.page = val;
      this.getList();
    },
    handleBetLimit: function handleBetLimit(row) {
      this.getCurLimit(row);
      var model = _lodash.default.cloneDeep(row);
      var betLimitArr = model.betLimit.split(',');
      var upLimitArr = model.upLimit.split(',');
      var downLimitArr = model.downLimit.split(',');
      var limit = [];
      for (var i = 0; i < betLimitArr.length; i++) {
        limit.push({
          betLimit: betLimitArr[i],
          upLimit: upLimitArr[i],
          downLimit: downLimitArr[i]
        });
      }
      this.accountForm = {
        uName: model.uName,
        betLimit: limit,
        limit: betLimitArr
      };
      this.dialog = true;
    },
    getCurLimit: function getCurLimit(row) {
      var _this4 = this;
      var params = {
        currency: row.currencyType
      };
      (0, _userMng.getCurrencyLimit)(params).then(function (res) {
        if (res.code == 200) {
          _this4.curLimitList = res.data;
        } else {
          _this4.$message.error(_this4.$t(res.message));
          _this4.curLimitList = [];
        }
      }).catch(function (err) {}).finally(function () {
        _this4.loading = false;
      });
    },
    getChannelList: function getChannelList() {
      var _this5 = this;
      (0, _userMng.getChannel)().then(function (res) {
        _this5.loading = false;
        if (res.code == 200) {
          _this5.channelList = res.data;
        } else {
          _this5.$message.error(_this5.$t(res.message));
          _this5.channelList = [];
        }
      }).catch(function (err) {
        _this5.loading = false;
        _this5.channelList = [];
      });
    },
    ipAnalysis: function ipAnalysis(ip) {
      if (!ip) {
        return '';
      }
      ;
      var parts = ip.split(':');
      if (parts.length == 1) {
        return ip;
      }
      var ipObject = {
        '0': this.$t('白鹭PC'),
        '1': this.$t('白鹭H5'),
        '2': this.$t('cocos-pc'),
        '3': this.$t('cocos-h5')
      };
      var clientType = ipObject[parts[0]] ? "".concat(ipObject[parts[0]], ":") : '';
      return "".concat(clientType).concat(parts[1]);
    },
    onSave: function onSave() {
      var _this6 = this;
      this.$refs['limitForm'].validate(function (valid) {
        if (valid) {
          var loading = _elementUi.Loading.service({
            fullscreen: true
          });
          (0, _userMng.changeCurrencyLimit)(_this6.accountForm).then(function (res) {
            if (res.code == 200) {
              _this6.dialog = false;
              _this6.onSearch();
            } else {
              _this6.$message.error(_this6.$t(res.message));
            }
          }).catch(function (err) {}).finally(function () {
            _this6.$nextTick(function () {
              loading.close();
            });
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 取消
    cancelForm: function cancelForm() {
      this.dialog = false;
      this.accountForm = {
        uName: '',
        betLimit: [],
        limit: []
      };
    } //登录ip解析
  }
};