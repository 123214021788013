"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _transferInquiry = require("@/api/report/transferInquiry");
var _elementUi = require("element-ui");
var _downFiles = require("@/utils/downFiles");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _this;
var _default = exports.default = {
  name: 'transferInquiry',
  props: {},
  data: function data() {
    return {
      accountForm: {
        uName: '',
        betLimit: [],
        limit: []
      },
      dialog: false,
      loading: false,
      tableData: {
        list: [],
        total: 0,
        totalPage: 1
      },
      queryForm: {
        page: 1,
        limit: 10,
        beginTime: '',
        endTime: '',
        userName: '',
        changeType: '',
        id: '',
        opId: ''
      },
      tranferOp: this.createTranferOp()
    };
  },
  beforeCreate: function beforeCreate() {
    _this = this;
  },
  mounted: function mounted() {
    this.queryForm.page = 1;
    this.getList();
  },
  methods: {
    createTranferOp: function createTranferOp() {
      return [{
        label: this.$t('存款'),
        value: 'deposite'
      }, {
        label: this.$t('取款'),
        value: 'Draw'
      }, {
        label: this.$t('下注'),
        value: 'dobet'
      }, {
        label: this.$t('结算'),
        value: 'settle'
      }, {
        label: this.$t('取消结果'),
        value: 'cancel'
      }, {
        label: this.$t('修改结果'),
        value: 'winlost'
      }, {
        label: this.$t('棋牌转入'),
        value: 'TRANS_IN'
      }, {
        label: this.$t('转出棋牌'),
        value: 'TRANS_OUT'
      }, {
        label: this.$t('创建账户'),
        value: 'create'
      }, {
        label: this.$t('账户余额同步'),
        value: 'syncAccountMoney'
      }];
    },
    onSearch: function onSearch() {
      if (!this.queryForm.userName) {
        this.$message.error(this.$t('用户名不能为空'));
        return;
      }
      this.queryForm.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      if (!this.queryForm.userName) {
        return;
      }
      this.loading = true;
      var params = _objectSpread({}, this.queryForm);
      if (!params.beginTime) {
        params.beginTime = '';
      }
      if (!params.endTime) {
        params.endTime = '';
      }
      if (!params.changeType) {
        params.changeType = '';
      }
      (0, _transferInquiry.getList)(params).then(function (res) {
        _this2.loading = false;
        if (res.code == 200) {
          _this2.tableData = {
            list: res.data,
            total: res.count,
            totalPage: 1
          };
        } else {
          _this2.$message.error(_this2.$t(res.message));
          _this2.tableData = {
            list: [],
            total: 0,
            totalPage: 1
          };
        }
      }).catch(function (err) {
        _this2.loading = false;
        _this2.tableData = {
          list: [],
          total: 0,
          totalPage: 1
        };
        ;
      });
    },
    // 页码size 改变
    handleSizeChange: function handleSizeChange(size) {
      setTimeout(function () {
        window.scrollTo({
          left: 0,
          top: 0
        });
      }, 10);
      this.queryForm.limit = size;
      this.queryForm.page = 1;
      this.getList();
    },
    // 分页数据改变
    handleCurrentChange: function handleCurrentChange(val) {
      window.scrollTo({
        left: 0,
        top: 0
      });
      this.queryForm.page = val;
      this.getList();
    },
    onOutput: function onOutput() {
      var _this3 = this;
      var loading = _elementUi.Loading.service({
        fullscreen: true
      });
      var params = _objectSpread({}, this.queryForm);
      (0, _transferInquiry.output)(params).then(function (res) {
        if (res.code == 200) {
          var columns = _this3.createColums();
          var data = _this3.editData(res.data);
          (0, _downFiles.downFiles)(data, columns, 'transferInquiry');
        } else {
          _this3.$message.error(_this3.$t(res.message));
        }
      }).catch(function (err) {
        console.log(err);
      }).finally(function () {
        _this3.$nextTick(function () {
          loading.close();
        });
      });
    },
    createColums: function createColums() {
      return [{
        "label": this.$t('ID'),
        "key": "id",
        "control_type": "TEXT"
      }, {
        "label": this.$t('账户名'),
        "key": "userName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('创建时间'),
        "key": "changeTime",
        "control_type": "TEXT"
      }, {
        "label": this.$t('转账类型'),
        "key": "changeType",
        "control_type": "TEXT"
      }, {
        "label": this.$t('局号/操作员ID'),
        "key": "opid",
        "control_type": "TEXT"
      }, {
        "label": this.$t('转入前余额'),
        "key": "beforeBalance",
        "control_type": "TEXT"
      }, {
        "label": this.$t('交易金额'),
        "key": "dealBalance",
        "control_type": "TEXT"
      }, {
        "label": this.$t('转入后余额'),
        "key": "afterBalance",
        "control_type": "TEXT"
      }];
    },
    editData: function editData(data) {
      var _this4 = this;
      if (!data) return [];
      var arr = [];
      data.forEach(function (item) {
        var model = _objectSpread({}, item);
        if (model.changeType) {
          model.changeType = _this4.$t(item.changeType);
        }
        ;
        arr.push(model);
      });
      return arr;
    }
  }
};