"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBalance = getBalance;
exports.getCodeImg = getCodeImg;
exports.login = login;
exports.logout = logout;
exports.updatePassWord = updatePassWord;
var _request = _interopRequireDefault(require("@/utils/request"));
// 登录
function login(data) {
  return (0, _request.default)({
    url: '/yx/login',
    method: 'post',
    // headers: { 'content-type': 'application/x-www-form-urlencoded' },
    data: data
  });
}

// 获取图片验证码
function getCodeImg() {
  return (0, _request.default)({
    url: '/yx/getVerifyCode',
    method: 'get',
    responseType: 'arraybuffer'
  });
}

//登出
function logout() {
  return (0, _request.default)({
    url: '/yx/logout',
    method: 'get',
    showLoading: true
  });
}

//修改登录密码
function updatePassWord(params) {
  return (0, _request.default)({
    url: "/yx/changePwd",
    method: 'get',
    params: params,
    showLoading: true
  });
}

// 获取余额
function getBalance() {
  return (0, _request.default)({
    url: '/yx/getBalance',
    method: 'get'
  });
}