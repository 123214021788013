"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.to-string");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _winReport = require("@/api/report/winReport");
var _common = require("@/api/common");
var _gameProperties = require("@/utils/gameProperties");
var _downFiles = require("@/utils/downFiles");
var _vuex = require("vuex");
var _elementUi = require("element-ui");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _this;

// 输赢报表
var _default = exports.default = {
  name: 'winReport',
  props: {},
  data: function data() {
    return {
      platform: process.env.VUE_APP_PLATFORM,
      channelList: [],
      currencyList: [],
      accountForm: {
        uName: '',
        betLimit: [],
        limit: []
      },
      loading: false,
      tableData: {
        list: [],
        total: 0,
        totalPage: 1
      },
      totalList: [],
      queryForm: {
        page: 1,
        limit: 10,
        game: '',
        channel: '',
        userName: '',
        proxy: '',
        beginTime: '',
        endTime: '',
        currency: 0,
        machineFlag: false,
        total: '',
        bankerPercent: '',
        evPercent: '',
        evAmountPercent: '',
        ipRiskScoreLimit: '',
        totalRiskScoreLimit: '',
        activity: 1,
        // 未使用的活动参数
        groupChannel: 0,
        groupCurrency: 0,
        groupProxy: 0,
        groupUserName: 0,
        groupDate: 0,
        groupGame: 0
      },
      rules: {
        limit: [{
          type: 'array',
          required: true,
          message: _this.$t('请选择'),
          trigger: 'change'
        }]
      }
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['isAdmin'])),
  beforeCreate: function beforeCreate() {
    _this = this;
  },
  mounted: function mounted() {
    this.queryForm.page = 1;
    this.queryForm.beginTime = this.formatTime(new Date(), 'Y-M-D');
    this.queryForm.endTime = this.formatTime(new Date(new Date().getTime() + 24 * 60 * 60 * 1000), 'Y-M-D');
    this.getList();
    this.getChannelList();
    this.getCurrencyList();
  },
  methods: {
    formatPercent: _gameProperties.formatPercent,
    renderCurrency: _gameProperties.renderCurrency,
    onSearch: function onSearch() {
      this.queryForm.page = 1;
      this.getList();
    },
    onOutput: function onOutput() {
      var _this2 = this;
      var loading = _elementUi.Loading.service({
        fullscreen: true
      });
      var params = this.parseParam(this.getParams());
      (0, _winReport.output)(params).then(function (res) {
        if (res.code == 200) {
          var columns = _this2.createColums();
          var data = _this2.editData(res.data);
          (0, _downFiles.downFiles)(data, columns, 'winReport');
        } else {
          _this2.$message.error(_this2.$t(res.message));
        }
      }).catch(function (err) {}).finally(function () {
        _this2.$nextTick(function () {
          loading.close();
        });
      });
    },
    formatNumber: function formatNumber(n) {
      n = n.toString();
      return n[1] ? n : '0' + n;
    },
    formatTime: function formatTime(time, format) {
      if (!time) {
        return;
      }
      var newArr = [];
      var formatArr = ['Y', 'M', 'D', 'h', 'm', 's'];
      newArr.push(time.getFullYear() ? time.getFullYear() : '');
      newArr.push(this.formatNumber(time.getMonth() + 1));
      newArr.push(this.formatNumber(time.getDate()));
      newArr.push(this.formatNumber(time.getHours()));
      newArr.push(this.formatNumber(time.getMinutes()));
      newArr.push(this.formatNumber(time.getSeconds()));
      for (var i in newArr) {
        format = format.replace(formatArr[i], newArr[i]);
      }
      return format;
    },
    editData: function editData(data) {
      var _this3 = this;
      if (!data) return [];
      var arr = [];
      data.forEach(function (item) {
        var model = _objectSpread({}, item);
        if (model.currency) model.currency = (0, _gameProperties.renderCurrency)(model.currency);
        model.bankerPercent = (0, _gameProperties.formatPercent)(model.bankerRounds / model.rounds * 100) + '%';
        model.evPercent = (0, _gameProperties.formatPercent)(model.evRounds / model.rounds * 100) + '%';
        model.realProfitability = (0, _gameProperties.formatPercent)(model.money / model.validBetAmount * 100) + '%';
        if (model.goodPercent) model.goodPercent = (0, _gameProperties.formatPercent)(model.goodRounds / model.rounds * 100) + '%';
        model.gametype = _this3.setGameName(model.gametype);
        arr.push(model);
      });
      return arr;
    },
    createColums: function createColums() {
      var admin = [{
        "label": this.$t('账户名'),
        "key": "userName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('渠道'),
        "key": "channel",
        "control_type": "TEXT"
      }, {
        "label": this.$t('代理'),
        "key": "agent",
        "control_type": "TEXT"
      }, {
        "label": this.$t('币种'),
        "key": "currency",
        "control_type": "TEXT"
      }, {
        "label": this.$t('日期'),
        "key": "groupFor",
        "control_type": "TEXT"
      }, {
        "label": this.$t('局数'),
        "key": "rounds",
        "control_type": "TEXT"
      }, {
        "label": this.$t('ev局数'),
        "key": "evRounds",
        "control_type": "TEXT"
      }, {
        "label": this.$t('庄局数'),
        "key": "bankerRounds",
        "control_type": "TEXT"
      }, {
        "label": this.$t('闲局数'),
        "key": "playerRounds",
        "control_type": "TEXT"
      }, {
        "label": this.$t('好路局数'),
        "key": "goodRounds",
        "control_type": "TEXT"
      }, {
        "label": this.$t('投注额'),
        "key": "servicemoney",
        "control_type": "TEXT"
      }, {
        "label": this.$t('洗码量'),
        "key": "validBetAmount",
        "control_type": "TEXT"
      }, {
        "label": this.$t('庄比例'),
        "key": "bankerPercent",
        "control_type": "TEXT"
      }, {
        "label": this.$t('ev百分比'),
        "key": "evPercent",
        "control_type": "TEXT"
      }, {
        "label": this.$t('ev投注百分比'),
        "key": "evAmountPercent",
        "control_type": "TEXT"
      }, {
        "label": this.$t('ev加权值'),
        "key": "evWinPercent",
        "control_type": "TEXT"
      }, {
        "label": this.$t('游戏输赢'),
        "key": "money",
        "control_type": "TEXT"
      }, {
        "label": this.$t('实际盈利率'),
        "key": "profitrate",
        "control_type": "TEXT"
      }, {
        "label": this.$t('好路比例'),
        "key": "goodPercent",
        "control_type": "TEXT"
      }, {
        "label": this.$t('IP风险分数'),
        "key": "ipRiskScore",
        "control_type": "TEXT"
      }, {
        "label": this.$t('合计风险分数'),
        "key": "totalRiskScore",
        "control_type": "TEXT"
      }];
      var normal = [{
        "label": this.$t('账户名'),
        "key": "userName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('渠道'),
        "key": "channel",
        "control_type": "TEXT"
      }, {
        "label": this.$t('代理'),
        "key": "agent",
        "control_type": "TEXT"
      }, {
        "label": this.$t('币种'),
        "key": "currency",
        "control_type": "TEXT"
      }, {
        "label": this.$t('日期'),
        "key": "groupFor",
        "control_type": "TEXT"
      }, {
        "label": this.$t('局数'),
        "key": "rounds",
        "control_type": "TEXT"
      }, {
        "label": this.$t('ev局数'),
        "key": "evRounds",
        "control_type": "TEXT"
      }, {
        "label": this.$t('庄局数'),
        "key": "bankerRounds",
        "control_type": "TEXT"
      }, {
        "label": this.$t('闲局数'),
        "key": "playerRounds",
        "control_type": "TEXT"
      }, {
        "label": this.$t('投注额'),
        "key": "servicemoney",
        "control_type": "TEXT"
      }, {
        "label": this.$t('洗码量'),
        "key": "validBetAmount",
        "control_type": "TEXT"
      }, {
        "label": this.$t('庄比例'),
        "key": "bankerPercent",
        "control_type": "TEXT"
      }, {
        "label": this.$t('ev百分比'),
        "key": "evPercent",
        "control_type": "TEXT"
      }, {
        "label": this.$t('ev投注百分比'),
        "key": "evAmountPercent",
        "control_type": "TEXT"
      }, {
        "label": this.$t('ev加权值'),
        "key": "evWinPercent",
        "control_type": "TEXT"
      }, {
        "label": this.$t('游戏输赢'),
        "key": "money",
        "control_type": "TEXT"
      }, {
        "label": this.$t('实际盈利率'),
        "key": "profitrate",
        "control_type": "TEXT"
      }];

      // 代理PAI 展示此字段
      // if (this.platform == 'astar') {
      //     let gameElement = {
      //         "label": this.$t('游戏'),
      //         "key": "gametype",
      //         "control_type": "TEXT"
      //     }
      //     admin.splice(5, 0, gameElement);
      //     normal.splice(5, 0, gameElement);
      // }

      var gameElement = {
        "label": this.$t('游戏'),
        "key": "gametype",
        "control_type": "TEXT"
      };
      admin.splice(5, 0, gameElement);
      normal.splice(5, 0, gameElement);
      return this.isAdmin ? admin : normal;
    },
    parseParam: function parseParam(param) {
      var paramStr = '';
      Object.keys(param).forEach(function (key, index) {
        if (index === 0) {
          paramStr += key + "=" + encodeURIComponent(param[key]);
        } else {
          paramStr += "&" + key + "=" + encodeURIComponent(param[key]);
        }
      });
      return paramStr;
    },
    getParams: function getParams() {
      var obj = {};
      var keyArr = ['beginTime', 'endTime', 'channel', 'game'];
      for (var i in this.queryForm) {
        if (keyArr.includes(i) && (!this.queryForm[i] || this.queryForm[i] instanceof Array && this.queryForm[i].length == 0)) {
          obj[i] = '';
        } else {
          obj[i] = this.queryForm[i];
        }
      }
      obj['channel'] = obj['channel'].toString();
      if (!this.isAdmin) {
        delete this.queryForm.machineFlag;
        delete this.queryForm.ipRiskScoreLimit;
        delete this.queryForm.totalRiskScoreLimit;
      }
      return obj;
    },
    getList: function getList() {
      var _this4 = this;
      this.loading = true;
      var pa = this.getParams();
      var params = this.parseParam(pa);
      (0, _winReport.getList)(params).then(function (res) {
        _this4.loading = false;
        if (res.code == 200) {
          var form = new FormData();
          for (var i in pa) {
            form.append(i, pa[i]);
          }
          _this4.getSumList(form);
          _this4.tableData = {
            list: res.data,
            total: res.count,
            totalPage: 1
          };
        } else {
          _this4.$message.error(_this4.$t(res.message));
          _this4.tableData = {
            list: [],
            total: 0,
            totalPage: 1
          };
        }
      }).catch(function (err) {
        _this4.loading = false;
        _this4.tableData = {
          list: [],
          total: 0,
          totalPage: 1
        };
      });
    },
    getSumList: function getSumList(params) {
      var _this5 = this;
      (0, _winReport.getSumList)(params).then(function (res) {
        if (res.code == 200) {
          _this5.totalList = res.data;
        } else {
          _this5.$message.error(_this5.$t(res.message));
          _this5.totalList = [];
        }
      }).catch(function (err) {
        _this5.totalList = [];
      });
    },
    // 页码size 改变
    handleSizeChange: function handleSizeChange(size) {
      setTimeout(function () {
        window.scrollTo({
          left: 0,
          top: 0
        });
      }, 10);
      this.queryForm.limit = size;
      this.queryForm.page = 1;
      this.getList();
    },
    // 分页数据改变
    handleCurrentChange: function handleCurrentChange(val) {
      window.scrollTo({
        left: 0,
        top: 0
      });
      this.queryForm.page = val;
      this.getList();
    },
    handleOpenPage: function handleOpenPage(row) {
      this.$router.push({
        name: 'gameRecord',
        params: {
          userName: row.userName,
          channel: row.channel
        }
      });
    },
    getChannelList: function getChannelList() {
      var _this6 = this;
      (0, _common.getChannel)().then(function (res) {
        if (res.code == 200) {
          _this6.channelList = res.data;
        } else {
          _this6.$message.error(_this6.$t(res.message));
          _this6.channelList = [];
        }
      }).catch(function (err) {
        _this6.channelList = [];
      });
    },
    getCurrencyList: function getCurrencyList() {
      var _this7 = this;
      (0, _common.getCurrency)().then(function (res) {
        if (res.code == 200) {
          _this7.currencyList = res.data.map(function (val) {
            val.name = (0, _gameProperties.renderCurrency)(val.name);
            return val;
          });
        } else {
          _this7.$message.error(_this7.$t(res.message));
          _this7.currencyList = [];
        }
      }).catch(function (err) {
        _this7.currencyList = [];
      });
    },
    // 设置游戏类型
    setGameName: function setGameName(type) {
      var gameType = {
        '1': this.$t('百家乐'),
        '2': this.$t('龙虎'),
        '3': this.$t('轮盘'),
        '4': this.$t('骰宝'),
        '5': this.$t('牛牛'),
        '12': this.$t('炸金花')
      };
      return gameType[type] ? gameType[type] : '--';
    }
  }
};