"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tranferOp = exports.getGameType = void 0;
var tranferOp = exports.tranferOp = [{
  label: '存款',
  value: 'deposite'
}, {
  label: '棋牌转入',
  value: 'TRANS_IN'
}, {
  label: '取款',
  value: 'Draw'
}, {
  label: '下注',
  value: 'dobet'
}, {
  label: '结算',
  value: 'settle'
}, {
  label: '取消结果',
  value: 'cancel'
}, {
  label: '修改结果',
  value: 'winlost'
}, {
  label: '转出棋牌',
  value: 'TRANS_OUT'
}, {
  label: '创建账户',
  value: 'create'
}, {
  label: '账户余额同步',
  value: 'syncAccountMoney'
}];
var getGameType = exports.getGameType = function getGameType(changeType) {
  switch (changeType) {
    case "deposite":
      return "存款";
    case "Draw":
      return "取款";
    case "dobet":
      return "下注";
    case "settle":
      return "结算";
    case "cancel":
      return "取消结果";
    case "winlost":
      return "修改结果";
    case "TRANS_IN":
      return "棋牌转入";
    case "TRANS_OUT":
      return "转出棋牌";
    case "create":
      return "创建账户";
    case "syncAccountMoney":
      return "账户余额同步";
    default:
      return changeType;
  }
};