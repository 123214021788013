"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _blackListMng = require("@/api/account/blackListMng");
var _elementUi = require("element-ui");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _this;
var _default = exports.default = {
  name: 'channelMng',
  props: {},
  data: function data() {
    var reg = /^(1\d{2}|2[0-4]\d|25[0-5]|[1-6]\d|[1-9])\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)\.(1\d{2}|2[0-4]\d|25[0-5]|[1-9]\d|\d)$/;
    return {
      editType: 0,
      isBand: '',
      accountForm: {
        address: '',
        bantime: '',
        note: ''
      },
      dialog: false,
      loading: false,
      tableData: {
        list: [],
        total: 0,
        totalPage: 1
      },
      queryForm: {
        page: 1,
        limit: 10,
        address: ''
      },
      rules: {
        address: [{
          required: true,
          validator: function validator(rule, value, callback) {
            if (value === '') {
              callback(new Error(_this.$t('请输入')));
            } else if (!reg.test(value)) {
              callback(new Error(_this.$t('请输入正确的ip地址')));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }],
        bantime: [{
          required: true,
          message: _this.$t('请选择'),
          trigger: 'change'
        }]
      }
    };
  },
  computed: {},
  watch: {},
  beforeCreate: function beforeCreate() {
    _this = this;
  },
  mounted: function mounted() {
    this.queryForm.page = 1;
    this.getList();
  },
  methods: (0, _defineProperty2.default)((0, _defineProperty2.default)({
    onSearch: function onSearch() {
      this.queryForm.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      var params = _objectSpread({}, this.queryForm);
      (0, _blackListMng.getList)(params).then(function (res) {
        _this2.loading = false;
        if (res.code == 200) {
          _this2.tableData = {
            list: res.data,
            total: res.count,
            totalPage: 1
          };
        } else {
          _this2.$message.error(_this2.$t(res.message));
          _this2.tableData = {
            list: [],
            total: 0,
            totalPage: 1
          };
        }
      }).catch(function (err) {
        _this2.loading = false;
        _this2.tableData = {
          list: [],
          total: 0,
          totalPage: 1
        };
      });
    },
    // 页码size 改变
    handleSizeChange: function handleSizeChange(size) {
      setTimeout(function () {
        window.scrollTo({
          left: 0,
          top: 0
        });
      }, 10);
      this.queryForm.limit = size;
      this.queryForm.page = 1;
      this.getList();
    },
    onSave: function onSave() {
      var _this3 = this;
      this.$refs['addForm'].validate(function (valid) {
        if (valid) {
          delete _this3.accountForm.address;
          var loading = _elementUi.Loading.service({
            fullscreen: true
          });
          if (_this3.editType === 0) {
            (0, _blackListMng.addItem)(_this3.accountForm).then(function (res) {
              if (res.code == 200) {
                _this3.dialog = false;
                _this3.$message.success(_this3.$t('操作成功'));
                _this3.onSearch();
              } else {
                _this3.$message.error(_this3.$t(res.message));
              }
            }).catch(function (err) {}).finally(function () {
              _this3.$nextTick(function () {
                loading.close();
              });
            });
          } else if (_this3.editType === 1) {
            (0, _blackListMng.updateItem)(_this3.accountForm).then(function (res) {
              if (res.code == 200) {
                _this3.dialog = false;
                _this3.$message.success(_this3.$t('操作成功'));
                _this3.onSearch();
              } else {
                _this3.$message.error(_this3.$t(res.message));
              }
            }).catch(function (err) {}).finally(function () {
              _this3.$nextTick(function () {
                loading.close();
              });
            });
          } else {
            console.log('error function');
            return;
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 分页数据改变
    handleCurrentChange: function handleCurrentChange(val) {
      window.scrollTo({
        left: 0,
        top: 0
      });
      this.queryForm.page = val;
      this.getList();
    },
    // 新增
    onAdd: function onAdd() {
      this.isBand = '';
      this.dialog = true;
      this.editType = 0;
      this.accountForm = {
        address: '',
        bantime: '',
        note: ''
      };
    },
    // 编辑
    editHandle: function editHandle(item, type) {
      if (type === 'edit') {
        this.isBand = item.bantime;
      } else {
        this.isBand = '';
      }
      this.dialog = true;
      this.editType = 1;
      var address = item.address,
        bantime = item.bantime,
        note = item.note,
        id = item.id;
      this.accountForm = {
        id: id,
        address: address,
        bantime: bantime,
        note: note,
        action: type,
        state: 1
      };
    },
    unBandHandle: function unBandHandle(item) {
      var _this4 = this;
      this.$confirm(item.address + ' ' + this.$t('确定要解封该ip吗'), this.$t('提示'), {
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        type: 'warning'
      }).then(function () {
        var params = _objectSpread(_objectSpread({}, item), {}, {
          state: 1
        });
        params.state = 1;
        (0, _blackListMng.updateItem)(params).then(function (res) {
          if (res) {
            _this4.$message.success(_this4.$t('操作成功'));
            _this4.getList();
          } else {
            _this4.$message.error(_this4.$t(res.message));
          }
        });
      }).catch(function (err) {});
    }
  }, "onSave", function onSave() {
    var _this5 = this;
    this.$refs['addForm'].validate(function (valid) {
      if (valid) {
        var loading = _elementUi.Loading.service({
          fullscreen: true
        });
        if (_this5.editType === 0) {
          (0, _blackListMng.addItem)(_this5.accountForm).then(function (res) {
            if (res.code == 200) {
              _this5.dialog = false;
              _this5.$message.success(_this5.$t('操作成功'));
              _this5.onSearch();
            } else {
              _this5.$message.error(_this5.$t(res.message));
            }
          }).catch(function (err) {}).finally(function () {
            _this5.$nextTick(function () {
              loading.close();
            });
          });
        } else if (_this5.editType === 1) {
          delete _this5.accountForm.address;
          (0, _blackListMng.updateItem)(_this5.accountForm).then(function (res) {
            if (res.code == 200) {
              _this5.dialog = false;
              _this5.$message.success(_this5.$t('操作成功'));
              _this5.onSearch();
            } else {
              _this5.$message.error(_this5.$t(res.message));
            }
          }).catch(function (err) {}).finally(function () {
            _this5.$nextTick(function () {
              loading.close();
            });
          });
        } else {
          console.log('error function');
          return;
        }
      } else {
        console.log('error submit!!');
        return false;
      }
    });
  }), "cancelForm", function cancelForm() {
    this.dialog = false;
    this.accountForm = {
      address: '',
      bantime: '',
      note: ''
    };
  })
};