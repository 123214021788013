"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.updateConfig = updateConfig;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取列表数据
function getList() {
  return (0, _request.default)({
    url: '/yx//machineBetManagement/getConfig',
    method: 'GET'
  });
}

// 更新
function updateConfig(params) {
  return (0, _request.default)({
    url: '/yx/machineBetManagement/config/update?' + params,
    method: 'POST'
  });
}