var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.queryForm, inline: true },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "default-time": "12:00:00",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      format: "yyyy-MM-dd HH:mm:ss",
                      placeholder: _vm.$t("开始时间"),
                    },
                    model: {
                      value: _vm.queryForm.startTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "startTime", $$v)
                      },
                      expression: "queryForm.startTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "default-time": "11:59:59",
                      format: "yyyy-MM-dd HH:mm:ss",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: _vm.$t("结束时间"),
                    },
                    model: {
                      value: _vm.queryForm.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "endTime", $$v)
                      },
                      expression: "queryForm.endTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("游戏"), filterable: "" },
                      model: {
                        value: _vm.queryForm.gameType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "gameType", $$v)
                        },
                        expression: "queryForm.gameType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("百家乐"), value: 1 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("请输入账户名"),
                    },
                    model: {
                      value: _vm.queryForm.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "userName", $$v)
                      },
                      expression: "queryForm.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.onSearch },
                    },
                    [_vm._v(_vm._s(_vm.$t("分析")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "time-limit" }, [
        _vm._v(" " + _vm._s(_vm.$t("时间范围")) + ":"),
        _c("span", [_vm._v(_vm._s(_vm.queryForm.startTime))]),
        _vm._v(" " + _vm._s(_vm.$t("至"))),
        _c("span", [_vm._v(_vm._s(_vm.queryForm.endTime))]),
      ]),
      _c(
        "ul",
        { staticClass: "analysis-data" },
        _vm._l(_vm.analysisList, function (item, i) {
          return _c("li", { key: i }, [
            _c("span", [_vm._v(_vm._s(_vm.$t(item.label)))]),
            _vm._v(" "),
            _c("i", [_vm._v(_vm._s(_vm.analysisDataInfor[item.key]))]),
          ])
        }),
        0
      ),
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "time-limit",
          staticStyle: {
            "text-align": "center",
            margin: "10px",
            padding: "16px 0",
            "font-size": "16px",
            "border-bottom": "solid 1px #ededed",
          },
        },
        [
          _vm._v(" " + _vm._s(_vm.$t("庄/闲 平均每注金额")) + ":"),
          _c("span", [_vm._v(_vm._s(_vm.averageAmount))]),
        ]
      ),
      _c("div", { staticClass: "columnar_wrap" }, [
        _c("div", { staticClass: "cw_item" }, [
          _c("div", { staticClass: "cwi_title" }, [
            _vm._v(_vm._s(_vm.$t("庄/闲 输赢注单数")) + ":"),
          ]),
          _vm._m(1),
          _c("div", { staticClass: "cwi_info" }, [
            _c("div", { staticClass: "c_info_item" }, [
              _vm._v(_vm._s(_vm.$t("赢")) + "（"),
              _c("span", { staticClass: "winvaule1" }),
              _vm._v("）"),
            ]),
            _c("div", { staticClass: "c_info_item" }, [
              _vm._v(_vm._s(_vm.$t("合计")) + ": "),
              _c("span", { staticClass: "totalred totalred1" }),
              _vm._v(_vm._s(_vm.$t("单"))),
            ]),
            _c("div", { staticClass: "c_info_item" }, [
              _vm._v("（"),
              _c("span", { staticClass: "losevaule1" }),
              _vm._v("）" + _vm._s(_vm.$t("输"))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "cw_item" }, [
          _c("div", { staticClass: "cwi_title" }, [
            _vm._v(_vm._s(_vm.$t("庄/闲 平均输/赢投注金额")) + ":"),
          ]),
          _vm._m(2),
          _c("div", { staticClass: "cwi_info" }, [
            _c("div", { staticClass: "c_info_item" }, [
              _vm._v(_vm._s(_vm.$t("赢")) + "（"),
              _c("span", { staticClass: "winvaule2" }),
              _vm._v("）"),
            ]),
            _c("div", { staticClass: "c_info_item" }, [
              _vm._v("（"),
              _c("span", { staticClass: "losevaule2" }),
              _vm._v("）" + _vm._s(_vm.$t("输"))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "cw_item" }, [
          _c("div", { staticClass: "cwi_title" }, [
            _vm._v(_vm._s(_vm.$t("庄/闲 输赢金额")) + ":"),
          ]),
          _vm._m(3),
          _c("div", { staticClass: "cwi_info" }, [
            _c("div", { staticClass: "c_info_item" }, [
              _vm._v(_vm._s(_vm.$t("赢")) + "（"),
              _c("span", { staticClass: "winvaule3" }),
              _vm._v("）"),
            ]),
            _c("div", { staticClass: "c_info_item" }, [
              _vm._v(_vm._s(_vm.$t("合计")) + ": "),
              _c("span", { staticClass: "totalred totalred3" }),
            ]),
            _c("div", { staticClass: "c_info_item" }, [
              _vm._v("（"),
              _c("span", { staticClass: "losevaule3" }),
              _vm._v("）" + _vm._s(_vm.$t("输"))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "cw_item" }, [
          _c("div", { staticClass: "cwi_title" }, [
            _vm._v(_vm._s(_vm.$t("其他 输赢注单数")) + ":"),
          ]),
          _vm._m(4),
          _c("div", { staticClass: "cwi_info" }, [
            _c("div", { staticClass: "c_info_item" }, [
              _vm._v(_vm._s(_vm.$t("赢")) + "（"),
              _c("span", { staticClass: "winvaule4" }),
              _vm._v("）"),
            ]),
            _c("div", { staticClass: "c_info_item" }, [
              _vm._v(_vm._s(_vm.$t("合计")) + ": "),
              _c("span", { staticClass: "totalred totalred4" }),
              _vm._v(_vm._s(_vm.$t("单"))),
            ]),
            _c("div", { staticClass: "c_info_item" }, [
              _vm._v("（"),
              _c("span", { staticClass: "losevaule4" }),
              _vm._v("）" + _vm._s(_vm.$t("输"))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "cw_item" }, [
          _c("div", { staticClass: "cwi_title" }, [
            _vm._v(_vm._s(_vm.$t("其他 平均输/赢投注金额")) + ":"),
          ]),
          _vm._m(5),
          _c("div", { staticClass: "cwi_info" }, [
            _c("div", { staticClass: "c_info_item" }, [
              _vm._v(_vm._s(_vm.$t("赢")) + "（"),
              _c("span", { staticClass: "winvaule5" }),
              _vm._v("）"),
            ]),
            _c("div", { staticClass: "c_info_item" }, [
              _vm._v("（"),
              _c("span", { staticClass: "losevaule5" }),
              _vm._v("）" + _vm._s(_vm.$t("输"))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "cw_item" }, [
          _c("div", { staticClass: "cwi_title" }, [
            _vm._v(_vm._s(_vm.$t("其他 输赢金额")) + ":"),
          ]),
          _vm._m(6),
          _c("div", { staticClass: "cwi_info" }, [
            _c("div", { staticClass: "c_info_item" }, [
              _vm._v(_vm._s(_vm.$t("赢")) + "（"),
              _c("span", { staticClass: "winvaule6" }),
              _vm._v("）"),
            ]),
            _c("div", { staticClass: "c_info_item" }, [
              _vm._v(_vm._s(_vm.$t("合计")) + ": "),
              _c("span", { staticClass: "totalred totalred6" }),
            ]),
            _c("div", { staticClass: "c_info_item" }, [
              _vm._v("（"),
              _c("span", { staticClass: "losevaule6" }),
              _vm._v("）" + _vm._s(_vm.$t("输"))),
            ]),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "search",
          staticStyle: {
            "margin-top": "20px",
            "padding-top": "30px",
            "border-top": "solid 1px #ededed",
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.queryGmaerForm, inline: true },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "span",
                {
                  staticStyle: {
                    "line-height": "32px",
                    "font-size": "14px",
                    "margin-right": "10px",
                    "font-weight": "bold",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("同局投注玩家查询")))]
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("同局投注局数比例") } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("请选择"), filterable: "" },
                      model: {
                        value: _vm.queryGmaerForm.betPercent,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryGmaerForm, "betPercent", $$v)
                        },
                        expression: "queryGmaerForm.betPercent",
                      },
                    },
                    _vm._l(_vm.sameGamerOp, function (item, i) {
                      return _c("el-option", {
                        key: i,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("同局投注总金额比例") } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { "margin-left": "14px" },
                      attrs: { placeholder: _vm.$t("请选择"), filterable: "" },
                      model: {
                        value: _vm.queryGmaerForm.amountPercent,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryGmaerForm, "amountPercent", $$v)
                        },
                        expression: "queryGmaerForm.amountPercent",
                      },
                    },
                    _vm._l(_vm.sameGamerOp, function (item, i) {
                      return _c("el-option", {
                        key: i,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.onGamerSearch },
                    },
                    [_vm._v(_vm._s(_vm.$t("检索")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.onOutput },
                    },
                    [_vm._v(_vm._s(_vm.$t("导出")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-view",
                        size: "mini",
                      },
                      on: { click: _vm.onWatchOrder },
                    },
                    [_vm._v(_vm._s(_vm.$t("查看注单")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.isCol
        ? _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              attrs: {
                border: "",
                "cell-style": { borderColor: "#e6e6e6" },
                "header-cell-style": {
                  background: "#f97802",
                  height: "45px",
                  color: "rgba(255, 255, 255, 1)",
                  borderColor: "#e6e6e6",
                },
                "highlight-current-row": "",
                data: _vm.tableData.list,
              },
              on: { "selection-change": _vm.handleSelectionChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "selection", width: "55" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "agentName",
                  label: _vm.$t("代理"),
                  "min-width": "100",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "userName",
                  label: _vm.$t("玩家账号"),
                  "min-width": "140",
                  sortable: "",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                cursor: "pointer",
                                color: "#409EFF",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onShowDetail(scope.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(scope.row.userName))]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1291062124
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "playerGameNumSum",
                  label: _vm.$t("玩家总投注局数"),
                  "min-width": "180",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "playerAmountSum",
                  label: _vm.$t("玩家总投注金额"),
                  "min-width": "180",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sameGameSum",
                  label: _vm.$t("同局投注局数"),
                  "min-width": "200",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sameGamePercent",
                  label: _vm.$t("同局比例"),
                  "min-width": "160",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sameAmountSum",
                  label: _vm.$t("同局投注总金额"),
                  "min-width": "200",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "sameAmountPercent",
                  label: _vm.$t("同局投注总金额比例"),
                  "min-width": "200",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "", "min-width": "60" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("i", {
                            staticClass: "el-icon-arrow-right",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.onGetAnalyData(scope.row)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  699067577
                ),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "hedgingGameSum",
                  label: _vm.$t("对冲局数"),
                  "min-width": "160",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "unHedgingGameSum",
                  label: _vm.$t("非对冲局数"),
                  "min-width": "160",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "hedgingAmountSum",
                  label: _vm.$t("对冲金额"),
                  "min-width": "160",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "unHedgingAmountSum",
                  label: _vm.$t("非对冲金额"),
                  "min-width": "200",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "currency",
                  label: _vm.$t("货币"),
                  "min-width": "140",
                  sortable: "",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "pagination-content" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryGmaerForm.page,
              "page-sizes": [10, 20, 30, 40, 50],
              "page-size": _vm.queryGmaerForm.limit,
              total: parseInt(_vm.tableData.total),
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.detailDialog,
            title: "",
            width: "700px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.detailDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                model: _vm.inforForm,
                size: "small",
                "label-width": "350px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("真人点数"), prop: "amount" } },
                [_vm._v(" " + _vm._s(_vm.inforForm.amount) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("币种"), prop: "amount" } },
                [_vm._v(" " + _vm._s(_vm.inforForm.currency) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("个人限额"), prop: "limitList" } },
                [_vm._v(" " + _vm._s(_vm.inforForm.limitList) + " ")]
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("累计有效投注额"), prop: "betAmount" },
                },
                [_vm._v(" " + _vm._s(_vm.inforForm.betAmount) + " ")]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("真人累计有效投注额"),
                    prop: "videoBetAmount",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.inforForm.videoBetAmount) + " ")]
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("累计输赢额"), prop: "resultAmount" },
                },
                [_vm._v(" " + _vm._s(_vm.inforForm.resultAmount) + " ")]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("真人累计输赢额"),
                    prop: "videoResultAmount",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.inforForm.videoResultAmount) + " ")]
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("创建时间"), prop: "registerDate" } },
                [_vm._v(" " + _vm._s(_vm.inforForm.registerDate) + " ")]
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("最后登录时间"),
                    prop: "lastLoginDate",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.inforForm.lastLoginDate) + " ")]
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: _vm.$t("最后投注时间"), prop: "lastBetDate" },
                },
                [_vm._v(" " + _vm._s(_vm.inforForm.lastBetDate) + " ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.dialog,
            "destroy-on-close": true,
            "before-close": _vm.handleClose,
            title: "",
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "table",
              attrs: {
                border: "",
                "cell-style": { borderColor: "#e6e6e6" },
                "header-cell-style": {
                  background: "#f97802",
                  height: "45px",
                  color: "rgba(255, 255, 255, 1)",
                  borderColor: "#e6e6e6",
                },
                "highlight-current-row": "",
                data: _vm.subTableData.list,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "tableNum",
                  label: _vm.$t("桌台号"),
                  "min-width": "140",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.tableNum
                                ? _vm.getZhTableName(scope.row.tableNum)
                                : ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "gid",
                  label: _vm.$t("局号"),
                  "min-width": "140",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "shoeAndGameNum",
                  label: _vm.$t("靴/局数"),
                  "min-width": "180",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "result",
                  label: _vm.$t("结果"),
                  "min-width": "100",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.renderZhResult(scope.row))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "agentName",
                  label: _vm.$t("代理"),
                  "min-width": "200",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "userName",
                  label: _vm.$t("玩家账号"),
                  "min-width": "160",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "betsId",
                  label: _vm.$t("投注编号"),
                  "min-width": "200",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "betTime",
                  label: _vm.$t("投注时间"),
                  "min-width": "200",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "correctTime",
                  label: _vm.$t("派彩时间"),
                  "min-width": "200",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "betType",
                  label: _vm.$t("投注类型"),
                  "min-width": "200",
                  sortable: "",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.betType
                                ? _vm.renderZhPlay(
                                    scope.row.betType,
                                    scope.row.tableNum
                                  )
                                : ""
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "amount",
                  label: _vm.$t("投注金额"),
                  "min-width": "200",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "userResult",
                  label: _vm.$t("输赢"),
                  "min-width": "200",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "currency",
                  label: _vm.$t("货币"),
                  "min-width": "200",
                  sortable: "",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "ip",
                  label: "ip",
                  "min-width": "200",
                  sortable: "",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagination-content" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.subTable.page,
                  "page-sizes": [10, 20, 30, 40, 50],
                  "page-size": _vm.subTable.limit,
                  total: parseInt(_vm.subTableData.total),
                  layout: "total, sizes, prev, pager, next, jumper",
                },
                on: {
                  "size-change": _vm.handleSubSizeChange,
                  "current-change": _vm.handleSubCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "charts-list" }, [
      _c("div", { attrs: { id: "zhudanbili" } }),
      _c("div", { attrs: { id: "touzhujinebili" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cwi_content" }, [
      _c("div", { staticClass: "c_content_win c_content_win1" }),
      _c("div", { staticClass: "c_content_lose c_content_lose1" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cwi_content" }, [
      _c("div", { staticClass: "c_content_win c_content_win2" }),
      _c("div", { staticClass: "c_content_lose c_content_lose2" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cwi_content" }, [
      _c("div", { staticClass: "c_content_win c_content_win3" }),
      _c("div", { staticClass: "c_content_lose c_content_lose3" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cwi_content" }, [
      _c("div", { staticClass: "c_content_win c_content_win4" }),
      _c("div", { staticClass: "c_content_lose c_content_lose4" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cwi_content" }, [
      _c("div", { staticClass: "c_content_win c_content_win5" }),
      _c("div", { staticClass: "c_content_lose c_content_lose5" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cwi_content" }, [
      _c("div", { staticClass: "c_content_win c_content_win6" }),
      _c("div", { staticClass: "c_content_lose c_content_lose6" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }