"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/web.dom.iterable");
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
var _vuexPersistedstate = _interopRequireDefault(require("vuex-persistedstate"));
var _getters = _interopRequireDefault(require("./getters"));
_vue.default.use(_vuex.default);
var modulesFiles = require.context('./modules', true, /\.js$/);
var modules = modulesFiles.keys().reduce(function (modules, modulePath) {
  var moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  var value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});
var store = new _vuex.default.Store({
  plugins: [(0, _vuexPersistedstate.default)({
    paths: ["user", "tabList", "permission", "langType"]
  })],
  modules: modules,
  getters: _getters.default
});
var _default = exports.default = store;