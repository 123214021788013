"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _logo = _interopRequireDefault(require("@/assets/login/logo.png"));
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'SidebarLogo',
  props: {
    isCollapse: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data: function data() {
    return {
      title: '代理管理网',
      logo: _logo.default
    };
  }
};