"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/core-js/modules/es6.array.iterator.js");
require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/core-js/modules/es6.promise.js");
require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/core-js/modules/es6.object.assign.js");
require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("element-ui/lib/theme-chalk/index.css");
var _Permission = _interopRequireDefault(require("./components/Permission"));
require("./assets/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _routers = _interopRequireDefault(require("./router/routers"));
require("./assets/icons");
require("./router/index");
var _lang = _interopRequireDefault(require("./lang"));
var _echarts = _interopRequireDefault(require("echarts"));
// 权限指令

_vue.default.prototype.$EventBus = new _vue.default();
_vue.default.use(_lang.default);
_vue.default.use(_Permission.default);
_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'small',
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
});
_vue.default.config.productionTip = false;
_vue.default.prototype.$echarts = _echarts.default;
new _vue.default({
  i18n: _lang.default,
  el: '#app',
  store: _store.default,
  router: _routers.default,
  render: function render(h) {
    return h(_App.default);
  }
}).$mount('#app');