"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.translateGame = exports.setGameType = exports.liveBetDetail = exports.handleDetailList = exports.filterGoalAway = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
var _lang = _interopRequireDefault(require("../lang"));
// 百家乐  庄[7-9-0] 闲[7-8-0]
function baccaratHandle(cards, category) {
  if (!cards) {
    return '--';
  }
  try {
    var lang = 'zh';
    var cards_list = cards.split(';');
    var player = [translateCard(cards_list[0]), translateCard(cards_list[2])]; // 闲家牌
    var banker = [translateCard(cards_list[1]), translateCard(cards_list[3])]; // 庄家牌

    if (translateCard(cards_list[4]) && translateCard(cards_list[4]) != '0') {
      player.push(translateCard(cards_list[4]));
    }
    if (translateCard(cards_list[5]) && translateCard(cards_list[5]) != '0') {
      banker.push(translateCard(cards_list[5]));
    }

    // if (translateCard(cards_list[4]) && translateCard(cards_list[4]) == 5) {
    //     translateCard(cards_list[4]) ? banker.push(translateCard(cards_list[4])) : null
    // } else {
    //     translateCard(cards_list[4]) ? player.push(translateCard(cards_list[4])) : null
    //     translateCard(cards_list[5]) ? banker.push(translateCard(cards_list[5])) : null
    // }
    if (category == 'banker') {
      return player.join('-');
    } else if (category == 'player') {
      return banker.join('-');
    } else {
      if (lang == 'zh') {
        return "[\u95F2:".concat(player.join('-'), ",\u5E84:").concat(banker.join('-'), "]");
      } else if (lang == 'tw') {
        return "[\u95F2:".concat(player.join('-'), ",\u5E84:").concat(banker.join('-'), "]");
      } else {
        return "[\u95F2:".concat(player.join('-'), ",\u5E84:").concat(banker.join('-'), ",]");
      }
    }
  } catch (err) {}
}

/**
 *  翻译游戏结果
 * @param {string} type  游戏类型 百家乐 龙虎 轮盘  骰宝 牛牛
 * @param {string} cards  对局游戏信息  
 * @param {string} category  游戏结果 返回 庄 或者 闲 或者庄闲
 * @param {string} lang  语言
 * @param {string} result   结果
 */
var translateGame = exports.translateGame = function translateGame(type, cards, result, category) {
  var gameResult = '';
  switch (type) {
    case "ROULETTE":
      //  轮盘
      gameResult = rouletteHandle(cards);
      break;
    case "BACCARAT":
      //  百家乐
      gameResult = baccaratHandle(cards, category);
      break;
    case "DRAGON-TIGER":
      gameResult = dragonTigerHandle(cards, category);
      break;
    case "DOU-NIU":
      gameResult = niuNiuHandle(cards, result);
      break;
    case "SIC-BO":
      gameResult = shaiBaoHandle(result);
      break;
    default:
      gameResult = '--';
      break;
  }
  ;
  return gameResult;
};

// 轮盘
function rouletteHandle(result) {
  if (!result) {
    return '--';
  }
  try {
    var lang = 'zh';
    var reulstList = [{
      key: '0',
      value: '0',
      color_zh: '绿',
      color_tw: '綠',
      color_en: 'green'
    }, {
      key: '1',
      value: '1',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '2',
      value: '2',
      color_zh: '黑',
      color_tw: '黑',
      color_en: 'black'
    }, {
      key: '3',
      value: '3',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '4',
      value: '4',
      color_zh: '黑',
      color_tw: '黑',
      color_en: 'black'
    }, {
      key: '5',
      value: '5',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '6',
      value: '6',
      color_zh: '黑',
      color_tw: '黑',
      color_en: 'black'
    }, {
      key: '7',
      value: '7',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '8',
      value: '8',
      color_zh: '黑',
      color_tw: '黑',
      color_en: 'black'
    }, {
      key: '9',
      value: '9',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '10',
      value: '10',
      color_zh: '黑',
      color_tw: '黑',
      color_en: 'black'
    }, {
      key: '11',
      value: '11',
      color_zh: '黑',
      color_tw: '黑',
      color_en: 'black'
    }, {
      key: '12',
      value: '12',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '13',
      value: '13',
      color_zh: '黑',
      color_tw: '黑',
      color_en: 'black'
    }, {
      key: '14',
      value: '14',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '15',
      value: '15',
      color_zh: '黑',
      color_tw: '黑',
      color_en: 'black'
    }, {
      key: '16',
      value: '16',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '17',
      value: '17',
      color_zh: '黑',
      color_tw: '黑',
      color_en: 'black'
    }, {
      key: '18',
      value: '18',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '19',
      value: '19',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '20',
      value: '20',
      color_zh: '黑',
      color_tw: '黑',
      color_en: 'black'
    }, {
      key: '21',
      value: '21',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '22',
      value: '22',
      color_zh: '黑',
      color_tw: '黑',
      color_en: 'black'
    }, {
      key: '23',
      value: '23',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '24',
      value: '24',
      color_zh: '黑',
      color_tw: '黑',
      color_en: 'black'
    }, {
      key: '25',
      value: '25',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '26',
      value: '26',
      color_zh: '黑',
      color_tw: '黑',
      color_en: 'black'
    }, {
      key: '27',
      value: '27',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '28',
      value: '28',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '29',
      value: '29',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '30',
      value: '30',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '31',
      value: '31',
      color_zh: '黑',
      color_tw: '黑',
      color_en: 'black'
    }, {
      key: '32',
      value: '32',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '33',
      value: '33',
      color_zh: '黑',
      color_tw: '黑',
      color_en: 'black'
    }, {
      key: '34',
      value: '34',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }, {
      key: '35',
      value: '35',
      color_zh: '黑',
      color_tw: '黑',
      color_en: 'black'
    }, {
      key: '36',
      value: '36',
      color_zh: '红',
      color_tw: '紅',
      color_en: 'red'
    }];
    var result_list = reulstList.filter(function (item) {
      return item.key == result;
    });
    return result_list[0] ? result_list[0].value + ' ' + result_list[0]["color_".concat(lang)] : '';
  } catch (err) {
    console.log(err);
  }
}

// 将 1,11,12,13 转成 AJQK
function translateCard(string) {
  if (!string) return '';
  var sArr = [];
  sArr = string.split('-');
  if (sArr[0] == '1') {
    return 'A';
  } else if (sArr[0] == '11') {
    return 'J';
  } else if (sArr[0] == '12') {
    return 'Q';
  } else if (sArr[0] == '13') {
    return 'K';
  } else {
    return sArr[0];
  }
}
;
function douniuType(type) {
  var niuObj = {
    PLAYER1: _lang.default.t('bettingType.PLAYER1'),
    YAJIN_FAN_PLAYER1: _lang.default.t('bettingType.YAJIN_FAN_PLAYER1'),
    FAN_PLAYER1: _lang.default.t('bettingType.FAN_PLAYER1'),
    PLAYER2: _lang.default.t('bettingType.PLAYER2'),
    YAJIN_FAN_PLAYER2: _lang.default.t('bettingType.YAJIN_FAN_PLAYER2'),
    FAN_PLAYER2: _lang.default.t('bettingType.FAN_PLAYER2'),
    PLAYER3: _lang.default.t('bettingType.PLAYER3'),
    YAJIN_FAN_PLAYER3: _lang.default.t('bettingType.YAJIN_FAN_PLAYER3'),
    FAN_PLAYER3: _lang.default.t('bettingType.FAN_PLAYER3')
  };
  return niuObj[type] ? niuObj[type] : '--';
}

// 龙虎
function dragonTigerHandle(cards, category) {
  var colorObj = {
    '4': '♦',
    '3': '♣',
    '2': '♥',
    '1': '♠'
  };
  if (!cards) {
    return '--';
  }
  try {
    var lang = 'zh';
    var cards_list = cards.split(';');
    var dragon = translateCard(cards_list[1]); // 龙家牌
    var dragonIndex = cards_list[1].split('-')[1];
    var dragon_color = colorObj[dragonIndex];
    var tiger = translateCard(cards_list[0]); // 虎家牌
    var tigerIndex = cards_list[0].split('-')[1];
    var tiger_color = colorObj[tigerIndex];
    // let dragon_color=

    if (category == 'banker') {
      return "".concat(dragon_color).concat(dragon);
    } else if (category == 'player') {
      return "".concat(tiger_color).concat(tiger);
    } else {
      if (lang == 'zh') {
        return "[\u9F99".concat(dragon, ",\u864E").concat(tiger, "]");
      } else if (lang == 'tw') {
        return "[\u9F99".concat(dragon, ",\u864E").concat(tiger, "]");
      } else {
        return "[\u9F99".concat(dragon, "],\u864E[").concat(tiger, "]");
      }
    }
  } catch (err) {
    console.log(err);
  }
}

// 牛牛游戏处理
function niuNiuHandle(cards, result) {
  if (!cards || !result) {
    return '--';
  }
  try {
    var resData = JSON.parse(result);
    var resCards = cards[0];
    var banker = resData[0].fullNiu || resData[0].point == '0' ? '牛牛' : resData[0].hasNiu ? "\u725B".concat(resData[0].point) : '无牛';
    var player1 = resData[1].fullNiu || resData[0].point == '0' ? '牛牛' : resData[1].hasNiu ? "\u725B".concat(resData[1].point) : '无牛';
    var player2 = resData[2].fullNiu || resData[0].point == '0' ? '牛牛' : resData[2].hasNiu ? "\u725B".concat(resData[2].point) : '无牛';
    var player3 = resData[3].fullNiu || resData[0].point == '0' ? '牛牛' : resData[3].hasNiu ? "\u725B".concat(resData[3].point) : '无牛';
    var title = "[\u5934\u724C".concat(resCards, ",\u5E84:").concat(banker, ",\u95F21:").concat(player1, ", \u95F22:").concat(player2, ",\u95F23:").concat(player3, "]");
    return title;
  } catch (err) {
    console.log(err);
  }
}

// 骰宝
function shaiBaoHandle(result) {
  if (!result) {
    return '--';
  }
  var title = result.replace(new RegExp(';', 'g'), ',');
  return title;
}

// 设置游戏类型
var setGameType = exports.setGameType = function setGameType(type) {
  var type_s = type.toUpperCase();
  if (type_s.includes('BACCARAT')) {
    // 百家乐
    return 'BACCARAT';
  } else if (type_s.includes('BACCAROT')) {
    // 百家乐
    return 'BACCARAT';
  } else if (type_s.includes('DRAGON-TIGER')) {
    // 龙虎
    return 'DRAGON-TIGER';
  } else if (type_s.includes('DOU-NIU')) {
    // 斗牛
    return 'DOU-NIU';
  } else if (type_s.includes('ROULETTE')) {
    // 轮盘
    return 'ROULETTE';
  } else if (type_s.includes('SIC-BO')) {
    // 骰宝
    return 'SIC-BO';
  } else if (type_s.includes('DICE')) {
    // 骰宝
    return 'SIC-BO';
  } else {
    return '--';
  }
};

// 游戏结果翻译 百家乐 龙虎需要展示游戏结果/牌

// 用户投注明细解析
var handleDetailList = exports.handleDetailList = function handleDetailList(type, amount, result, chooseTeamName) {
  var resultList = []; // 数据结果
  if (type.includes('BACCARAT')) {
    //百家乐
    var teamList = chooseTeamName.split('#');
    teamList = teamList.filter(function (item) {
      return item.length > 0;
    });
    for (var i = 0; i < teamList.length; i++) {
      var amountList = teamList[i].split(':');
      var target = {
        index: baccaratType(amountList[0]),
        betAmount: amountList[1] ? amountList[1].split('@')[0] : '',
        betResult: amountList[1] ? amountList[1].split('@')[1] : ''
      };
      resultList.push(target);
    }
    resultList.push({
      index: _lang.default.t('bettingType.heji'),
      betAmount: amount,
      betResult: result
    });
    return resultList;
  } else if (type.includes('DRAGON-TIGER')) {
    // 龙虎
    var _teamList = chooseTeamName.split('#');
    _teamList = _teamList.filter(function (item) {
      return item.length > 0;
    });
    for (var _i = 0; _i < _teamList.length; _i++) {
      var _amountList = _teamList[_i].split(':');
      var _target = {
        index: _amountList[0] == 'PLAYER' ? _lang.default.t('bettingType.hu') : _amountList[0] == 'BANKER' ? _lang.default.t('bettingType.long') : _lang.default.t('bettingType.he'),
        betAmount: _amountList[1] ? _amountList[1].split('@')[0] : '',
        betResult: _amountList[1] ? _amountList[1].split('@')[1] : ''
      };
      resultList.push(_target);
    }
    resultList.push({
      index: _lang.default.t('bettingType.heji'),
      betAmount: amount,
      betResult: result
    });
    return resultList;
  } else if (type.includes('DOU-NIU')) {
    // 斗牛
    var _teamList2 = chooseTeamName.split('#');
    _teamList2 = _teamList2.filter(function (item) {
      return item.length > 0;
    });
    for (var _i2 = 0; _i2 < _teamList2.length; _i2++) {
      var _amountList2 = _teamList2[_i2].split(':');
      var _target2 = {
        index: douniuType(_amountList2[0]),
        betAmount: _amountList2[1] ? _amountList2[1].split('@')[0] : '',
        betResult: _amountList2[1] ? _amountList2[1].split('@')[1] : ''
      };
      resultList.push(_target2);
    }
    resultList.push({
      index: _lang.default.t('bettingType.heji'),
      betAmount: amount,
      betResult: result
    });
    return resultList;
  } else if (type.includes('ROULETTE')) {
    // 轮盘

    var _teamList3 = chooseTeamName.split('#');
    _teamList3 = _teamList3.filter(function (item) {
      return item.length > 0;
    });
    for (var _i3 = 0; _i3 < _teamList3.length; _i3++) {
      var _amountList3 = _teamList3[_i3].split(':');
      var _target3 = {
        index: roulette(_amountList3[0]),
        betAmount: _amountList3[1] ? _amountList3[1].split('@')[0] : '',
        betResult: _amountList3[1] ? _amountList3[1].split('@')[1] : ''
      };
      resultList.push(_target3);
    }
    resultList.push({
      index: _lang.default.t('bettingType.heji'),
      betAmount: amount,
      betResult: result
    });
    return resultList;
  } else if (type.includes('SIC-BO')) {
    // 骰宝
    var _teamList4 = chooseTeamName.split('#');
    _teamList4 = _teamList4.filter(function (item) {
      return item.length > 0;
    });
    for (var _i4 = 0; _i4 < _teamList4.length; _i4++) {
      var _amountList4 = _teamList4[_i4].split(':');
      var _target4 = {
        index: sibotype(_amountList4[0]),
        betAmount: _amountList4[1] ? _amountList4[1].split('@')[0] : '',
        betResult: _amountList4[1] ? _amountList4[1].split('@')[1] : ''
      };
      resultList.push(_target4);
    }
    resultList.push({
      index: _lang.default.t('bettingType.heji'),
      betAmount: amount,
      betResult: result
    });
    return resultList;
  } else {
    return resultList;
  }
};

// 用户投注明细解析
var liveBetDetail = exports.liveBetDetail = function liveBetDetail(type, chooseTeamName) {
  var resultList = []; // 数据结果

  if (type.includes('BACCARAT')) {
    //百家乐
    var teamList = chooseTeamName.split('#');
    teamList = teamList.filter(function (item) {
      return item.length > 0;
    });
    for (var i = 0; i < teamList.length; i++) {
      var amountList = teamList[i].split(':');
      resultList.push("".concat(baccaratType(amountList[0]), ":").concat(amountList[1]));
    }
    var result = resultList.join(',');
    return result.slice(0, -1);
  } else if (type.includes('DRAGON-TIGER')) {
    // 龙虎
    var _teamList5 = chooseTeamName.split('#');
    _teamList5 = _teamList5.filter(function (item) {
      return item.length > 0;
    });
    for (var _i5 = 0; _i5 < _teamList5.length; _i5++) {
      var _amountList5 = _teamList5[_i5].split(':');
      var pointName = _amountList5[0] == 'PLAYER' ? _lang.default.t('bettingType.hu') : _amountList5[0] == 'BANKER' ? _lang.default.t('bettingType.long') : _lang.default.t('bettingType.he');
      resultList.push("".concat(pointName, ":").concat(_amountList5[1]));
    }
    var _result = resultList.join(',');
    return _result.slice(0, -1);
  } else if (type.includes('DOU-NIU')) {
    // 斗牛
    var _teamList6 = chooseTeamName.split('#');
    _teamList6 = _teamList6.filter(function (item) {
      return item.length > 0;
    });
    for (var _i6 = 0; _i6 < _teamList6.length; _i6++) {
      var _amountList6 = _teamList6[_i6].split(':');
      resultList.push("".concat(douniuType(_amountList6[0]), ":").concat(_amountList6[1]));
    }
    var _result2 = resultList.join(',');
    return _result2.slice(0, -1);
  } else if (type.includes('ROULETTE')) {
    // 轮盘
    var _teamList7 = chooseTeamName.split('#');
    _teamList7 = _teamList7.filter(function (item) {
      return item.length > 0;
    });
    for (var _i7 = 0; _i7 < _teamList7.length; _i7++) {
      var _amountList7 = _teamList7[_i7].split(':');
      resultList.push("".concat(roulette(_amountList7[0]), ":").concat(_amountList7[1]));
    }
    var _result3 = resultList.join(',');
    return _result3.slice(0, -1);
  } else if (type.includes('SIC-BO')) {
    // 骰宝
    var _teamList8 = chooseTeamName.split('#');
    _teamList8 = _teamList8.filter(function (item) {
      return item.length > 0;
    });
    for (var _i8 = 0; _i8 < _teamList8.length; _i8++) {
      var _amountList8 = _teamList8[_i8].split(':');
      resultList.push("".concat(sibotype(_amountList8[0]), ":").concat(_amountList8[1]));
    }
    var _result4 = resultList.join(',');
    return _result4.slice(0, -1);
  } else {
    return resultList.join(',');
  }
};

// 斗牛下注类型

// 骰宝下注类型
function sibotype(type) {
  var typeObj = {
    DiTri0: _lang.default.t('bettingType.DiTri0'),
    DiTri1: _lang.default.t('bettingType.DiTri1'),
    DiTri2: _lang.default.t('bettingType.DiTri2'),
    DiTri3: _lang.default.t('bettingType.DiTri3'),
    DiTri4: _lang.default.t('bettingType.DiTri4'),
    DiTri5: _lang.default.t('bettingType.DiTri5'),
    DiTri6: _lang.default.t('bettingType.DiTri6'),
    DiDou1: _lang.default.t('bettingType.DiDou1'),
    DiDou2: _lang.default.t('bettingType.DiDou2'),
    DiDou3: _lang.default.t('bettingType.DiDou3'),
    DiDou4: _lang.default.t('bettingType.DiDou4'),
    DiDou5: _lang.default.t('bettingType.DiDou5'),
    DiDou6: _lang.default.t('bettingType.DiDou6'),
    DiTo04: _lang.default.t('bettingType.DiTo04'),
    DiTo05: _lang.default.t('bettingType.DiTo05'),
    DiTo06: _lang.default.t('bettingType.DiTo06'),
    DiTo07: _lang.default.t('bettingType.DiTo07'),
    DiTo08: _lang.default.t('bettingType.DiTo08'),
    DiTo09: _lang.default.t('bettingType.DiTo09'),
    DiTo10: _lang.default.t('bettingType.DiTo10'),
    DiTo11: _lang.default.t('bettingType.DiTo11'),
    DiTo12: _lang.default.t('bettingType.DiTo12'),
    DiTo13: _lang.default.t('bettingType.DiTo13'),
    DiTo14: _lang.default.t('bettingType.DiTo14'),
    DiTo15: _lang.default.t('bettingType.DiTo15'),
    DiTo16: _lang.default.t('bettingType.DiTo16'),
    DiTo17: _lang.default.t('bettingType.DiTo17'),
    DiTw12: _lang.default.t('bettingType.DiTw12'),
    DiTw13: _lang.default.t('bettingType.DiTw13'),
    DiTw14: _lang.default.t('bettingType.DiTw14'),
    DiTw15: _lang.default.t('bettingType.DiTw15'),
    DiTw16: _lang.default.t('bettingType.DiTw16'),
    DiTw23: _lang.default.t('bettingType.DiTw23'),
    DiTw24: _lang.default.t('bettingType.DiTw24'),
    DiTw25: _lang.default.t('bettingType.DiTw25'),
    DiTw26: _lang.default.t('bettingType.DiTw26'),
    DiTw34: _lang.default.t('bettingType.DiTw34'),
    DiTw35: _lang.default.t('bettingType.DiTw35'),
    DiTw36: _lang.default.t('bettingType.DiTw36'),
    DiTw45: _lang.default.t('bettingType.DiTw45'),
    DiTw46: _lang.default.t('bettingType.DiTw46'),
    DiTw56: _lang.default.t('bettingType.DiTw56'),
    DiOne1: _lang.default.t('bettingType.DiOne1'),
    DiOne2: _lang.default.t('bettingType.DiOne2'),
    DiOne3: _lang.default.t('bettingType.DiOne3'),
    DiOne4: _lang.default.t('bettingType.DiOne4'),
    DiOne5: _lang.default.t('bettingType.DiOne5'),
    DiOne6: _lang.default.t('bettingType.DiOne6'),
    DiEven: _lang.default.t('bettingType.DiEven'),
    DiOdd: _lang.default.t('bettingType.DiOdd'),
    DiBig: _lang.default.t('bettingType.DiBig'),
    DiSm: _lang.default.t('bettingType.DiSm')
  };
  return typeObj[type] ? typeObj[type] : '--';
}

// 轮盘下注类型
function roulette(type) {
  var rouletteObj = {
    RoEven: _lang.default.t('bettingType.RoEven'),
    RoOdd: _lang.default.t('bettingType.RoOdd'),
    Ro12s1: _lang.default.t('bettingType.Ro12s1'),
    Ro12s2: _lang.default.t('bettingType.Ro12s2'),
    Ro12s3: _lang.default.t('bettingType.Ro12s3'),
    RoRow1: _lang.default.t('bettingType.RoRow1'),
    RoRow2: _lang.default.t('bettingType.RoRow2'),
    RoRow3: _lang.default.t('bettingType.RoRow3'),
    RoSm: _lang.default.t('bettingType.RoSm'),
    RoBig: _lang.default.t('bettingType.RoBig'),
    RoSm1: _lang.default.t('bettingType.RoSm1'),
    RoBig1: _lang.default.t('bettingType.RoBig1'),
    RoBak: _lang.default.t('bettingType.RoBak'),
    RoRed: _lang.default.t('bettingType.RoRed'),
    RedBak: _lang.default.t('bettingType.RedBak'),
    BigSm: _lang.default.t('bettingType.BigSm'),
    TaxiList: _lang.default.t('bettingType.TaxiList'),
    OddEven: _lang.default.t('bettingType.OddEven'),
    Direct_Bet: _lang.default.t('bettingType.Direct_Bet'),
    Marks_Bet: _lang.default.t('bettingType.Marks_Bet'),
    Street_Bet: _lang.default.t('bettingType.Street_Bet'),
    Three_Several: _lang.default.t('bettingType.Three_Several'),
    Corner_Bet: _lang.default.t('bettingType.Corner_Bet'),
    Four_Bet: _lang.default.t('bettingType.Four_Bet'),
    Line_Bet: _lang.default.t('bettingType.Line_Bet'),
    Make_Bet: _lang.default.t('bettingType.Make_Bet'),
    Bet_Dozen: _lang.default.t('bettingType.Bet_Dozen'),
    RoSi88: _lang.default.t('bettingType.RoSi88'),
    RoSi01: _lang.default.t('bettingType.RoSi01'),
    RoSi02: _lang.default.t('bettingType.RoSi02'),
    RoSi03: _lang.default.t('bettingType.RoSi03'),
    RoSi04: _lang.default.t('bettingType.RoSi04'),
    RoSi05: _lang.default.t('bettingType.RoSi05'),
    RoSi06: _lang.default.t('bettingType.RoSi06'),
    RoSi07: _lang.default.t('bettingType.RoSi07'),
    RoSi08: _lang.default.t('bettingType.RoSi08'),
    RoSi09: _lang.default.t('bettingType.RoSi09'),
    RoSi10: _lang.default.t('bettingType.RoSi10'),
    RoSi11: _lang.default.t('bettingType.RoSi11'),
    RoSi12: _lang.default.t('bettingType.RoSi12'),
    RoSi13: _lang.default.t('bettingType.RoSi13'),
    RoSi14: _lang.default.t('bettingType.RoSi14'),
    RoSi15: _lang.default.t('bettingType.RoSi15'),
    RoSi16: _lang.default.t('bettingType.RoSi16'),
    RoSi17: _lang.default.t('bettingType.RoSi17'),
    RoSi18: _lang.default.t('bettingType.RoSi18'),
    RoSi19: _lang.default.t('bettingType.RoSi19'),
    RoSi20: _lang.default.t('bettingType.RoSi20'),
    RoSi21: _lang.default.t('bettingType.RoSi21'),
    RoSi22: _lang.default.t('bettingType.RoSi22'),
    RoSi23: _lang.default.t('bettingType.RoSi23'),
    RoSi24: _lang.default.t('bettingType.RoSi24'),
    RoSi25: _lang.default.t('bettingType.RoSi25'),
    RoSi26: _lang.default.t('bettingType.RoSi26'),
    RoSi27: _lang.default.t('bettingType.RoSi27'),
    RoSi28: _lang.default.t('bettingType.RoSi28'),
    RoSi29: _lang.default.t('bettingType.RoSi29'),
    RoSi30: _lang.default.t('bettingType.RoSi30'),
    RoSi31: _lang.default.t('bettingType.RoSi31'),
    RoSi32: _lang.default.t('bettingType.RoSi32'),
    RoSi33: _lang.default.t('bettingType.RoSi33'),
    RoSi34: _lang.default.t('bettingType.RoSi34'),
    RoSi35: _lang.default.t('bettingType.RoSi35'),
    RoSi36: _lang.default.t('bettingType.RoSi36'),
    RoT_12: _lang.default.t('bettingType.RoT_12'),
    RoT_23: _lang.default.t('bettingType.RoT_23'),
    Ro_123: _lang.default.t('bettingType.Ro_123'),
    RoCol1: _lang.default.t('bettingType.RoCol1'),
    RoCol2: _lang.default.t('bettingType.RoCol2'),
    RoCol3: _lang.default.t('bettingType.RoCol3'),
    RoCol4: _lang.default.t('bettingType.RoCol4'),
    RoCol5: _lang.default.t('bettingType.RoCol5'),
    RoCol6: _lang.default.t('bettingType.RoCol6'),
    RoCol7: _lang.default.t('bettingType.RoCol7'),
    RoCol8: _lang.default.t('bettingType.RoCol8'),
    RoCol9: _lang.default.t('bettingType.RoCol9'),
    RoCol0: _lang.default.t('bettingType.RoCol0'),
    RoCola: _lang.default.t('bettingType.RoCola'),
    Ro1245: _lang.default.t('bettingType.Ro1245'),
    Ro2356: _lang.default.t('bettingType.Ro2356'),
    Ro4578: _lang.default.t('bettingType.Ro4578'),
    Ro5689: _lang.default.t('bettingType.Ro5689'),
    Ro780a: _lang.default.t('bettingType.Ro780a'),
    Ro89ab: _lang.default.t('bettingType.Ro89ab'),
    Ro0acd: _lang.default.t('bettingType.Ro0acd'),
    Roabde: _lang.default.t('bettingType.Roabde'),
    Rocdfg: _lang.default.t('bettingType.Rocdfg'),
    Rodegh: _lang.default.t('bettingType.Rodegh'),
    Rofgij: _lang.default.t('bettingType.Rofgij'),
    Roghjk: _lang.default.t('bettingType.Roghjk'),
    Roijlm: _lang.default.t('bettingType.Roijlm'),
    Rojkmn: _lang.default.t('bettingType.Rojkmn'),
    Rolmop: _lang.default.t('bettingType.Rolmop'),
    Romnpq: _lang.default.t('bettingType.Romnpq'),
    Rooprs: _lang.default.t('bettingType.Rooprs'),
    Ropqst: _lang.default.t('bettingType.Ropqst'),
    Rorsuv: _lang.default.t('bettingType.Rorsuv'),
    Rostvw: _lang.default.t('bettingType.Rostvw'),
    Rouvxy: _lang.default.t('bettingType.Rouvxy'),
    Rovwyz: _lang.default.t('bettingType.Rovwyz'),
    RoT123: _lang.default.t('bettingType.RoT123'),
    RoT456: _lang.default.t('bettingType.RoT456'),
    RoT789: _lang.default.t('bettingType.RoT789'),
    RoT0ab: _lang.default.t('bettingType.RoT0ab'),
    RoTcde: _lang.default.t('bettingType.RoTcde'),
    RoTfgh: _lang.default.t('bettingType.RoTfgh'),
    RoTijk: _lang.default.t('bettingType.RoTijk'),
    RoTlmn: _lang.default.t('bettingType.RoTlmn'),
    RoTopq: _lang.default.t('bettingType.RoTopq'),
    RoTrst: _lang.default.t('bettingType.RoTrst'),
    RoTuvw: _lang.default.t('bettingType.RoTuvw'),
    RoTxyz: _lang.default.t('bettingType.RoTxyz'),
    RoDu12: _lang.default.t('bettingType.RoDu12'),
    RoDu23: _lang.default.t('bettingType.RoDu23'),
    RoDu45: _lang.default.t('bettingType.RoDu45'),
    RoDu56: _lang.default.t('bettingType.RoDu56'),
    RoDu78: _lang.default.t('bettingType.RoDu78'),
    RoDu89: _lang.default.t('bettingType.RoDu89'),
    RoDu0a: _lang.default.t('bettingType.RoDu0a'),
    RoDuab: _lang.default.t('bettingType.RoDuab'),
    RoDucd: _lang.default.t('bettingType.RoDucd'),
    RoDude: _lang.default.t('bettingType.RoDude'),
    RoDufg: _lang.default.t('bettingType.RoDufg'),
    RoDugh: _lang.default.t('bettingType.RoDugh'),
    RoDuij: _lang.default.t('bettingType.RoDuij'),
    RoDujk: _lang.default.t('bettingType.RoDujk'),
    RoDulm: _lang.default.t('bettingType.RoDulm'),
    RoDumn: _lang.default.t('bettingType.RoDumn'),
    RoDuop: _lang.default.t('bettingType.RoDuop'),
    RoDupq: _lang.default.t('bettingType.RoDupq'),
    RoDurs: _lang.default.t('bettingType.RoDurs'),
    RoDust: _lang.default.t('bettingType.RoDust'),
    RoDuuv: _lang.default.t('bettingType.RoDuuv'),
    RoDuvw: _lang.default.t('bettingType.RoDuvw'),
    RoDuxy: _lang.default.t('bettingType.RoDuxy'),
    RoDuyz: _lang.default.t('bettingType.RoDuyz'),
    RoDu_1: _lang.default.t('bettingType.RoDu_1'),
    RoDu_2: _lang.default.t('bettingType.RoDu_2'),
    RoDu_3: _lang.default.t('bettingType.RoDu_3'),
    RoDu14: _lang.default.t('bettingType.RoDu14'),
    RoDu25: _lang.default.t('bettingType.RoDu25'),
    RoDu36: _lang.default.t('bettingType.RoDu36'),
    RoDu47: _lang.default.t('bettingType.RoDu47'),
    RoDu58: _lang.default.t('bettingType.RoDu58'),
    RoDu69: _lang.default.t('bettingType.RoDu69'),
    RoDu70: _lang.default.t('bettingType.RoDu70'),
    RoDu8a: _lang.default.t('bettingType.RoDu8a'),
    RoDu9b: _lang.default.t('bettingType.RoDu9b'),
    RoDu0c: _lang.default.t('bettingType.RoDu0c'),
    RoDuad: _lang.default.t('bettingType.RoDuad'),
    RoDube: _lang.default.t('bettingType.RoDube'),
    RoDucf: _lang.default.t('bettingType.RoDucf'),
    RoDudg: _lang.default.t('bettingType.RoDudg'),
    RoDueh: _lang.default.t('bettingType.RoDueh'),
    RoDufi: _lang.default.t('bettingType.RoDufi'),
    RoDugj: _lang.default.t('bettingType.RoDugj'),
    RoDuhk: _lang.default.t('bettingType.RoDuhk'),
    RoDuil: _lang.default.t('bettingType.RoDuil'),
    RoDujm: _lang.default.t('bettingType.RoDujm'),
    RoDukn: _lang.default.t('bettingType.RoDukn'),
    RoDulo: _lang.default.t('bettingType.RoDulo'),
    RoDump: _lang.default.t('bettingType.RoDump'),
    RoDunq: _lang.default.t('bettingType.RoDunq'),
    RoDuor: _lang.default.t('bettingType.RoDuor'),
    RoDups: _lang.default.t('bettingType.RoDups'),
    RoDuqt: _lang.default.t('bettingType.RoDuqt'),
    RoDuru: _lang.default.t('bettingType.RoDuru'),
    RoDusv: _lang.default.t('bettingType.RoDusv'),
    RoDutw: _lang.default.t('bettingType.RoDutw'),
    RoDuux: _lang.default.t('bettingType.RoDuux'),
    RoDuvy: _lang.default.t('bettingType.RoDuvy'),
    RoDuwz: _lang.default.t('bettingType.RoDuwz')
  };
  return rouletteObj[type] ? rouletteObj[type] : '--';
}

// 百家乐下注类型
function baccaratType(type) {
  var baccaratObj = {
    BANKER: _lang.default.t('bettingType.BANKER'),
    PLAYER: _lang.default.t('bettingType.PLAYER'),
    TILE: _lang.default.t('bettingType.TILE'),
    BP: _lang.default.t('bettingType.BP'),
    PP: _lang.default.t('bettingType.PP'),
    BO: _lang.default.t('bettingType.BO'),
    BE: _lang.default.t('bettingType.BE'),
    PO: _lang.default.t('bettingType.PO'),
    PE: _lang.default.t('bettingType.PE'),
    SS: _lang.default.t('bettingType.SS'),
    BIG: _lang.default.t('bettingType.BIG'),
    SMALL: _lang.default.t('bettingType.SMALL'),
    BN: _lang.default.t('bettingType.BN'),
    PN: _lang.default.t('bettingType.PN'),
    AP: _lang.default.t('bettingType.AP'),
    PFP: _lang.default.t('bettingType.PFP'),
    BDB: _lang.default.t('bettingType.BDB'),
    PDB: _lang.default.t('bettingType.PDB'),
    LS: _lang.default.t('bettingType.LS')
  };
  return baccaratObj[type] ? baccaratObj[type] : '--';
}
var filterGoalAway = exports.filterGoalAway = function filterGoalAway(type, goalAway) {
  var result = '';
  if (type.includes('BACCAROT')) {
    // 百家乐
    result = baccaratType(goalAway);
  } else if (type.includes('BACCARAT')) {
    // 百家乐
    result = baccaratType(goalAway);
  } else if (type.includes('DRAGON-TIGER')) {
    // 龙虎
    result = goalAway.includes('PLAYER') ? _lang.default.t('bettingType.hu') : goalAway.includes('BANKER') ? _lang.default.t('bettingType.long') : _lang.default.t('bettingType.he');
  } else {
    result = '';
  }
  ;
  return result;
};