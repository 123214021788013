var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                display: "block",
                "margin-bottom": "10px",
                "font-size": "14px",
              },
            },
            [
              _c("font", { attrs: { color: "red" } }, [
                _vm._v("[" + _vm._s(_vm.$t("北京时间")) + "]"),
              ]),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.queryForm, inline: true },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("账户名"),
                    },
                    model: {
                      value: _vm.queryForm.loginName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "loginName", $$v)
                      },
                      expression: "queryForm.loginName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("注单号"),
                    },
                    model: {
                      value: _vm.queryForm.round,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "round", $$v)
                      },
                      expression: "queryForm.round",
                    },
                  }),
                ],
                1
              ),
              _vm.isAdmin
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("渠道"),
                            filterable: "",
                            clearable: "",
                          },
                          model: {
                            value: _vm.queryForm.channel,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "channel", $$v)
                            },
                            expression: "queryForm.channel",
                          },
                        },
                        _vm._l(_vm.channelList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: item },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      clearable: "",
                      "default-time": "12:00:00",
                      placeholder: _vm.$t("开始时间"),
                    },
                    model: {
                      value: _vm.queryForm.beginTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "beginTime", $$v)
                      },
                      expression: "queryForm.beginTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      clearable: "",
                      "default-time": "11:59:59",
                      placeholder: _vm.$t("结束时间"),
                    },
                    model: {
                      value: _vm.queryForm.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "endTime", $$v)
                      },
                      expression: "queryForm.endTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px" },
                      attrs: { placeholder: _vm.$t("时间类型") },
                      model: {
                        value: _vm.queryForm.timeType,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "timeType", $$v)
                        },
                        expression: "queryForm.timeType",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("创建时间"), value: "create" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("退款时间"), value: "refund" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "140px" },
                      attrs: { placeholder: _vm.$t("注单状态") },
                      model: {
                        value: _vm.queryForm.fail,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "fail", $$v)
                        },
                        expression: "queryForm.fail",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("全部"), value: "" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("正常"), value: "1,3,4,5" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("异常"), value: "0,2" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.onSearch },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("搜索")) + " ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.onReset },
                    },
                    [_vm._v(_vm._s(_vm.$t("清空条件")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "tablex",
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "45px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.tableData.list,
            height: "480px",
          },
        },
        [
          [
            _c("el-table-column", {
              attrs: {
                prop: "round",
                label: _vm.$t("游戏类型"),
                "min-width": "100",
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.type == 1
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("真人视讯")))])
                        : _vm._e(),
                      scope.row.type == 2
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("直播")))])
                        : _vm._e(),
                      scope.row.type == 3
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("区块链")))])
                        : _vm._e(),
                      scope.row.type == 4
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("棋牌/捕鱼")))])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "channel",
                label: _vm.$t("渠道"),
                "min-width": "120",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "loginName",
                label: _vm.$t("用户名称"),
                "min-width": "140",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "round",
                label: _vm.$t("注单号"),
                "min-width": "140",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "mtCode",
                label: _vm.$t("交易码"),
                "min-width": "140",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "amount",
                label: _vm.$t("金额"),
                "min-width": "100",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "roolType",
                label: _vm.$t("注单类型"),
                "min-width": "100",
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.roolType == "1"
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("下注")))])
                        : _vm._e(),
                      scope.row.roolType == "2"
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("结算")))])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createTime",
                label: _vm.$t("注单状态"),
                "min-width": "100",
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      ["1", "3", "4", "5"].includes(scope.row.fail)
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("正常")))])
                        : _c("span", [_vm._v(_vm._s(_vm.$t("异常")))]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "createTime",
                label: _vm.$t("创建时间"),
                "min-width": "150",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "refundTime",
                label: _vm.$t("退款时间"),
                "min-width": "150",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "refundUser",
                label: _vm.$t("退款操作人"),
                "min-width": "110",
                sortable: "",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "fail",
                label: _vm.$t("操作状态"),
                width: "110",
                sortable: "",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.opFlag == 0 && scope.row.roolType == 1
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("退款失败")))])
                        : _vm._e(),
                      scope.row.opFlag == 1 && scope.row.roolType == 1
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("退款成功")))])
                        : _vm._e(),
                      scope.row.opFlag == 0 && scope.row.roolType == 2
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("结算失败")))])
                        : _vm._e(),
                      scope.row.opFlag == 1 && scope.row.roolType == 2
                        ? _c("span", [_vm._v(_vm._s(_vm.$t("结算成功")))])
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: _vm.$t("操作"), "min-width": "90", sortable: "" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.roolType == 1
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "warning", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleRefund(scope.row)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("退款")) + " ")]
                          )
                        : _vm._e(),
                      scope.row.roolType == 2
                        ? _c(
                            "el-button",
                            {
                              attrs: { type: "warning", size: "mini" },
                              on: {
                                click: function ($event) {
                                  return _vm.reconcileAgain(scope.row)
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("重新结算")) + " ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
          ],
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "pagination-content" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryForm.page,
              "page-sizes": [10, 20, 30, 40, 50, 100],
              "page-size": _vm.queryForm.limit,
              total: parseInt(_vm.tableData.total),
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }