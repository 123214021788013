"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addChannel = addChannel;
exports.channelManageList = channelManageList;
exports.deleteChannel = deleteChannel;
exports.unLockChannel = unLockChannel;
exports.updateChannel = updateChannel;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取列表数据
function channelManageList(params) {
  return (0, _request.default)({
    url: "/yx/channelMg/list?",
    method: 'GET',
    params: params
  });
}

// 添加渠道
function addChannel(params) {
  return (0, _request.default)({
    url: '/yx/channelMg/add',
    method: 'POST',
    params: params
  });
}

// 修改渠道
function updateChannel(params) {
  return (0, _request.default)({
    url: '/yx/channelMg/update',
    method: 'POST',
    params: params
  });
}

// 删除渠道
function deleteChannel(params) {
  return (0, _request.default)({
    url: '/yx/channelMg/delete',
    method: 'POST',
    params: params
  });
}

// 解锁渠道
function unLockChannel(params) {
  return (0, _request.default)({
    url: '/yx/channelMg/unLock',
    method: 'GET',
    showLoading: true,
    params: params
  });
}