"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
var _notice = require("@/api/notice");
var _time = require("@/utils/time");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'Dashboard',
  data: function data() {
    return {
      list: []
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['userInfo'])),
  mounted: function mounted() {},
  methods: {
    handleSetLineChartData: function handleSetLineChartData(type) {
      this.lineChartData = lineChartData[type];
    },
    getNotice: function getNotice() {
      var _this = this;
      var agents = this.userInfo.userType == 'PLATFORM' ? ["COMPANY", "PARTNER", "GENGERAL", "AGENT", "SUBAGENT"] : [this.userInfo.userType];
      var reqData = {
        "parentid": this.userInfo.id,
        "pageNo": 1,
        "pageSize": 100,
        "startdate": (0, _time.getDay)(-91),
        "enddate": (0, _time.getDay)(1),
        "state": 'NORMAL',
        "opid": this.userInfo.id,
        "agents": agents,
        "visible": 1
      };
      (0, _notice.homeNotice)(reqData).then(function (res) {
        if (res.code == 200) {
          _this.list = res.data.list;
        }
      });
    }
  }
};