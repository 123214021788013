"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.array.includes");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _betReportList = require("@/api/report/betReportList");
var _common = require("@/api/common");
var _gameProperties = require("@/utils/gameProperties");
var _downFiles = require("@/utils/downFiles");
var _elementUi = require("element-ui");
var _vuex = require("vuex");
var _lodash = _interopRequireDefault(require("lodash"));
var _big = require("@/utils/big");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 游戏记录查询
var _default = exports.default = {
  name: 'gameRecord',
  props: {},
  data: function data() {
    return {
      channelList: [],
      currencyList: [],
      loading: false,
      tableData: {
        list: [],
        total: 0,
        totalPage: 1
      },
      totalList: [],
      queryForm: {
        page: 1,
        limit: 10,
        userName: '',
        betId: '',
        roundId: '',
        beginTime: '',
        endTime: '',
        enterType: '',
        channel: '',
        CCYID: '',
        agentName: '',
        betPoint: '',
        clientType: '',
        groupTableId: '',
        //按桌台统计
        groupBetPoint: '',
        //按投注类型统计
        groupClientType: '',
        //按客户端类型统计
        groupDate: '',
        //按投注日期统计
        groupEnterType: '',
        //按下注方式统计
        gameName: 'BACCARAT' // 游戏类型
      }
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['isAdmin'])),
  watch: {
    '$route.params': {
      handler: function handler(newValue, oldValue) {
        this.init();
      },
      deep: true
    }
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    getTableName: _gameProperties.getTableName,
    formatCurrency: _gameProperties.formatCurrency,
    getGameName: _gameProperties.getGameName,
    getStatus: _gameProperties.getStatus,
    renderResult: _gameProperties.renderResult,
    renderCurrency: _gameProperties.renderCurrency,
    getBetPoint: _gameProperties.getBetPoint,
    init: function init() {
      this.getChannelList();
      this.getCurrencyList();
    },
    onSearch: function onSearch() {
      this.queryForm.page = 1;
      this.getList();
    },
    getParams: function getParams() {
      var obj = {};
      var keyArr = ['beginTime', 'endTime', 'channel', 'clientType', 'betPoint', 'enterType'];
      for (var i in this.queryForm) {
        if (keyArr.includes(i) && (this.queryForm[i] == 'allSelect' || !this.queryForm[i] || this.queryForm[i] instanceof Array && this.queryForm[i].length == 0)) {
          obj[i] = '';
        } else {
          obj[i] = this.queryForm[i];
        }
      }
      obj['channel'] = obj['channel'].toString();
      if (!this.isAdmin) {
        delete obj['channel'];
      }
      return obj;
    },
    onReset: function onReset() {
      this.queryForm = {
        page: 1,
        limit: 10,
        userName: '',
        betId: '',
        roundId: '',
        beginTime: '',
        endTime: '',
        enterType: '',
        channel: '',
        CCYID: '',
        agentName: '',
        betPoint: '',
        clientType: '',
        groupTableId: '',
        //按桌台统计
        groupBetPoint: '',
        //按投注类型统计
        groupClientType: '',
        //按客户端类型统计
        groupDate: '',
        //按投注日期统计
        groupEnterType: '',
        //按下注方式统计
        gameName: 'BACCARAT'
      };
    },
    onOutput: function onOutput() {
      var _this = this;
      var loading = _elementUi.Loading.service({
        fullscreen: true
      });
      var params = this.parseParam(this.getParams());
      (0, _betReportList.output)(params).then(function (res) {
        if (res.code == 200) {
          var columns = _this.createColums();
          var data = _this.editData(res.data);
          (0, _downFiles.downFiles)(data, columns, 'betReport');
        } else {
          _this.$message.error(_this.$t(res.message));
        }
      }).catch(function (err) {}).finally(function () {
        _this.$nextTick(function () {
          loading.close();
        });
      });
    },
    editData: function editData(data) {
      var _this2 = this;
      if (!data) return [];
      var arr = [];
      data.forEach(function (item) {
        var model = _objectSpread({}, item);
        if (model.betPoint) model.betPoint = _this2.getBetPoint(model);
        if (model.clientType) model.clientType = _this2.setClientType(model.clientType);
        if (model.enterType) model.enterType = _this2.setBetType(model.enterType);
        if (model.status) model.status = _this2.getStatus(model.status);
        if (model.winPoint) model.winPoint = _this2.renderResult(_objectSpread({
          result: model.winPoint
        }, model));
        if (model.gamblingNum) model.gamblingNum = "".concat(model.shoeNum, "-").concat(model.gamblingNum);
        if (model.gameName) model.gameName = _this2.getGameName(model.gameName);
        if (model.currency) model.currency = _this2.renderCurrency(model.currency);
        if (model.tableNum) model.tableNum = _this2.getTableName(model.tableNum);
        arr.push(model);
      });
      return arr;
    },
    createColums: function createColums() {
      var admin = [{
        "label": this.$t('玩家账号'),
        "key": "userName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('代理'),
        "key": "agentName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('渠道'),
        "key": "companyName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('币种'),
        "key": "currency",
        "control_type": "TEXT"
      }, {
        "label": this.$t('台号'),
        "key": "tableNum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('投注编号'),
        "key": "betId",
        "control_type": "TEXT"
      }, {
        "label": this.$t('投注日期'),
        "key": "groupFor",
        "control_type": "TEXT"
      }, {
        "label": this.$t('投注时间'),
        "key": "betTime",
        "control_type": "TEXT"
      }, {
        "label": this.$t('派彩时间'),
        "key": "correctTime",
        "control_type": "TEXT"
      }, {
        "label": this.$t('游戏'),
        "key": "gameName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('局号'),
        "key": "roundId",
        "control_type": "TEXT"
      }, {
        "label": this.$t('靴/局数'),
        "key": "gamblingNum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('游戏结果'),
        "key": "winPoint",
        "control_type": "TEXT"
      }, {
        "label": this.$t('状态'),
        "key": "status",
        "control_type": "TEXT"
      }, {
        "label": this.$t('投注类型'),
        "key": "betPoint",
        "control_type": "TEXT"
      }, {
        "label": this.$t('投注金额'),
        "key": "amount",
        "control_type": "TEXT"
      }, {
        "label": this.$t('输赢'),
        "key": "pointResult",
        "control_type": "TEXT"
      }, {
        "label": this.$t('有效投注'),
        "key": "commAmount",
        "control_type": "TEXT"
      }, {
        "label": this.$t('客户端'),
        "key": "clientType",
        "control_type": "TEXT"
      }, {
        "label": this.$t('下注方式'),
        "key": "enterType",
        "control_type": "TEXT"
      }];
      return admin;
    },
    parseParam: function parseParam(param) {
      var paramStr = '';
      Object.keys(param).forEach(function (key, index) {
        if (index === 0) {
          paramStr += key + "=" + encodeURIComponent(param[key]);
        } else {
          paramStr += "&" + key + "=" + encodeURIComponent(param[key]);
        }
      });
      return paramStr;
    },
    toDecimal: function toDecimal(x) {
      var a = parseFloat(x);
      if (isNaN(a)) {
        return false;
      }
      var f = Math.round(x * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf('.');
      if (rs < 0) {
        rs = s.length;
        s += '.';
      }
      while (s.length <= rs + 2) {
        s += '0';
      }
      return s;
    },
    checkParams: function checkParams() {
      var _this$queryForm = this.queryForm,
        beginTime = _this$queryForm.beginTime,
        endTime = _this$queryForm.endTime;
      var beginTimeT = _lodash.default.cloneDeep(beginTime);
      var endTimeT = _lodash.default.cloneDeep(endTime);
      var timeDiff = new Date(endTimeT).getTime() - new Date(beginTimeT).getTime();
      var formatTimeDiff = timeDiff / (3600 * 1000 * 24);
      if (formatTimeDiff > 31) {
        this.$message.error(this.$t('时间范围只能在31天内'));
        return false;
      }
      return true;
    },
    getList: function getList() {
      var _this3 = this;
      if (!this.checkParams()) {
        return;
      }
      this.loading = true;
      var pa = this.getParams();
      var params = this.parseParam(pa);
      (0, _betReportList.getList)(params).then(function (res) {
        _this3.loading = false;
        if (res.code == 200) {
          var form = new FormData();
          for (var i in pa) {
            form.append(i, pa[i]);
          }
          _this3.getSumList(form);
          _this3.tableData = {
            list: res.data,
            total: res.count,
            totalPage: 1
          };
        } else {
          _this3.$message.error(_this3.$t(res.message));
          _this3.tableData = {
            list: [],
            total: 0,
            totalPage: 1
          };
        }
      }).catch(function (err) {
        _this3.loading = false;
        _this3.tableData = {
          list: [],
          total: 0,
          totalPage: 1
        };
      });
    },
    getSumList: function getSumList(params) {
      var _this4 = this;
      (0, _betReportList.getSumList)(params).then(function (res) {
        if (res.code == 200) {
          _this4.totalList = res.data;
        } else {
          _this4.$message.error(_this4.$t(res.message));
          _this4.totalList = [];
        }
      }).catch(function (err) {
        _this4.totalList = [];
      });
    },
    // 页码size 改变
    handleSizeChange: function handleSizeChange(size) {
      setTimeout(function () {
        window.scrollTo({
          left: 0,
          top: 0
        });
      }, 10);
      this.queryForm.limit = size;
      this.queryForm.page = 1;
      this.getList();
    },
    // 分页数据改变
    handleCurrentChange: function handleCurrentChange(val) {
      window.scrollTo({
        left: 0,
        top: 0
      });
      this.queryForm.page = val;
      this.getList();
    },
    getChannelList: function getChannelList() {
      var _this5 = this;
      (0, _common.getChannel)().then(function (res) {
        if (res.code == 200) {
          _this5.channelList = res.data;
        } else {
          _this5.$message.error(_this5.$t(res.message));
          _this5.channelList = [];
        }
      }).catch(function (err) {
        _this5.channelList = [];
      });
    },
    getCurrencyList: function getCurrencyList() {
      var _this6 = this;
      (0, _common.getCurrency)().then(function (res) {
        if (res.code == 200) {
          _this6.currencyList = res.data.map(function (val) {
            val.name = (0, _gameProperties.renderCurrency)(val.name);
            return val;
          });
        } else {
          _this6.$message.error(_this6.$t(res.message));
          _this6.currencyList = [];
        }
      }).catch(function (err) {
        _this6.currencyList = [];
      });
    },
    setBetType: function setBetType(type) {
      var tyObject = {
        '1': this.$t('大厅'),
        '2': this.$t('换桌'),
        '3': this.$t('多台'),
        '4': this.$t('桌台内')
      };
      return tyObject[type] ? tyObject[type] : '';
    },
    setClientType: function setClientType(type) {
      var tyObject = {
        '0': this.$t('白鹭PC'),
        '1': this.$t('白鹭H5'),
        '2': this.$t('cocos-pc'),
        '3': this.$t('cocos-h5')
      };
      return tyObject[type] ? tyObject[type] : '';
    },
    // 合计 
    getTotal: function getTotal(param) {
      var sumList = ['betAmount', 'validbetamount', 'money'];
      var columns = param.columns,
        data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        var values = data.map(function (item) {
          var defaultItem = item[column.property] ? "".concat(item[column.property]) : '0';
          var str = defaultItem.replace(/,/g, "");
          return Number(str);
        });
        if (sumList.includes(column.property)) {
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);
            if (!isNaN(value)) {
              return (0, _big.addBigNumbers)(prev, curr);
            } else {
              return prev;
            }
          }, 0);
          sums[index] = sums[index];
        } else {
          sums[index] = '';
        }
      });
      sums[0] = this.$t('合计');
      var sumsTotal = sums.map(function (item) {
        return (0, _big.formatNumber)(item);
      });
      return sumsTotal;
    },
    gameChange: function gameChange() {
      this.queryForm.betPoint = '';
    }
  }
};