"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
var _userAssociatedIP = require("@/api/report/userAssociatedIP");
var _lodash = _interopRequireDefault(require("lodash"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var _this;
var _default = exports.default = {
  name: 'userAssociatedIP',
  props: {},
  data: function data() {
    return {
      accountForm: {
        uName: '',
        betLimit: [],
        limit: []
      },
      dialog: false,
      loading: false,
      tableData: {
        list: [],
        total: 0,
        totalPage: 1
      },
      queryForm: {
        page: 1,
        limit: 10,
        userName: '',
        beginTime: '',
        endTime: ''
      }
    };
  },
  beforeCreate: function beforeCreate() {
    _this = this;
  },
  mounted: function mounted() {},
  methods: {
    onSearch: function onSearch() {
      this.queryForm.page = 1;
      this.getList();
    },
    handleOpenPage: function handleOpenPage(row) {
      this.$router.push({
        name: 'gameRecord',
        params: {
          userName: row.userName
        }
      });
    },
    checkParams: function checkParams() {
      if (!this.queryForm.userName) {
        this.$message.error(this.$t('账户名必填'));
        return false;
      }
      if (!this.queryForm.beginTime) {
        this.$message.error(this.$t('开始时间必填'));
        return false;
      }
      if (!this.queryForm.endTime) {
        this.$message.error(this.$t('结束时间必填'));
        return false;
      }
      var beginTime = _lodash.default.cloneDeep(this.queryForm.beginTime);
      var endTime = _lodash.default.cloneDeep(this.queryForm.endTime);
      var timeDiff = new Date(endTime).getTime() - new Date(beginTime).getTime();
      var formatTimeDiff = timeDiff / (3600 * 1000 * 24);
      if (formatTimeDiff > 14) {
        this.$message.error(this.$t('时间范围只能在14天内'));
        return false;
      }
      return true;
    },
    parseParam: function parseParam(param) {
      var paramStr = '';
      Object.keys(param).forEach(function (key, index) {
        if (index === 0) {
          paramStr += key + "=" + encodeURIComponent(param[key]);
        } else {
          paramStr += "&" + key + "=" + encodeURIComponent(param[key]);
        }
      });
      return paramStr;
    },
    getList: function getList() {
      var _this2 = this;
      if (!this.checkParams()) {
        return;
      }
      this.loading = true;
      var params = this.parseParam(this.queryForm);
      (0, _userAssociatedIP.getList)(params).then(function (res) {
        _this2.loading = false;
        if (res.code == 200) {
          _this2.tableData = {
            list: res.data,
            total: res.count,
            totalPage: 1
          };
        } else {
          _this2.$message.error(_this2.$t(res.message));
          _this2.tableData = {
            list: [],
            total: 0,
            totalPage: 1
          };
        }
      }).catch(function (err) {
        _this2.loading = false;
        _this2.tableData = {
          list: [],
          total: 0,
          totalPage: 1
        };
        ;
      });
    },
    // 页码size 改变
    handleSizeChange: function handleSizeChange(size) {
      setTimeout(function () {
        window.scrollTo({
          left: 0,
          top: 0
        });
      }, 10);
      this.queryForm.limit = size;
      this.queryForm.page = 1;
      this.getList();
    },
    // 分页数据改变
    handleCurrentChange: function handleCurrentChange(val) {
      window.scrollTo({
        left: 0,
        top: 0
      });
      this.queryForm.page = val;
      this.getList();
    }
  }
};