"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/es7.array.includes");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _playerBettingAnalysis = require("@/api/report/playerBettingAnalysis");
var _transferData = require("@/utils/transferData");
var _gameProperties = require("@/utils/gameProperties");
var _echarts = _interopRequireDefault(require("echarts"));
var _downFiles = require("@/utils/downFiles");
var _elementUi = require("element-ui");
var _lodash = _interopRequireDefault(require("lodash"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _this;
var _default = exports.default = {
  name: 'playerBettingAnalysis',
  props: {},
  data: function data() {
    return {
      tranferOp: _transferData.tranferOp,
      analysisList: [{
        key: 'gameNumSum',
        label: '总投注局数'
      }, {
        key: 'orderNumSum',
        label: '总注单数'
      }, {
        key: 'amountSum',
        label: '总投注金额'
      }, {
        key: 'resultSum',
        label: '总输赢'
      }, {
        key: 'currency',
        label: '货币'
      }],
      sameGamerOp: [{
        label: '10%',
        value: 0.1
      }, {
        label: '20%',
        value: 0.2
      }, {
        label: '30%',
        value: 0.3
      }, {
        label: '40%',
        value: 0.4
      }, {
        label: '50%',
        value: 0.5
      }, {
        label: '60%',
        value: 0.6
      }, {
        label: '70%',
        value: 0.7
      }, {
        label: '80%',
        value: 0.8
      }, {
        label: '90%',
        value: 0.9
      }, {
        label: '100%',
        value: 1
      }],
      isCol: true,
      multipleSelection: [],
      analysisDataInfor: {},
      inforForm: {
        amount: '',
        currency: '',
        limitList: '',
        betAmount: '',
        videoBetAmount: '',
        resultAmount: '',
        videoResultAmount: '',
        registerDate: '',
        lastLoginDate: '',
        lastBetDate: ''
      },
      accountForm: {
        uName: '',
        betLimit: [],
        limit: []
      },
      dialog: false,
      detailDialog: false,
      loading: false,
      tableData: {
        list: [],
        total: 0,
        totalPage: 1
      },
      subTableData: {
        list: [],
        total: 0,
        totalPage: 1
      },
      subTable: {
        page: 1,
        limit: 10
      },
      queryGmaerForm: {
        page: 1,
        limit: 10,
        startTime: '',
        endTime: '',
        userName: '',
        gameType: '',
        gameNumSum: '',
        amountSum: '',
        betPercent: '',
        amountPercent: ''
      },
      queryForm: {
        startTime: '',
        endTime: '',
        userName: '',
        gameType: 1
      },
      isAnalyze: false
    };
  },
  computed: {
    averageAmount: function averageAmount() {
      var number = (this.analysisDataInfor.bankerAmount + this.analysisDataInfor.playerAmount) / (this.analysisDataInfor.bankerCount + this.analysisDataInfor.playerCount);
      return number ? number.toFixed(2) : '';
    }
  },
  beforeCreate: function beforeCreate() {
    _this = this;
  },
  mounted: function mounted() {},
  methods: {
    getZhTableName: _gameProperties.getZhTableName,
    renderZhResult: _gameProperties.renderZhResult,
    renderZhPlay: _gameProperties.renderZhPlay,
    handleClose: function handleClose(done) {
      this.subTableData = {
        list: [],
        total: 0,
        totalPage: 1
      };
      done();
    },
    getOrderDetail: function getOrderDetail() {
      var _this2 = this;
      var arr = this.multipleSelection.map(function (val) {
        return val.userId;
      });
      var params = this.parseParam(this.getParams(_objectSpread(_objectSpread(_objectSpread({}, this.queryForm), this.subTable), {}, {
        hedgeUserIdStr: arr.join(',')
      })));
      (0, _playerBettingAnalysis.getOrderDetail)(params).then(function (res) {
        if (res.code == 200) {
          _this2.subTableData = {
            list: res.data,
            total: res.count,
            totalPage: 1
          };
        } else {
          _this2.$message.error(_this2.$t(res.message));
          _this2.subTableData = {
            list: [],
            total: 0,
            totalPage: 1
          };
        }
      }).catch(function (err) {}).finally(function () {});
    },
    onShowDetail: function onShowDetail(row) {
      this.getUserDetail(row);
      this.detailDialog = true;
    },
    getUserDetail: function getUserDetail(row) {
      var _this3 = this;
      var params = this.parseParam(this.getParams({
        userId: row.userId,
        userName: row.userName,
        type: 2
      }));
      (0, _playerBettingAnalysis.getUserDetail)(params).then(function (res) {
        if (res.code == 200) {
          var obj = res.data;
          obj.limitList = obj.limitList.join('; ');
          _this3.inforForm = obj;
        } else {
          _this3.$message.error(_this3.$t(res.message));
        }
      }).catch(function (err) {}).finally(function () {});
    },
    onGamerSearch: function onGamerSearch() {
      if (this.isAnalyze) {
        this.getList();
      } else {
        this.$message.error(this.$t('请先分析玩家信息再检索'));
      }
    },
    onGetAnalyData: function onGetAnalyData(row) {
      var _this4 = this;
      var params = this.parseParam(this.getParams(_objectSpread(_objectSpread({}, this.queryForm), {}, {
        hedgeUserId: row.userId
      })));
      (0, _playerBettingAnalysis.hedging)(params).then(function (res) {
        if (res.code == 200) {
          _this4.tableData.list.forEach(function (val, index) {
            var obj = val;
            if (obj.userId == row.userId) {
              _this4.tableData.list[index] = Object.assign({}, val, res.data);
            }
          });
          _this4.isCol = false;
          _this4.$nextTick(function () {
            _this4.isCol = true;
          });
        } else {
          _this4.$message.error(_this4.$t(res.message));
        }
      }).catch(function (err) {}).finally(function () {});
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    onSearch: function onSearch() {
      this.isAnalyze = true;
      this.getAnalysisData();
    },
    onWatchOrder: function onWatchOrder() {
      if (this.multipleSelection.length === 0) {
        this.$message.error(this.$t('请选择一个'));
        return;
      }
      this.getOrderDetail();
      this.dialog = true;
    },
    getParams: function getParams(data) {
      var obj = {};
      var keyArr = ['startTime', 'endTime'];
      for (var i in data) {
        if (keyArr.includes(i) && (data[i] instanceof String && !data[i] || data[i] instanceof Array && data[i].length == 0)) {
          obj[i] = '';
        } else {
          obj[i] = data[i];
        }
      }
      return obj;
    },
    parseParam: function parseParam(param) {
      var paramStr = '';
      Object.keys(param).forEach(function (key, index) {
        if (index === 0) {
          paramStr += key + "=" + encodeURIComponent(param[key]);
        } else {
          paramStr += "&" + key + "=" + encodeURIComponent(param[key]);
        }
      });
      return paramStr;
    },
    onOutput: function onOutput() {
      var _this5 = this;
      if (!this.checkParams()) {
        return;
      }
      if (!this.queryGmaerForm.betPercent) {
        this.$message.error(this.$t('同局投注局数比例必填'));
        return;
      }
      if (!this.queryGmaerForm.amountPercent) {
        this.$message.error(this.$t('同局投注总金额比例必填'));
        return;
      }
      var loading = _elementUi.Loading.service({
        fullscreen: true
      });
      var params = this.parseParam(this.getParams(_objectSpread(_objectSpread({}, this.queryGmaerForm), this.queryForm)));
      (0, _playerBettingAnalysis.output)(params).then(function (res) {
        if (res.code == 200) {
          var columns = _this5.createColums();
          (0, _downFiles.downFiles)(res.data, columns, 'playerBettingAnalysis');
        } else {
          _this5.$message.error(_this5.$t(res.message));
        }
      }).catch(function (err) {}).finally(function () {
        _this5.$nextTick(function () {
          loading.close();
        });
      });
    },
    createColums: function createColums() {
      var admin = [{
        "label": this.$t('代理'),
        "key": "agentName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('玩家账号'),
        "key": "userName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('玩家总投注局数'),
        "key": "playerGameNumSum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('玩家总投注金额'),
        "key": "playerAmountSum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('同局投注局数'),
        "key": "sameGameSum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('同局比例'),
        "key": "sameGamePercent",
        "control_type": "TEXT"
      }, {
        "label": this.$t('同局投注总金额'),
        "key": "sameAmountSum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('同局投注总金额比例'),
        "key": "sameAmountPercent",
        "control_type": "TEXT"
      }, {
        "label": this.$t('对冲局数'),
        "key": "hedgingGameSum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('非对冲局数'),
        "key": "unHedgingGameSum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('对冲金额'),
        "key": "hedgingAmountSum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('非对冲金额'),
        "key": "unHedgingAmountSum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('货币'),
        "key": "currency",
        "control_type": "TEXT"
      }];
      return admin;
    },
    getAnalysisData: function getAnalysisData() {
      var _this6 = this;
      if (!this.checkParams()) {
        return;
      }
      var params = _objectSpread({}, this.queryForm);
      (0, _playerBettingAnalysis.getAnalysis)(params).then(function (res) {
        if (res.code == 200) {
          _this6.analysisDataInfor = res.data;
          _this6.queryGmaerForm.gameNumSum = res.data.gameNumSum;
          _this6.queryGmaerForm.amountSum = res.data.amountSum;
          _this6.initChart();
          _this6.setColum(res.data);
        } else {
          _this6.$message.error(_this6.$t(res.message));
          _this6.analysisDataInfor = {};
        }
      }).catch(function (err) {
        _this6.analysisDataInfor = {};
        ;
      });
    },
    checkParams: function checkParams() {
      if (!this.queryForm.startTime) {
        this.$message.error(this.$t('开始时间必填'));
        return;
      }
      if (!this.queryForm.endTime) {
        this.$message.error(this.$t('结束时间必填'));
        return;
      }
      if (!this.queryForm.userName) {
        this.$message.error(this.$t('用户名不能为空'));
        return;
      }
      var beginTime = _lodash.default.cloneDeep(this.queryForm.startTime);
      var endTime = _lodash.default.cloneDeep(this.queryForm.endTime);
      var timeDiff = new Date(endTime).getTime() - new Date(beginTime).getTime();
      var formatTimeDiff = timeDiff / (3600 * 1000 * 24);
      if (formatTimeDiff > 31) {
        this.$message.error(this.$t('时间范围只能在31天内'));
        return false;
      }
      return true;
    },
    getList: function getList() {
      var _this7 = this;
      if (!this.checkParams()) {
        return;
      }
      if (!this.queryGmaerForm.betPercent) {
        this.$message.error(this.$t('同局投注局数比例必填'));
        return;
      }
      if (!this.queryGmaerForm.amountPercent) {
        this.$message.error(this.$t('同局投注总金额比例必填'));
        return;
      }
      this.loading = true;
      var params = this.parseParam(this.getParams(_objectSpread(_objectSpread({}, this.queryGmaerForm), this.queryForm)));
      (0, _playerBettingAnalysis.getList)(params).then(function (res) {
        _this7.loading = false;
        if (res.code == 200) {
          _this7.tableData = {
            list: res.data,
            total: res.count,
            totalPage: 1
          };
        } else {
          _this7.$message.error(_this7.$t(res.message));
          _this7.tableData = {
            list: [],
            total: 0,
            totalPage: 1
          };
        }
      }).catch(function (err) {
        _this7.loading = false;
        _this7.tableData = {
          list: [],
          total: 0,
          totalPage: 1
        };
        ;
      });
    },
    // 页码size 改变
    handleSizeChange: function handleSizeChange(size) {
      setTimeout(function () {
        window.scrollTo({
          left: 0,
          top: 0
        });
      }, 10);
      this.queryGmaerForm.limit = size;
      this.queryGmaerForm.page = 1;
      this.getList();
    },
    // 分页数据改变
    handleCurrentChange: function handleCurrentChange(val) {
      window.scrollTo({
        left: 0,
        top: 0
      });
      this.queryGmaerForm.page = val;
      this.getList();
    },
    // 页码size 改变
    handleSubSizeChange: function handleSubSizeChange(size) {
      setTimeout(function () {
        window.scrollTo({
          left: 0,
          top: 0
        });
      }, 10);
      this.subTable.limit = size;
      this.subTable.page = 1;
      this.getOrderDetail();
    },
    // 分页数据改变
    handleSubCurrentChange: function handleSubCurrentChange(val) {
      window.scrollTo({
        left: 0,
        top: 0
      });
      this.subTable.page = val;
      this.getOrderDetail();
    },
    initChart: function initChart() {
      var _this$analysisDataInf = this.analysisDataInfor,
        bankerCount = _this$analysisDataInf.bankerCount,
        playerCount = _this$analysisDataInf.playerCount,
        otherCount = _this$analysisDataInf.otherCount,
        bankerAmount = _this$analysisDataInf.bankerAmount,
        playerAmount = _this$analysisDataInf.playerAmount,
        otherAmount = _this$analysisDataInf.otherAmount;
      var zhudanbili = _echarts.default.init(document.getElementById('zhudanbili'));
      zhudanbili.setOption(this.chartOpt(this.$t('注单比例'), bankerCount, playerCount, otherCount));
      var touzhujinebili = _echarts.default.init(document.getElementById('touzhujinebili'));
      touzhujinebili.setOption(this.chartOpt(this.$t('投注金额比例'), bankerAmount, playerAmount, otherAmount));
    },
    chartOpt: function chartOpt(name, a, b, c) {
      return {
        color: ['#df0e24', '#130ddf', '#2f8b26'],
        title: {
          text: name + ':',
          subtext: '',
          left: 'left'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          bottom: 10
        },
        series: [{
          name: name,
          type: 'pie',
          radius: '50%',
          data: [{
            value: a,
            name: this.$t('庄') + a
          }, {
            value: b,
            name: this.$t('闲') + b
          }, {
            value: c,
            name: this.$t('其他') + c
          }],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }, {
          name: name,
          type: 'pie',
          radius: '50%',
          label: {
            //饼图图形上的文本标签
            normal: {
              show: true,
              position: 'inner',
              //标签的位置
              textStyle: {
                fontWeight: 300,
                fontSize: 16 //文字的字体大小
              },
              formatter: '{d}%'
            }
          },
          data: [{
            value: a,
            name: this.$t('庄') + a
          }, {
            value: b,
            name: this.$t('闲') + b
          }, {
            value: c,
            name: this.$t('其他') + c
          }],
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      };
    },
    setColum: function setColum(res) {
      //第一个横条
      var widthwin1 = res.bpWinCount && res.bpWinCount + res.bpLoseCount ? res.bpWinCount / (res.bpWinCount + res.bpLoseCount) * 100 : 0;
      var widthlose1 = res.bpLoseCount && res.bpWinCount + res.bpLoseCount ? res.bpLoseCount / (res.bpWinCount + res.bpLoseCount) * 100 : 0;
      var c_content_win1 = document.getElementsByClassName('c_content_win1')[0];
      c_content_win1.style.width = widthwin1 == 0 ? '50%' : widthwin1 + '%';
      c_content_win1.innerHTML = widthwin1.toFixed(2) + '%';
      var c_content_lose1 = document.getElementsByClassName('c_content_lose1')[0];
      c_content_lose1.style.width = widthlose1 == 0 ? '50%' : widthlose1 + '%';
      c_content_lose1.innerHTML = widthlose1.toFixed(2) + '%';
      document.getElementsByClassName('winvaule1')[0].innerHTML = res.bpWinCount;
      document.getElementsByClassName('losevaule1')[0].innerHTML = res.bpLoseCount;
      document.getElementsByClassName('totalred1')[0].innerHTML = res.bpWinCount + res.bpLoseCount;

      //第二个横条
      var bpWinAvg2 = res.bpWinAmount && res.bpWinCount ? res.bpWinAmount / res.bpWinCount : 0;
      var bpLostAvg2 = res.bpLoseAmount && res.bpLoseCount ? res.bpLoseAmount / res.bpLoseCount : 0;
      var bpTotal2 = bpWinAvg2 + Math.abs(bpLostAvg2);
      var widthwin2 = bpWinAvg2 && bpTotal2 ? bpWinAvg2 / bpTotal2 * 100 : 0;
      var widthlose2 = bpLostAvg2 && bpTotal2 ? Math.abs(bpLostAvg2) / bpTotal2 * 100 : 0;
      var c_content_win2 = document.getElementsByClassName('c_content_win2')[0];
      c_content_win2.style.width = widthwin2 == 0 ? '50%' : widthwin2 + '%';
      c_content_win2.style.innerHTML = widthwin2.toFixed(2) + '%';
      var c_content_lose2 = document.getElementsByClassName('c_content_lose2')[0];
      c_content_lose2.style.width = widthlose2 == 0 ? '50%' : widthlose2 + '%';
      c_content_lose2.style.innerHTML = widthlose2.toFixed(2) + '%';
      document.getElementsByClassName('winvaule2')[0].innerHTML = bpWinAvg2.toFixed(2);
      document.getElementsByClassName('losevaule2')[0].innerHTML = bpLostAvg2.toFixed(2);

      //第三个横条
      var bpTotal3 = res.bpWinAmount + Math.abs(res.bpLoseAmount);
      var widthwin3 = res.bpWinAmount && bpTotal3 ? res.bpWinAmount / bpTotal3 * 100 : 0;
      var widthlose3 = res.bpLoseAmount && bpTotal3 ? Math.abs(res.bpLoseAmount) / bpTotal3 * 100 : 0;
      var c_content_win3 = document.getElementsByClassName('c_content_win3')[0];
      c_content_win3.style.width = widthwin3 == 0 ? '50%' : widthwin3 + '%';
      c_content_win3.style.innerHTML = widthwin3.toFixed(2) + '%';
      var c_content_lose3 = document.getElementsByClassName('c_content_lose3')[0];
      c_content_lose3.style.width = widthlose3 == 0 ? '50%' : widthlose3 + '%';
      c_content_lose3.style.innerHTML = widthlose3.toFixed(2) + '%';
      document.getElementsByClassName('winvaule3')[0].innerHTML = res.bpWinAmount;
      document.getElementsByClassName('losevaule3')[0].innerHTML = res.bpLoseAmount;
      document.getElementsByClassName('totalred3')[0].innerHTML = res.bpWinAmount + res.bpLoseAmount;

      //第四个横条
      var widthwin4 = res.otherWinCount && res.otherWinCount + res.otherLoseCount ? res.otherWinCount / (res.otherWinCount + res.otherLoseCount) * 100 : 0;
      var widthlose4 = res.otherLoseCount && res.otherWinCount + res.otherLoseCount ? res.otherLoseCount / (res.otherWinCount + res.otherLoseCount) * 100 : 0;
      var c_content_win4 = document.getElementsByClassName('c_content_win4')[0];
      c_content_win4.style.width = widthwin4 == 0 ? '50%' : widthwin4 + '%';
      c_content_win4.style.innerHTML = widthwin4.toFixed(2) + '%';
      var c_content_lose4 = document.getElementsByClassName('c_content_lose4')[0];
      c_content_lose4.style.width = widthlose4 == 0 ? '50%' : widthlose4 + '%';
      c_content_lose4.style.innerHTML = widthlose4.toFixed(2) + '%';
      document.getElementsByClassName('winvaule4')[0].innerHTML = res.otherWinCount;
      document.getElementsByClassName('losevaule4')[0].innerHTML = res.otherLoseCount;
      document.getElementsByClassName('totalred4')[0].innerHTML = res.otherWinCount + res.otherLoseCount;

      //第五个横条
      var otherWinAvg5 = res.otherWinAmount && res.otherWinCount ? res.otherWinAmount / res.otherWinCount : 0;
      var otherLostAvg5 = res.otherLoseAmount && res.otherLoseCount ? Math.abs(res.otherLoseAmount) / res.otherLoseCount : 0;
      var otherTotal5 = otherWinAvg5 + otherLostAvg5;
      var widthwin5 = otherWinAvg5 && otherTotal5 ? otherWinAvg5 / otherTotal5 * 100 : 0;
      var widthlose5 = otherWinAvg5 && otherTotal5 ? otherLostAvg5 / otherTotal5 * 100 : 0;
      var c_content_win5 = document.getElementsByClassName('c_content_win5')[0];
      c_content_win5.style.width = widthwin5 == 0 ? '50%' : widthwin5 + '%';
      c_content_win5.style.innerHTML = widthwin5.toFixed(2) + '%';
      var c_content_lose5 = document.getElementsByClassName('c_content_lose5')[0];
      c_content_lose5.style.width = widthlose5 == 0 ? '50%' : widthlose5 + '%';
      c_content_lose5.style.innerHTML = widthlose5.toFixed(2) + '%';
      document.getElementsByClassName('winvaule5')[0].innerHTML = otherWinAvg5.toFixed(2);
      document.getElementsByClassName('losevaule5')[0].innerHTML = res.otherLoseAmount && res.otherLoseCount ? (res.otherLoseAmount / res.otherLoseCount).toFixed(2) : 0;

      //第六个横条
      var otherTotal6 = res.otherWinAmount + Math.abs(res.otherLoseAmount);
      var widthwin6 = res.otherWinAmount && otherTotal6 ? res.otherWinAmount / otherTotal6 * 100 : 0;
      var widthlose6 = res.otherLoseAmount && otherTotal6 ? Math.abs(res.otherLoseAmount) / otherTotal6 * 100 : 0;
      var c_content_win6 = document.getElementsByClassName('c_content_win6')[0];
      c_content_win6.style.width = widthwin6 == 0 ? '50%' : widthwin6 + '%';
      c_content_win6.style.innerHTML = widthwin6.toFixed(2) + '%';
      var c_content_lose6 = document.getElementsByClassName('c_content_lose6')[0];
      c_content_lose6.style.width = widthlose6 == 0 ? '50%' : widthlose6 + '%';
      c_content_lose6.style.innerHTML = widthlose6.toFixed(2) + '%';
      document.getElementsByClassName('winvaule6')[0].innerHTML = res.otherWinAmount;
      document.getElementsByClassName('losevaule6')[0].innerHTML = res.otherLoseAmount;
      document.getElementsByClassName('totalred6')[0].innerHTML = res.otherWinAmount + res.otherLoseAmount;
    }
  }
};