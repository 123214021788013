"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.array.includes");
var _sameIPUsers = require("@/api/report/sameIPUsers");
var _downFiles = require("@/utils/downFiles");
var _elementUi = require("element-ui");
var _lodash = _interopRequireDefault(require("lodash"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'sameIPUsers',
  props: {},
  data: function data() {
    return {
      accountForm: {
        uName: '',
        betLimit: [],
        limit: []
      },
      dialog: false,
      loading: false,
      tableData: {
        list: [],
        total: 0,
        totalPage: 1
      },
      queryForm: {
        page: 1,
        limit: 10,
        beginTime: '',
        endTime: '',
        ip: ''
      }
    };
  },
  beforeCreate: function beforeCreate() {},
  mounted: function mounted() {
    this.queryForm.page = 1;
    this.queryForm.beginTime = this.formatTime(new Date(new Date().getTime() - 24 * 60 * 60 * 1000), 'Y-M-D');
    this.queryForm.endTime = this.formatTime(new Date(), 'Y-M-D');
    this.getList();
  },
  methods: {
    onOutput: function onOutput() {
      var _this = this;
      var loading = _elementUi.Loading.service({
        fullscreen: true
      });
      var params = this.parseParam(this.getParams(this.queryForm));
      (0, _sameIPUsers.output)(params).then(function (res) {
        if (res.code == 200) {
          var columns = _this.createColums();
          (0, _downFiles.downFiles)(res.data, columns, 'sameIPUsers');
        } else {
          _this.$message.error(_this.$t(res.message));
        }
      }).catch(function (err) {}).finally(function () {
        _this.$nextTick(function () {
          loading.close();
        });
      });
    },
    createColums: function createColums() {
      var admin = [{
        "label": this.$t('日期'),
        "key": "groupfor",
        "control_type": "TEXT"
      }, {
        "label": 'IP',
        "key": "ip",
        "control_type": "TEXT"
      }, {
        "label": this.$t('用户名'),
        "key": "username",
        "control_type": "TEXT"
      }];
      return admin;
    },
    checkParams: function checkParams() {
      if (!this.queryForm.beginTime || !this.queryForm.endTime) {
        this.$message.error(this.$t('日期必填'));
        return false;
      }
      var beginTime = _lodash.default.cloneDeep(this.queryForm.beginTime);
      var endTime = _lodash.default.cloneDeep(this.queryForm.endTime);
      var timeDiff = new Date(endTime).getTime() - new Date(beginTime).getTime();
      var formatTimeDiff = timeDiff / (3600 * 1000 * 24);
      if (formatTimeDiff > 31) {
        this.$message.error(this.$t('时间范围只能在31天内'));
        return false;
      }
      return true;
    },
    onSearch: function onSearch() {
      this.queryForm.page = 1;
      this.getList();
    },
    getParams: function getParams(data) {
      var obj = {};
      var keyArr = ['beginTime', 'endTime'];
      for (var i in data) {
        if (keyArr.includes(i) && (data[i] instanceof String && !data[i] || data[i] instanceof Array && data[i].length == 0)) {
          obj[i] = '';
        } else {
          obj[i] = data[i];
        }
      }
      return obj;
    },
    getList: function getList() {
      var _this2 = this;
      if (!this.checkParams()) {
        return;
      }
      this.loading = true;
      var params = this.parseParam(this.getParams(this.queryForm));
      (0, _sameIPUsers.getList)(params).then(function (res) {
        _this2.loading = false;
        if (res.code == 200) {
          _this2.tableData = {
            list: res.data,
            total: res.count,
            totalPage: 1
          };
        } else {
          _this2.$message.error(_this2.$t(res.message));
          _this2.tableData = {
            list: [],
            total: 0,
            totalPage: 1
          };
        }
      }).catch(function (err) {
        _this2.loading = false;
        _this2.tableData = {
          list: [],
          total: 0,
          totalPage: 1
        };
        ;
      });
    },
    formatNumber: function formatNumber(n) {
      n = n.toString();
      return n[1] ? n : '0' + n;
    },
    formatTime: function formatTime(time, format) {
      if (!time) {
        return;
      }
      var newArr = [];
      var formatArr = ['Y', 'M', 'D', 'h', 'm', 's'];
      newArr.push(time.getFullYear());
      newArr.push(this.formatNumber(time.getMonth() + 1));
      newArr.push(this.formatNumber(time.getDate()));
      newArr.push(this.formatNumber(time.getHours()));
      newArr.push(this.formatNumber(time.getMinutes()));
      newArr.push(this.formatNumber(time.getSeconds()));
      for (var i in newArr) {
        format = format.replace(formatArr[i], newArr[i]);
      }
      return format;
    },
    parseParam: function parseParam(param) {
      var paramStr = '';
      Object.keys(param).forEach(function (key, index) {
        if (index === 0) {
          paramStr += key + "=" + encodeURIComponent(param[key]);
        } else {
          paramStr += "&" + key + "=" + encodeURIComponent(param[key]);
        }
      });
      return paramStr;
    },
    // 页码size 改变
    handleSizeChange: function handleSizeChange(size) {
      setTimeout(function () {
        window.scrollTo({
          left: 0,
          top: 0
        });
      }, 10);
      this.queryForm.limit = size;
      this.queryForm.page = 1;
      this.getList();
    },
    // 分页数据改变
    handleCurrentChange: function handleCurrentChange(val) {
      window.scrollTo({
        left: 0,
        top: 0
      });
      this.queryForm.page = val;
      this.getList();
    }
  }
};