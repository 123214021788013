"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.logOut = exports.default = void 0;
var _login = require("@/api/login");
var _auth = require("@/utils/auth");
var user = {
  state: {
    token: (0, _auth.getToken)(),
    roles: [],
    userInfo: {},
    // 第一次加载菜单时用到
    loadMenus: false
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER: function SET_USER(state, user) {
      state.user = user;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_LOAD_MENUS: function SET_LOAD_MENUS(state, loadMenus) {
      state.loadMenus = loadMenus;
    },
    SET_USER_INFO: function SET_USER_INFO(state, info) {
      state.userInfo = info; // 用户信息
    }
  },
  actions: {
    // 登录
    Login: function Login(_ref, userInfo) {
      var commit = _ref.commit;
      return new Promise(function (resolve, reject) {
        (0, _login.login)(userInfo.username, userInfo.password, userInfo.verifyCode).then(function (res) {
          if (res.code == 200) {
            var token = userInfo.username + '-' + userInfo.password;
            (0, _auth.setToken)(token, true);
            commit('SET_TOKEN', token);
            resolve(res);
          } else {
            reject(res);
          }
        }).catch(function (error) {
          reject(error);
        });
      });
    },
    // 登出
    LogOut: function LogOut(_ref2) {
      var commit = _ref2.commit;
      return new Promise(function (resolve, reject) {
        (0, _login.logout)().then(function (res) {
          localStorage.removeItem('vuex');
          logOut(commit);
          resolve();
        }).catch(function (error) {
          logOut(commit);
          reject(error);
        });
      });
    },
    // 登录失效
    LoginInvalid: function LoginInvalid(_ref3) {
      var commit = _ref3.commit;
      localStorage.removeItem('vuex');
      commit('SET_TOKEN', '');
      (0, _auth.removeToken)();
    },
    setUserInfo: function setUserInfo(_ref4, info) {
      var commit = _ref4.commit;
      commit('SET_USER_INFO', info);
    }
  }
};
var logOut = exports.logOut = function logOut(commit) {
  commit('SET_TOKEN', '');
  commit('SET_ROLES', []);
  (0, _auth.removeToken)();
};
var _default = exports.default = user;