"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vue = _interopRequireDefault(require("vue"));
var _vueI18n = _interopRequireDefault(require("vue-i18n"));
var _en = _interopRequireDefault(require("element-ui/lib/locale/lang/en"));
var _zhCN = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-CN"));
var _zhTW = _interopRequireDefault(require("element-ui/lib/locale/lang/zh-TW"));
var _th = _interopRequireDefault(require("element-ui/lib/locale/lang/th"));
var _vi = _interopRequireDefault(require("element-ui/lib/locale/lang/vi"));
var _ko = _interopRequireDefault(require("element-ui/lib/locale/lang/ko"));
var _en2 = _interopRequireDefault(require("./en"));
var _zh = _interopRequireDefault(require("./zh"));
var _tw = _interopRequireDefault(require("./tw"));
var _th2 = _interopRequireDefault(require("./th"));
var _vi2 = _interopRequireDefault(require("./vi"));
var _ko2 = _interopRequireDefault(require("./ko"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } // element-ui lang
// element-ui lang
// element-ui lang
// element-ui lang
// element-ui lang
// element-ui lang
_ko.default.el.pagination.pagesize = '/페이지'; // 重写翻译

_vue.default.use(_vueI18n.default);
var messages = {
  en: _objectSpread(_objectSpread({}, _en.default), _en2.default),
  zh: _objectSpread(_objectSpread({}, _zhCN.default), _zh.default),
  tw: _objectSpread(_objectSpread({}, _zhTW.default), _tw.default),
  th: _objectSpread(_objectSpread({}, _th.default), _th2.default),
  vi: _objectSpread(_objectSpread({}, _vi.default), _vi2.default),
  ko: _objectSpread(_objectSpread({}, _ko.default), _ko2.default)
};
var langType = localStorage.getItem('lang') || 'zh';
localStorage.setItem('lang', langType);
var i18n = new _vueI18n.default({
  locale: langType,
  // set locale
  messages: messages // set locale messages
});
var _default = exports.default = i18n;