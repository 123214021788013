"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var getters = {
  userInfo: function userInfo(state) {
    return state.user.userInfo;
  },
  permission_routers: function permission_routers(state) {
    return state.permission.routers;
  },
  purview: function purview(state) {
    return state.permission.purview;
  },
  isAdmin: function isAdmin(state) {
    return state.permission.isAdmin;
  },
  tab_list: function tab_list(state) {
    return state.tabList.list;
  },
  langType: function langType(state) {
    return state.langType.lang;
  }
};
var _default = exports.default = getters;