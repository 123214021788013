"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.showLoading = exports.hideLoading = void 0;
var _elementUi = require("element-ui");
var _lang = _interopRequireDefault(require("../lang"));
var _vue = _interopRequireDefault(require("vue"));
var needLoadingRequestCount = 0;
var loading;
var startLoading = function startLoading() {
  loading = _elementUi.Loading.service({
    lock: true,
    text: _lang.default.tc('拼命加载中') + '...',
    spinner: 'el-icon-loading',
    background: 'rgba(255, 255, 255, .6)'
  });
};
function endLoading() {
  _vue.default.nextTick(function () {
    loading.close();
  });
}
var showLoading = exports.showLoading = function showLoading() {
  if (needLoadingRequestCount === 0) {
    startLoading();
  }
  needLoadingRequestCount++;
};
var hideLoading = exports.hideLoading = function hideLoading() {
  setTimeout(tryCloseLoading, 300);
};
function tryCloseLoading() {
  if (needLoadingRequestCount <= 0) return;
  needLoadingRequestCount--;
  if (needLoadingRequestCount === 0) {
    endLoading();
  }
}