"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.changeCurrencyLimit = changeCurrencyLimit;
exports.changeStatus = changeStatus;
exports.getChannel = getChannel;
exports.getCurrencyLimit = getCurrencyLimit;
exports.userManageList = userManageList;
var _request = _interopRequireDefault(require("@/utils/request"));
var _qs = _interopRequireDefault(require("qs"));
// 获取列表数据
function userManageList(params) {
  return (0, _request.default)({
    url: "/yx/userManagement/UserInfos?".concat(_qs.default.stringify(params)),
    method: 'GET'
  });
}

// 获取渠道
function getChannel() {
  return (0, _request.default)({
    url: '/yx/configQuery/channel',
    method: 'GET'
  });
}

// 启用停用
function changeStatus(params) {
  return (0, _request.default)({
    url: "/yx/userManagement/status?id=".concat(params.id, "&state=").concat(params.state),
    method: 'GET'
  });
}

// 获取限额
function getCurrencyLimit(params) {
  return (0, _request.default)({
    url: "/yx/userManagement/getCurrencyLimit?currency=".concat(params.currency),
    method: 'GET'
  });
}

// 修改限额
function changeCurrencyLimit(params) {
  return (0, _request.default)({
    url: "/yx/userManagement/changeLimit?limit=".concat(params.limit, "&loginName=").concat(params.uName),
    method: 'GET'
  });
}