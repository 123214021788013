"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.to-string");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _blockchainBetting = require("@/api/report/blockchainBetting");
var _common = require("@/api/common");
var _gameProperties = require("@/utils/gameProperties");
var _vuex = require("vuex");
var _downFiles = require("@/utils/downFiles");
var _elementUi = require("element-ui");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _this;

// 输赢报表
var _default = exports.default = {
  name: 'blockchainBetting',
  props: {},
  data: function data() {
    return {
      dialog: false,
      channelList: [],
      currencyList: [],
      modelUrl: '',
      loading: false,
      isDimen: false,
      tableData: {
        list: [],
        total: 0,
        totalPage: 1
      },
      totalList: [],
      queryForm: {
        page: 1,
        limit: 10,
        userName: '',
        gameId: '',
        roundId: '',
        sourceId: '',
        channel: '',
        agentName: '',
        beginTime: '',
        endTime: '',
        groupChannel: 0,
        groupCurrency: 0,
        groupProxy: 0,
        groupUserName: 0,
        groupDate: 0,
        id: ''
      }
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['isAdmin'])),
  beforeCreate: function beforeCreate() {
    _this = this;
  },
  mounted: function mounted() {
    this.queryForm.page = 1;
    this.queryForm.beginTime = this.formatTime(new Date(), 'start');
    this.queryForm.endTime = this.formatTime(new Date(new Date().getTime() + 24 * 60 * 60 * 1000), 'end');
    this.getChannelList();
    // this.getCurrencyList();
    this.getList();
  },
  methods: {
    formatPercent: _gameProperties.formatPercent,
    renderCurrency: _gameProperties.renderCurrency,
    onSearch: function onSearch() {
      this.queryForm.page = 1;
      this.getList();
    },
    formatNumber: function formatNumber(n) {
      n = n.toString();
      return n[1] ? n : '0' + n;
    },
    formatTime: function formatTime(time, dateType) {
      if (!time) {
        return;
      }
      var year = time.getFullYear();
      var month = this.formatNumber(time.getMonth() + 1);
      var day = this.formatNumber(time.getDate());
      return dateType == 'start' ? "".concat(year, "-").concat(month, "-").concat(day, " 12:00:00") : "".concat(year, "-").concat(month, "-").concat(day, " 11:59:59");
    },
    isDimensions: function isDimensions() {
      var _this$queryForm = this.queryForm,
        groupChannel = _this$queryForm.groupChannel,
        groupCurrency = _this$queryForm.groupCurrency,
        groupProxy = _this$queryForm.groupProxy,
        groupUserName = _this$queryForm.groupUserName,
        groupDate = _this$queryForm.groupDate;
      if (groupChannel == 1 || groupCurrency == 1 || groupProxy == 1 || groupUserName == 1 || groupDate == 1) {
        return true;
      } else {
        return false;
      }
    },
    parseParam: function parseParam(param) {
      var paramStr = '';
      Object.keys(param).forEach(function (key, index) {
        if (index === 0) {
          paramStr += key + "=" + encodeURIComponent(param[key]);
        } else {
          paramStr += "&" + key + "=" + encodeURIComponent(param[key]);
        }
      });
      return paramStr;
    },
    getParams: function getParams() {
      var obj = {};
      var keyArr = ['beginTime', 'endTime', 'channel', 'gameId'];
      for (var i in this.queryForm) {
        if (keyArr.includes(i) && (!this.queryForm[i] || this.queryForm[i] instanceof Array && this.queryForm[i].length == 0)) {
          obj[i] = '';
        } else {
          obj[i] = this.queryForm[i];
        }
      }
      obj['channel'] = obj['channel'].toString();
      return obj;
    },
    getList: function getList() {
      var _this2 = this;
      this.loading = true;
      var pa = this.getParams();
      var params = this.parseParam(pa);
      (0, _blockchainBetting.getList)(params).then(function (res) {
        _this2.loading = false;
        _this2.isDimen = _this2.isDimensions();
        if (res.code == 200) {
          var form = new FormData();
          for (var i in pa) {
            form.append(i, pa[i]);
          }
          _this2.getSumList(form);
          _this2.tableData = {
            list: res.data,
            total: res.count,
            totalPage: 1
          };
        } else {
          _this2.$message.error(_this2.$t(res.message));
          _this2.tableData = {
            list: [],
            total: 0,
            totalPage: 1
          };
        }
      }).catch(function (err) {
        _this2.loading = false;
        _this2.tableData = {
          list: [],
          total: 0,
          totalPage: 1
        };
      });
    },
    getSumList: function getSumList(params) {
      var _this3 = this;
      (0, _blockchainBetting.getSumList)(params).then(function (res) {
        if (res.code == 200) {
          _this3.totalList = res.data;
        } else {
          _this3.$message.error(_this3.$t(res.message));
          _this3.totalList = [];
        }
      }).catch(function (err) {
        _this3.totalList = [];
      });
    },
    // 导出
    onOutput: function onOutput() {
      var _this4 = this;
      var loading = _elementUi.Loading.service({
        fullscreen: true
      });
      var params = this.parseParam(this.getParams());
      (0, _blockchainBetting.output)(params).then(function (res) {
        if (res.code == 200) {
          var columns = _this4.createColums();
          var data = _this4.editData(res.data);
          (0, _downFiles.downFiles)(data, columns, 'blockChain');
        } else {
          _this4.$message.error(_this4.$t(res.message));
        }
      }).catch(function (err) {
        console.log(err);
      }).finally(function () {
        _this4.$nextTick(function () {
          loading.close();
        });
      });
    },
    editData: function editData(data) {
      var _this5 = this;
      if (!data) return [];
      var arr = [];
      data.forEach(function (item) {
        var model = _objectSpread({}, item);
        if (model.gameName) model.gameName = _this5.$t(model.gameName);
        if (model.tableName) model.tableName = _this5.$t(model.tableName);
        if (model.currency) model.currency = _this5.$t(model.currency);
        arr.push(model);
      });
      return arr;
    },
    // 创建表头
    createColums: function createColums() {
      var normal = [{
        "label": this.$t('账户名'),
        "key": "userName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('桌号'),
        "key": "tableName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('游戏'),
        "key": "gameName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('币种'),
        "key": "currency",
        "control_type": "TEXT"
      }, {
        "label": this.$t('渠道'),
        "key": "channel",
        "control_type": "TEXT"
      }, {
        "label": this.$t('代理'),
        "key": "agentName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('局号'),
        "key": "roundId",
        "control_type": "TEXT"
      }, {
        "label": this.$t('总下注'),
        "key": "amount",
        "control_type": "TEXT"
      }, {
        "label": this.$t('洗码量'),
        "key": "commamount",
        "control_type": "TEXT"
      }, {
        "label": this.$t('总派彩'),
        "key": "result",
        "control_type": "TEXT"
      }, {
        "label": this.$t('收入'),
        "key": "userResult",
        "control_type": "TEXT"
      }, {
        "label": this.$t('订单ID'),
        "key": "sourceId",
        "control_type": "TEXT"
      }, {
        "label": this.$t('日期'),
        "key": "betDate",
        "control_type": "TEXT"
      }, {
        "label": this.$t('投注时间'),
        "key": "betTime",
        "control_type": "TEXT"
      }];
      return normal;
    },
    // 页码size 改变
    handleSizeChange: function handleSizeChange(size) {
      setTimeout(function () {
        window.scrollTo({
          left: 0,
          top: 0
        });
      }, 10);
      this.queryForm.limit = size;
      this.queryForm.page = 1;
      this.getList();
    },
    // 分页数据改变
    handleCurrentChange: function handleCurrentChange(val) {
      window.scrollTo({
        left: 0,
        top: 0
      });
      this.queryForm.page = val;
      this.getList();
    },
    detailHandle: function detailHandle(row) {
      var _this6 = this;
      var channel = row.channel,
        userName = row.userName,
        sourceId = row.sourceId,
        gameId = row.gameId,
        tableId = row.tableId;
      this.dialog = true;
      var pa = {
        channel: channel || '',
        userName: userName,
        sourceId: sourceId,
        tableId: tableId,
        gameId: gameId,
        lang: localStorage.getItem('lang') === 'zh' ? 'zh-hans' : localStorage.getItem('lang')
      };
      var params = this.parseParam(pa);
      (0, _blockchainBetting.getDetail)(params).then(function (res) {
        if (res.code == 200) {
          _this6.modelUrl = res.data;
        } else {
          _this6.$message.error(_this6.$t(res.message));
          _this6.modelUrl = {};
        }
      }).catch(function (err) {
        _this6.modelUrl = {};
      });
    },
    getChannelList: function getChannelList() {
      var _this7 = this;
      (0, _common.getChannel)().then(function (res) {
        if (res.code == 200) {
          _this7.channelList = res.data;
        } else {
          _this7.$message.error(_this7.$t(res.message));
          _this7.channelList = [];
        }
      }).catch(function (err) {
        _this7.channelList = [];
      });
    },
    getCurrencyList: function getCurrencyList() {
      var _this8 = this;
      (0, _common.getCurrency)().then(function (res) {
        if (res.code == 200) {
          _this8.currencyList = res.data.map(function (val) {
            val.name = (0, _gameProperties.renderCurrency)(val.name);
            return val;
          });
        } else {
          _this8.$message.error(_this8.$t(res.message));
          _this8.currencyList = [];
        }
      }).catch(function (err) {
        _this8.currencyList = [];
      });
    }
  }
};