var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.queryForm, inline: true },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-cascader", {
                    attrs: {
                      placeholder: _vm.$t("桌台选择"),
                      props: { multiple: true, label: "label" },
                      "collapse-tags": "",
                      clearable: "",
                      "show-all-levels": false,
                      options: _vm.tableIdsList,
                    },
                    model: {
                      value: _vm.queryForm.table,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "table", $$v)
                      },
                      expression: "queryForm.table",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      placeholder: _vm.$t("选择日期时间"),
                    },
                    model: {
                      value: _vm.queryForm.startDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "startDate", $$v)
                      },
                      expression: "queryForm.startDate",
                    },
                  }),
                  _c("span", [
                    _c("span", { staticStyle: { "margin-right": "8px" } }, [
                      _vm._v("12:00:00"),
                    ]),
                    _vm._v(" " + _vm._s(_vm.$t("到"))),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      placeholder: _vm.$t("选择日期时间"),
                    },
                    model: {
                      value: _vm.queryForm.endDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "endDate", $$v)
                      },
                      expression: "queryForm.endDate",
                    },
                  }),
                  _c("span", [_vm._v("11:59:59")]),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("快速选择"),
                        filterable: "",
                      },
                      on: { change: _vm.onDateChange },
                      model: {
                        value: _vm.queryForm.searchDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "searchDate", $$v)
                        },
                        expression: "queryForm.searchDate",
                      },
                    },
                    _vm._l(_vm.dateSearchList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("渠道选择"),
                        multiple: "",
                        "collapse-tags": "",
                        filterable: "",
                        clearable: "",
                      },
                      model: {
                        value: _vm.queryForm.channelId,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "channelId", $$v)
                        },
                        expression: "queryForm.channelId",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "select-class",
                          on: { click: _vm.onSelectAll },
                        },
                        [
                          _c("i", { staticClass: "el-icon-document-checked" }),
                          _vm._v(_vm._s(_vm.$t("全选"))),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "select-class",
                          on: { click: _vm.onCounterElection },
                        },
                        [
                          _c("i", { staticClass: "el-icon-document-delete" }),
                          _vm._v(_vm._s(_vm.$t("反选"))),
                        ]
                      ),
                      _vm._l(_vm.channelList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item, value: item },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("币种") },
                      model: {
                        value: _vm.queryForm.currency,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "currency", $$v)
                        },
                        expression: "queryForm.currency",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("全部币种"), value: "" },
                      }),
                      _vm._l(_vm.currencyList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.onSearch },
                    },
                    [_vm._v(_vm._s(_vm.$t("搜索")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-download",
                        size: "mini",
                      },
                      on: { click: _vm.onOutput },
                    },
                    [_vm._v(_vm._s(_vm.$t("导出")))]
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("统计维度") } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupTable,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupTable", $$v)
                        },
                        expression: "queryForm.groupTable",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("桌台号")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupDate", $$v)
                        },
                        expression: "queryForm.groupDate",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("日期")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupChannel,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupChannel", $$v)
                        },
                        expression: "queryForm.groupChannel",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("渠道")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupCurreny,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupCurreny", $$v)
                        },
                        expression: "queryForm.groupCurreny",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("币种")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "45px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.tableData.list,
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "tableNum",
              label: _vm.$t("桌台号"),
              "min-width": "100",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getTableName(scope.row.tableNum))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "channel",
              label: _vm.$t("渠道"),
              "min-width": "100",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "currency",
              label: _vm.$t("币种"),
              "min-width": "140",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.renderCurrency(scope.row.currency))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "date",
              label: _vm.$t("日期"),
              "min-width": "140",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "totalSum",
              label: _vm.$t("笔数"),
              "min-width": "120",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "amountSum",
              label: _vm.$t("投注额"),
              "min-width": "120",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.toDecimal(scope.row.amountSum))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "commamountSum",
              label: _vm.$t("洗码量"),
              "min-width": "140",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.toDecimal(scope.row.commamountSum))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "resultSum",
              label: _vm.$t("输赢金额"),
              "min-width": "140",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.toDecimal(scope.row.resultSum))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "winPercent",
              label: _vm.$t("获利率"),
              "min-width": "140",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.renderBanker(scope.row.winPercent))),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-content" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryForm.page,
              "page-sizes": [10, 20, 30, 40, 50],
              "page-size": _vm.queryForm.limit,
              total: parseInt(_vm.tableData.total),
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "45px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.totalList,
            "show-summary": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "currency",
              label: _vm.$t("币种"),
              "min-width": "140",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.renderCurrency(scope.row.currency))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "totalSum",
              label: _vm.$t("总笔数"),
              "min-width": "140",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "amountSum",
              label: _vm.$t("投注总额"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.toDecimal(scope.row.amountSum))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "commamountSum",
              label: _vm.$t("洗码量总额"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.toDecimal(scope.row.commamountSum))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "resultSum",
              label: _vm.$t("游戏输赢总额(元)"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.toDecimal(scope.row.resultSum))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "winPercent",
              label: _vm.$t("获利率"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.renderBanker(scope.row.winPercent))),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }