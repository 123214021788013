"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.roleDesc = roleDesc;
exports.roleQuery = roleQuery;
var _request = _interopRequireDefault(require("@/utils/request"));
// 角色列表
function roleQuery(name) {
  return (0, _request.default)({
    url: "/as/system/role/query?name=".concat(name),
    method: 'GET'
  });
}

// 角色详情
function roleDesc(id) {
  return (0, _request.default)({
    url: "/as/system/role/query-desc?roleId=".concat(id),
    method: 'GET'
  });
}