"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addBigNumbers = addBigNumbers;
exports.diffPercentage = diffPercentage;
exports.formatNumber = formatNumber;
exports.formatNumberBig = formatNumberBig;
exports.percentage = percentage;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
var _big = _interopRequireDefault(require("big.js"));
// 设置默认精度为两位小数
_big.default.DP = 2;

// 千分位
function formatNumberBig(num) {
  if (typeof num === 'string' && !isNaN(parseFloat(num))) {
    num = parseFloat(num);
  }
  if (typeof num !== 'number' || isNaN(num)) {
    return num;
  }
  var formattedNumber = num.toFixed(2);
  // 截取小数点后两位
  // formattedNumber = formattedNumber.slice(0, -1);
  if (formattedNumber.indexOf('.') !== -1) {
    var decimalPart = formattedNumber.split('.')[1];
    if (decimalPart.length < 2) {
      formattedNumber += '0';
    }
  } else {
    formattedNumber += '.00';
  }
  // 将数字转换为千分位格式
  formattedNumber = formattedNumber.replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return formattedNumber;
}

// 小数转换为百分比的方法
function percentage(decimalString) {
  if (!decimalString) {
    return '--';
  }
  var decimalNum = parseFloat(decimalString);
  var originalNumber = new _big.default(decimalNum);
  var percentage = originalNumber.times(100);
  // 将结果转换为字符串，并添加百分号
  var percentageString = percentage.toString() + '%';
  return percentageString;
}

// 两数之差转换成百分比
function diffPercentage(decimal1, decimal2) {
  if (!decimal1 || !decimal2) {
    return '--';
  }
  var decimalNum1 = parseFloat(decimal1);
  var decimalNum2 = parseFloat(decimal2);
  // 将字符串小数转换为Big对象
  var num1 = new _big.default(decimalNum1);
  var num2 = new _big.default(decimalNum2);
  // 计算差值
  var difference = num1.minus(num2);
  // 小数转换为百分比
  var percentage = difference.times(100);
  // 将结果转换为字符串，并添加百分号
  var percentageString = percentage.toString() + '%';
  return percentageString;
}

// 两数相加
function addBigNumbers(num1, num2) {
  if (!isValidNumber(num1) || !isValidNumber(num2)) {
    return '0';
  }
  var bigNum1 = new _big.default(num1);
  var bigNum2 = new _big.default(num2);
  return bigNum1.plus(bigNum2).toString();
}

// 辅助函数：判断输入数值是否有效
function isValidNumber(num) {
  return !isNaN(Number(num));
}

// 千分位
function formatNumber(num) {
  // 如果输入的是字符串形式的数字，则转换为数字类型
  if (typeof num === 'string' && !isNaN(parseFloat(num))) {
    num = parseFloat(num);
  }

  // 如果输入的不是数字，则直接返回本身
  if (typeof num !== 'number' || isNaN(num)) {
    return num;
  }

  // 将数字转换为字符串，并保留两位小数
  var formattedNumber = num.toFixed(2);
  // 截取小数点后两位
  formattedNumber = formattedNumber.slice(0, -1);
  // 如果小数点后不足两位，则补零
  if (formattedNumber.indexOf('.') !== -1) {
    var decimalPart = formattedNumber.split('.')[1];
    if (decimalPart.length < 2) {
      formattedNumber += '0';
    }
  } else {
    formattedNumber += '.00';
  }
  // 将数字转换为千分位格式
  formattedNumber = formattedNumber.replace(/\d(?=(\d{3})+\.)/g, '$&,');
  return formattedNumber;
}