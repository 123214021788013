"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getAnalysis = getAnalysis;
exports.getList = getList;
exports.getOrderDetail = getOrderDetail;
exports.getUserDetail = getUserDetail;
exports.hedging = hedging;
exports.output = output;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取列表数据
function getList(params) {
  return (0, _request.default)({
    url: '/yx/player/retrieve?' + params,
    method: 'GET'
  });
}

// 获取列表数据
function getAnalysis(params) {
  return (0, _request.default)({
    url: "/yx/player/overview?startTime=".concat(params.startTime, "&endTime=").concat(params.endTime, "&userName=").concat(params.userName, "&gameType=").concat(params.gameType),
    method: 'GET'
  });
}

// 导出
function output(data) {
  return (0, _request.default)({
    url: '/yx/player/retrieve/excel?' + data,
    method: 'GET'
  });
}

// 查询计算数据
function hedging(data) {
  return (0, _request.default)({
    url: '/yx/player/hedging?' + data,
    method: 'GET'
  });
}

// 查看注单
function getOrderDetail(data) {
  return (0, _request.default)({
    url: '/yx/player/orderDetail?' + data,
    method: 'GET'
  });
}

// 查看用户详情
function getUserDetail(data) {
  return (0, _request.default)({
    url: '/yx/player/userDetail?' + data,
    method: 'GET'
  });
}