"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.output = output;
var _request = _interopRequireDefault(require("@/utils/request"));
var _qs = _interopRequireDefault(require("qs"));
// 获取列表数据
function getList(params) {
  return (0, _request.default)({
    url: "/yx/transferQuery/list?page=".concat(params.page, "&limit=").concat(params.limit, "&beginTime=").concat(params.beginTime, "&endTime=").concat(params.endTime, "&userName=").concat(params.userName, "&changeType=").concat(params.changeType, "&id=").concat(params.id, "&opId=").concat(params.opId),
    method: 'GET'
  });
}

// 导出
function output(data) {
  return (0, _request.default)({
    url: "/yx/transferQuery/excel?".concat(_qs.default.stringify(data)),
    method: 'POST'
  });
}