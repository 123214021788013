"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.getNewList = getNewList;
exports.getNewSumList = getNewSumList;
exports.getSumList = getSumList;
exports.output = output;
exports.outputNew = outputNew;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取列表数据
function getList(params) {
  return (0, _request.default)({
    url: '/yx/gameLogQuery/list?' + params,
    method: 'GET'
  });
}

// 新-获取列表数据
function getNewList(params) {
  return (0, _request.default)({
    url: '/yx/gameNewLogQuery/list?' + params,
    method: 'GET'
  });
}

// 获取统计数据
function getSumList(data) {
  return (0, _request.default)({
    url: '/yx/gameLogQuery/sum',
    method: 'POST',
    data: data
  });
}

// 导出
function output(data) {
  return (0, _request.default)({
    url: '/yx/gameLogQuery/excel?' + data,
    method: 'POST'
  });
}

// 新-获取统计数据
function getNewSumList(data) {
  return (0, _request.default)({
    url: '/yx/gameNewLogQuery/sum',
    method: 'POST',
    data: data
  });
}

// 新-导出
function outputNew(data) {
  return (0, _request.default)({
    url: '/yx/gameNewLogQuery/excel?' + data,
    method: 'POST'
  });
}