var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.queryForm, inline: true },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { placeholder: _vm.$t("游戏") },
                      model: {
                        value: _vm.queryForm.game,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "game", $$v)
                        },
                        expression: "queryForm.game",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("全部游戏"), value: "" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("百家乐"), value: 1 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("龙虎"), value: 2 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("轮盘"), value: 3 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("骰宝"), value: 4 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("牛牛"), value: 5 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("炸金花"), value: 12 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { placeholder: _vm.$t("渠道"), filterable: "" },
                      model: {
                        value: _vm.queryForm.channel,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "channel", $$v)
                        },
                        expression: "queryForm.channel",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("全部"), value: "" },
                      }),
                      _vm._l(_vm.channelList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item, value: item },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("账户名"),
                    },
                    model: {
                      value: _vm.queryForm.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "userName", $$v)
                      },
                      expression: "queryForm.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("代理"),
                    },
                    model: {
                      value: _vm.queryForm.proxy,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "proxy", $$v)
                      },
                      expression: "queryForm.proxy",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      placeholder: _vm.$t("开始时间"),
                    },
                    model: {
                      value: _vm.queryForm.beginTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "beginTime", $$v)
                      },
                      expression: "queryForm.beginTime",
                    },
                  }),
                  _c("span", [
                    _c("span", { staticStyle: { "margin-right": "8px" } }, [
                      _vm._v("12:00:00"),
                    ]),
                    _vm._v(_vm._s(_vm.$t("到"))),
                  ]),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      placeholder: _vm.$t("结束时间"),
                    },
                    model: {
                      value: _vm.queryForm.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "endTime", $$v)
                      },
                      expression: "queryForm.endTime",
                    },
                  }),
                  _c("span", [_vm._v("11:59:59")]),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "160px" },
                      attrs: { placeholder: _vm.$t("币种") },
                      model: {
                        value: _vm.queryForm.currency,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "currency", $$v)
                        },
                        expression: "queryForm.currency",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("全部"), value: 0 },
                      }),
                      _vm._l(_vm.currencyList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _vm.isAdmin
                ? _c(
                    "el-form-item",
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "160px" },
                          attrs: { placeholder: _vm.$t("用户类型") },
                          model: {
                            value: _vm.queryForm.machineFlag,
                            callback: function ($$v) {
                              _vm.$set(_vm.queryForm, "machineFlag", $$v)
                            },
                            expression: "queryForm.machineFlag",
                          },
                        },
                        [
                          _c("el-option", {
                            attrs: { label: _vm.$t("全部用户"), value: false },
                          }),
                          _c("el-option", {
                            attrs: { label: _vm.$t("特殊用户"), value: true },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("总局数"),
                    },
                    model: {
                      value: _vm.queryForm.total,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "total", $$v)
                      },
                      expression: "queryForm.total",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("庄比例"),
                    },
                    model: {
                      value: _vm.queryForm.bankerPercent,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "bankerPercent", $$v)
                      },
                      expression: "queryForm.bankerPercent",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("ev百分比"),
                    },
                    model: {
                      value: _vm.queryForm.evPercent,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "evPercent", $$v)
                      },
                      expression: "queryForm.evPercent",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("ev投注百分比"),
                    },
                    model: {
                      value: _vm.queryForm.evAmountPercent,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "evAmountPercent", $$v)
                      },
                      expression: "queryForm.evAmountPercent",
                    },
                  }),
                ],
                1
              ),
              _vm.isAdmin
                ? _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        staticStyle: { width: "160px" },
                        attrs: {
                          clearable: "",
                          size: "small",
                          placeholder: _vm.$t("IP风险分数"),
                        },
                        model: {
                          value: _vm.queryForm.ipRiskScoreLimit,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "ipRiskScoreLimit", $$v)
                          },
                          expression: "queryForm.ipRiskScoreLimit",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isAdmin
                ? _c(
                    "el-form-item",
                    [
                      _c("el-input", {
                        staticStyle: { width: "160px" },
                        attrs: {
                          clearable: "",
                          size: "small",
                          placeholder: _vm.$t("合计风险分数"),
                        },
                        model: {
                          value: _vm.queryForm.totalRiskScoreLimit,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryForm, "totalRiskScoreLimit", $$v)
                          },
                          expression: "queryForm.totalRiskScoreLimit",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.onSearch },
                    },
                    [_vm._v(_vm._s(_vm.$t("搜索")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-download",
                        size: "mini",
                      },
                      on: { click: _vm.onOutput },
                    },
                    [_vm._v(_vm._s(_vm.$t("导出")))]
                  ),
                ],
                1
              ),
              _c("br"),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("统计维度") } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupChannel,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupChannel", $$v)
                        },
                        expression: "queryForm.groupChannel",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("渠道")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupCurrency,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupCurrency", $$v)
                        },
                        expression: "queryForm.groupCurrency",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("币种")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupProxy,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupProxy", $$v)
                        },
                        expression: "queryForm.groupProxy",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("代理")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupUserName,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupUserName", $$v)
                        },
                        expression: "queryForm.groupUserName",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("用户名称")))]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      attrs: { "true-label": 1, "false-label": 0 },
                      model: {
                        value: _vm.queryForm.groupDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "groupDate", $$v)
                        },
                        expression: "queryForm.groupDate",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("按日期")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "45px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.tableData.list,
          },
        },
        [
          _vm.isAdmin
            ? [
                _c("el-table-column", {
                  key: "userNamekey",
                  attrs: {
                    prop: "userName",
                    label: _vm.$t("账户名"),
                    "min-width": "160",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              {
                                staticClass: "table-link",
                                on: {
                                  click: function ($event) {
                                    return _vm.handleOpenPage(scope.row)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(scope.row.userName))]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    37791333
                  ),
                }),
                _c("el-table-column", {
                  key: "channelkey",
                  attrs: {
                    prop: "channel",
                    label: _vm.$t("渠道"),
                    "min-width": "100",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "agentkey",
                  attrs: {
                    prop: "agent",
                    label: _vm.$t("代理"),
                    "min-width": "100",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "CCYNAMEkey",
                  attrs: {
                    prop: "CCYNAME",
                    label: _vm.$t("币种"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.renderCurrency(scope.row.CCYNAME))
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    984051124
                  ),
                }),
                _c("el-table-column", {
                  key: "groupForkey",
                  attrs: {
                    prop: "groupFor",
                    label: _vm.$t("日期"),
                    "min-width": "100",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "gamekey",
                  attrs: {
                    prop: "game",
                    label: _vm.$t("游戏"),
                    "min-width": "100",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(_vm.setGameName(scope.row.game))),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2621633282
                  ),
                }),
                _c("el-table-column", {
                  key: "roundskey",
                  attrs: {
                    prop: "rounds",
                    label: _vm.$t("局数"),
                    "min-width": "160",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "evRoundskey",
                  attrs: {
                    prop: "evRounds",
                    label: _vm.$t("ev局数"),
                    "min-width": "180",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "bankerRoundskey",
                  attrs: {
                    prop: "bankerRounds",
                    label: _vm.$t("庄局数"),
                    "min-width": "200",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "playerRoundskey",
                  attrs: {
                    prop: "playerRounds",
                    label: _vm.$t("闲局数"),
                    "min-width": "200",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "goodRoundskey",
                  attrs: {
                    prop: "goodRounds",
                    label: _vm.$t("好路局数"),
                    "min-width": "220",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "betAmountkey",
                  attrs: {
                    prop: "betAmount",
                    label: _vm.$t("投注额"),
                    "min-width": "120",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "validBetAmountkey",
                  attrs: {
                    prop: "validBetAmount",
                    label: _vm.$t("洗码量"),
                    "min-width": "160",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "bankerPercentkey",
                  attrs: {
                    prop: "bankerPercent",
                    label: _vm.$t("庄比例"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPercent(
                                    (scope.row.bankerRounds /
                                      scope.row.rounds) *
                                      100
                                  ) + "%"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    2357514036
                  ),
                }),
                _c("el-table-column", {
                  key: "evPercentkey1",
                  attrs: {
                    prop: "evPercent",
                    label: _vm.$t("ev百分比"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPercent(
                                    (scope.row.evRounds / scope.row.rounds) *
                                      100
                                  ) + "%"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    385193142
                  ),
                }),
                _c("el-table-column", {
                  key: "evAmountPercent1",
                  attrs: {
                    prop: "evAmountPercent",
                    label: _vm.$t("ev投注百分比"),
                    "min-width": "160",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPercent(
                                    scope.row.countAmountSum == 0
                                      ? 0
                                      : (scope.row.evAmount /
                                          scope.row.countAmountSum) *
                                          100
                                  ) + "%"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1565055391
                  ),
                }),
                _c("el-table-column", {
                  key: "evWinPercentkey1",
                  attrs: {
                    prop: "evWinPercent",
                    label: _vm.$t("ev加权值"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPercent(
                                    scope.row.countAmountSum == 0
                                      ? 0
                                      : (scope.row.evWinSum /
                                          scope.row.countAmountSum) *
                                          100
                                  ) + "%"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3637737640
                  ),
                }),
                _c("el-table-column", {
                  key: "moneykey",
                  attrs: {
                    prop: "money",
                    label: _vm.$t("游戏输赢"),
                    "min-width": "160",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "realProfitability1",
                  attrs: {
                    prop: "realProfitability",
                    label: _vm.$t("实际盈利率"),
                    "min-width": "160",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPercent(
                                    (scope.row.money /
                                      scope.row.validBetAmount) *
                                      100
                                  ) + "%"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    666496572
                  ),
                }),
                _c("el-table-column", {
                  key: "goodPercent1",
                  attrs: {
                    prop: "goodPercent",
                    label: _vm.$t("好路比例"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.formatPercent(
                                    (scope.row.goodRounds / scope.row.rounds) *
                                      100
                                  ) + "%"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    3141506374
                  ),
                }),
                _c("el-table-column", {
                  key: "ipRiskScore1",
                  attrs: {
                    prop: "ipRiskScore",
                    label: _vm.$t("IP风险分数"),
                    "min-width": "140",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "totalRiskScore1",
                  attrs: {
                    prop: "totalRiskScore",
                    label: _vm.$t("合计风险分数"),
                    "min-width": "140",
                    sortable: "",
                  },
                }),
              ]
            : [
                _c("el-table-column", {
                  key: "userName2",
                  attrs: {
                    prop: "userName",
                    label: _vm.$t("账户名"),
                    "min-width": "120",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c(
                            "span",
                            {
                              staticClass: "table-link",
                              on: {
                                click: function ($event) {
                                  return _vm.handleOpenPage(scope.row)
                                },
                              },
                            },
                            [_vm._v(_vm._s(scope.row.userName))]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  key: "channel2",
                  attrs: {
                    prop: "channel",
                    label: _vm.$t("渠道"),
                    "min-width": "100",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "agent2",
                  attrs: {
                    prop: "agent",
                    label: _vm.$t("代理"),
                    "min-width": "100",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "CCYNAME2",
                  attrs: {
                    prop: "CCYNAME",
                    label: _vm.$t("币种"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(_vm.renderCurrency(scope.row.CCYNAME))
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  key: "groupFor2",
                  attrs: {
                    prop: "groupFor",
                    label: _vm.$t("日期"),
                    "min-width": "200",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "gamekey2",
                  attrs: {
                    prop: "game",
                    label: _vm.$t("游戏"),
                    "min-width": "100",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(_vm._s(_vm.setGameName(scope.row.game))),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  key: "rounds2",
                  attrs: {
                    prop: "rounds",
                    label: _vm.$t("局数"),
                    "min-width": "120",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "evRounds2",
                  attrs: {
                    prop: "evRounds",
                    label: _vm.$t("ev局数"),
                    "min-width": "120",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "bankerRounds2",
                  attrs: {
                    prop: "bankerRounds",
                    label: _vm.$t("庄局数"),
                    "min-width": "120",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "playerRounds2",
                  attrs: {
                    prop: "playerRounds",
                    label: _vm.$t("闲局数"),
                    "min-width": "120",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "betAmount2",
                  attrs: {
                    prop: "betAmount",
                    label: _vm.$t("投注额"),
                    "min-width": "140",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "validBetAmount2",
                  attrs: {
                    prop: "validBetAmount",
                    label: _vm.$t("洗码量"),
                    "min-width": "140",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "bankerPercent2",
                  attrs: {
                    prop: "bankerPercent",
                    label: _vm.$t("庄比例"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatPercent(
                                  (scope.row.bankerRounds / scope.row.rounds) *
                                    100
                                ) + "%"
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  key: "evPercent2",
                  attrs: {
                    prop: "evPercent",
                    label: _vm.$t("ev百分比"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatPercent(
                                  (scope.row.evRounds / scope.row.rounds) * 100
                                ) + "%"
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  key: "evAmountPercent2",
                  attrs: {
                    prop: "evAmountPercent",
                    label: _vm.$t("ev投注百分比"),
                    "min-width": "180",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatPercent(
                                  scope.row.countAmountSum == 0
                                    ? 0
                                    : (scope.row.evAmount /
                                        scope.row.countAmountSum) *
                                        100
                                ) + "%"
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  key: "evWinPercent2",
                  attrs: {
                    prop: "evWinPercent",
                    label: _vm.$t("ev加权值"),
                    "min-width": "140",
                    sortable: "",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatPercent(
                                  scope.row.countAmountSum == 0
                                    ? 0
                                    : (scope.row.evWinSum /
                                        scope.row.countAmountSum) *
                                        100
                                ) + "%"
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("el-table-column", {
                  key: "money2",
                  attrs: {
                    prop: "money",
                    label: _vm.$t("游戏输赢"),
                    "min-width": "140",
                    sortable: "",
                  },
                }),
                _c("el-table-column", {
                  key: "realProfitability2",
                  attrs: {
                    prop: "realProfitability",
                    label: _vm.$t("实际盈利率"),
                    "min-width": "140",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (scope) {
                        return [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.formatPercent(
                                  (scope.row.money / scope.row.validBetAmount) *
                                    100
                                ) + "%"
                              )
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
              ],
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "pagination-content" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryForm.page,
              "page-sizes": [10, 20, 30, 40, 50],
              "page-size": _vm.queryForm.limit,
              total: parseInt(_vm.tableData.total),
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "45px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.totalList,
            "show-summary": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "CCYNAME",
              label: _vm.$t("币种"),
              "min-width": "100",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.renderCurrency(scope.row.CCYNAME))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "rounds",
              label: _vm.$t("总笔数"),
              "min-width": "140",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "betAmount",
              label: _vm.$t("投注总额"),
              "min-width": "200",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "validbetamount",
              label: _vm.$t("洗码量总额"),
              "min-width": "200",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "money",
              label: _vm.$t("游戏输赢总额(元)"),
              "min-width": "200",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "realProfitability",
              label: _vm.$t("实际盈利率"),
              "min-width": "200",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.formatPercent(
                            (scope.row.money / scope.row.validbetamount) * 100
                          ) + "%"
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }