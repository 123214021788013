"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDetail = getDetail;
exports.getGameList = getGameList;
exports.getList = getList;
exports.getSumList = getSumList;
exports.output = output;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取列表数据
function getList(params) {
  return (0, _request.default)({
    url: '/yx/gameLogVG/list?' + params,
    method: 'GET'
  });
}

// 获取游戏
function getGameList() {
  return (0, _request.default)({
    url: '/yx/gameLogVG/getVgGameList',
    method: 'GET'
  });
}

// 获取统计数据
function getSumList(data) {
  return (0, _request.default)({
    url: '/yx/gameLogVG/sum',
    method: 'POST',
    data: data
  });
}

// 导出
function output(data) {
  return (0, _request.default)({
    url: '/yx/gameLogVG/excel?' + data,
    method: 'POST'
  });
}

// 详情
function getDetail(data) {
  return (0, _request.default)({
    url: '/yx/gameLogVG/getVgBetDetailUrl?' + data,
    method: 'get'
  });
}