"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMenus = getMenus;
exports.getMenusTree = getMenusTree;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取菜单树形列表
function getMenusTree(id) {
  return (0, _request.default)({
    url: "/as/system/menu/tree?parentId=".concat(id),
    method: 'GET'
  });
}
function getMenus(data) {
  return (0, _request.default)({
    url: '/as/system/menu/query',
    method: 'post',
    data: data
  });
}