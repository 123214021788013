"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.function.name");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vgGameRecord = require("@/api/report/vgGameRecord");
var _common = require("@/api/common");
var _gameProperties = require("@/utils/gameProperties");
var _downFiles = require("@/utils/downFiles");
var _elementUi = require("element-ui");
var _vuex = require("vuex");
var _lodash = _interopRequireDefault(require("lodash"));
var _big = require("@/utils/big");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _this;
// 游戏记录查询
var _default = exports.default = {
  components: {},
  name: 'vgGameRecord',
  props: {},
  data: function data() {
    return {
      dialog: false,
      gamesList: [],
      channelList: [],
      currencyList: [],
      detailUrl: '',
      accountForm: {
        uName: '',
        betLimit: [],
        limit: []
      },
      loading: false,
      tableData: {
        list: [],
        total: 0,
        totalPage: 1
      },
      totalList: [],
      queryForm: {
        page: 1,
        limit: 10,
        userName: '',
        sourceId: '',
        roundId: '',
        beginTime: '',
        endTime: '',
        gameId: '',
        channel: '',
        proxy: '',
        currency: '',
        timeType: 'create',
        groupChannel: '',
        // 渠道
        groupCurrency: '',
        //币种
        groupProxy: '',
        // 代理
        groupUserName: '',
        //用户名称
        groupDate: '' // 按日期
      },
      dialogForm: {}
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['isAdmin'])),
  watch: {
    '$route.params': {
      handler: function handler(newValue, oldValue) {
        this.init();
      },
      deep: true
    }
  },
  beforeCreate: function beforeCreate() {
    _this = this;
  },
  mounted: function mounted() {
    this.init();
  },
  methods: {
    formatMoneyToLocal: _gameProperties.formatMoneyToLocal,
    formatCurrency: _gameProperties.formatCurrency,
    getGameName: _gameProperties.getGameName,
    renderCurrency: _gameProperties.renderCurrency,
    handleClose: function handleClose(done) {
      this.dialogForm = {};
      done();
    },
    init: function init() {
      this.queryForm.page = 1;
      var _this$$route$params = this.$route.params,
        gid = _this$$route$params.gid,
        userName = _this$$route$params.userName,
        channel = _this$$route$params.channel;
      if (gid || userName || channel) {
        this.queryForm.roundId = gid || '';
        this.queryForm.userName = userName || '';
        this.queryForm.channel = channel || '';
        this.getList();
      }
      this.getChannelList();
      this.getCurrencyList();
      this.getGameList();
    },
    onOpenDetail: function onOpenDetail(row) {
      var _this2 = this;
      var userName = row.userName,
        sourceId = row.sourceId,
        gameId = row.gameId,
        channel = row.channel,
        vgChannel = row.vgChannel;
      this.dialogForm = row;
      var obj = {
        userName: userName || '',
        sourceId: sourceId || '',
        gameId: gameId || '',
        channel: channel || '',
        vgChannel: vgChannel || ''
      };
      var params = this.parseParam(obj);
      (0, _vgGameRecord.getDetail)(params).then(function (res) {
        if (res.code == 200) {
          _this2.detailUrl = res.data + '&language=' + (localStorage.getItem('lang') == 'zh' ? 'zh-cn' : 'en');
          window.open(_this2.detailUrl);
        } else {
          _this2.$message.error(_this2.$t(res.message));
        }
      }).catch(function (err) {}).finally(function () {});
    },
    onSearch: function onSearch() {
      this.queryForm.page = 1;
      this.getList();
    },
    onReset: function onReset() {
      this.queryForm = {
        page: 1,
        limit: 10,
        userName: '',
        sourceId: '',
        roundId: '',
        beginTime: '',
        endTime: '',
        gameId: '',
        channel: '',
        proxy: '',
        currency: '',
        timeType: 'create',
        groupChannel: '',
        // 渠道
        groupCurrency: '',
        //币种
        groupProxy: '',
        // 代理
        groupUserName: '',
        //用户名称
        groupDate: '' // 按日期
      };
    },
    onOutput: function onOutput() {
      var _this3 = this;
      var loading = _elementUi.Loading.service({
        fullscreen: true
      });
      var params = this.parseParam(this.getParams());
      (0, _vgGameRecord.output)(params).then(function (res) {
        if (res.code == 200) {
          var columns = _this3.createColums();
          var data = _this3.editData(res.data);
          (0, _downFiles.downFiles)(data, columns, 'vgGameRecord');
        } else {
          _this3.$message.error(_this3.$t(res.message));
        }
      }).catch(function (err) {}).finally(function () {
        _this3.$nextTick(function () {
          loading.close();
        });
      });
    },
    editData: function editData(data) {
      var _this4 = this;
      if (!data) return [];
      return data.map(function (item) {
        if (item.currency) item.currency = (0, _gameProperties.renderCurrency)(item.currency);
        if (item.gameName) item.gameName = _this4.$t(item.gameName);
        return item;
      });
    },
    createColums: function createColums() {
      var normal = [{
        "label": this.$t('注单号'),
        "key": "sourceId",
        "control_type": "TEXT"
      }, {
        "label": this.$t('用户名'),
        "key": "userName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('渠道'),
        "key": "channel",
        "control_type": "TEXT"
      }, {
        "label": this.$t('代理'),
        "key": "agentName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('游戏'),
        "key": "gameName",
        "control_type": "TEXT"
      }, {
        "label": this.$t('币种'),
        "key": "currency",
        "control_type": "TEXT"
      }, {
        "label": this.$t('局号'),
        "key": "roundId",
        "control_type": "TEXT"
      }, {
        "label": this.$t('房号'),
        "key": "roomId",
        "control_type": "TEXT"
      }, {
        "label": this.$t('创建时间'),
        "key": "betTime",
        "control_type": "TEXT"
      }, {
        "label": this.$t('投注额'),
        "key": "amount",
        "control_type": "TEXT"
      }, {
        "label": this.$t('有效投注额'),
        "key": "commamount",
        "control_type": "TEXT"
      }, {
        "label": this.$t('游戏输赢'),
        "key": "userResult",
        "control_type": "TEXT"
      }, {
        "label": this.$t('服务费/抽水'),
        "key": "serviceCharge",
        "control_type": "TEXT"
      }, {
        "label": this.$t('实际输赢'),
        "key": "result",
        "control_type": "TEXT"
      }];
      return normal;
    },
    parseParam: function parseParam(param) {
      var paramStr = '';
      Object.keys(param).forEach(function (key, index) {
        if (index === 0) {
          paramStr += key + "=" + encodeURIComponent(param[key]);
        } else {
          paramStr += "&" + key + "=" + encodeURIComponent(param[key]);
        }
      });
      return paramStr;
    },
    toDecimal: function toDecimal(x) {
      var a = parseFloat(x);
      if (isNaN(a)) {
        return false;
      }
      var f = Math.round(x * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf('.');
      if (rs < 0) {
        rs = s.length;
        s += '.';
      }
      while (s.length <= rs + 2) {
        s += '0';
      }
      return s;
    },
    getParams: function getParams() {
      var obj = {};
      var keyArr = ['beginTime', 'endTime', 'channel', 'gameId'];
      for (var i in this.queryForm) {
        if (keyArr.includes(i) && (!this.queryForm[i] || this.queryForm[i] instanceof Array && this.queryForm[i].length == 0)) {
          obj[i] = '';
        } else {
          obj[i] = this.queryForm[i];
        }
      }
      obj['channel'] = obj['channel'].toString();
      return obj;
    },
    checkParams: function checkParams() {
      var _this$queryForm = this.queryForm,
        userName = _this$queryForm.userName,
        sourceId = _this$queryForm.sourceId,
        roundId = _this$queryForm.roundId,
        beginTime = _this$queryForm.beginTime,
        endTime = _this$queryForm.endTime;
      if (!userName && !sourceId && !roundId && (!beginTime || !endTime)) {
        this.$message.error(this.$t('用户名、注单号、局号、时间范围必填其一'));
        return false;
      }
      var beginTimeT = _lodash.default.cloneDeep(beginTime);
      var endTimeT = _lodash.default.cloneDeep(endTime);
      var timeDiff = new Date(endTimeT).getTime() - new Date(beginTimeT).getTime();
      var formatTimeDiff = timeDiff / (3600 * 1000 * 24);
      if (formatTimeDiff > 31) {
        this.$message.error(this.$t('时间范围只能在31天内'));
        return false;
      }
      return true;
    },
    getList: function getList() {
      var _this5 = this;
      if (!this.checkParams()) {
        return;
      }
      this.loading = true;
      var pa = this.getParams();
      var params = this.parseParam(pa);
      (0, _vgGameRecord.getList)(params).then(function (res) {
        _this5.loading = false;
        if (res.code == 200) {
          var form = new FormData();
          for (var i in pa) {
            form.append(i, pa[i]);
          }
          _this5.getSumList(form);
          _this5.tableData = {
            list: res.data,
            total: res.count,
            totalPage: 1
          };
        } else {
          _this5.$message.error(_this5.$t(res.message));
          _this5.tableData = {
            list: [],
            total: 0,
            totalPage: 1
          };
        }
      }).catch(function (err) {
        _this5.loading = false;
        _this5.tableData = {
          list: [],
          total: 0,
          totalPage: 1
        };
      });
    },
    getSumList: function getSumList(params) {
      var _this6 = this;
      (0, _vgGameRecord.getSumList)(params).then(function (res) {
        if (res.code == 200) {
          _this6.totalList = res.data;
        } else {
          _this6.$message.error(_this6.$t(res.message));
          _this6.totalList = [];
        }
      }).catch(function (err) {
        _this6.totalList = [];
      });
    },
    // 页码size 改变
    handleSizeChange: function handleSizeChange(size) {
      setTimeout(function () {
        window.scrollTo({
          left: 0,
          top: 0
        });
      }, 10);
      this.queryForm.limit = size;
      this.queryForm.page = 1;
      this.getList();
    },
    // 分页数据改变
    handleCurrentChange: function handleCurrentChange(val) {
      window.scrollTo({
        left: 0,
        top: 0
      });
      this.queryForm.page = val;
      this.getList();
    },
    getChannelList: function getChannelList() {
      var _this7 = this;
      (0, _common.getChannel)().then(function (res) {
        if (res.code == 200) {
          _this7.channelList = res.data;
        } else {
          _this7.$message.error(_this7.$t(res.message));
          _this7.channelList = [];
        }
      }).catch(function (err) {
        _this7.channelList = [];
      });
    },
    getCurrencyList: function getCurrencyList() {
      var _this8 = this;
      (0, _common.getCurrency)().then(function (res) {
        if (res.code == 200) {
          _this8.currencyList = res.data.map(function (val) {
            val.name = (0, _gameProperties.renderCurrency)(val.name);
            return val;
          });
        } else {
          _this8.$message.error(_this8.$t(res.message));
          _this8.currencyList = [];
        }
      }).catch(function (err) {
        _this8.currencyList = [];
      });
    },
    getGameList: function getGameList() {
      var _this9 = this;
      (0, _vgGameRecord.getGameList)().then(function (res) {
        if (res.code == 200) {
          _this9.gamesList = (0, _gameProperties.deepgreeTree)(_lodash.default.cloneDeep(res.data));
        } else {
          _this9.$message.error(_this9.$t(res.message));
          _this9.gamesList = [];
        }
      }).catch(function (err) {
        _this9.gamesList = [];
      });
    },
    // 合计 
    getTotal: function getTotal(param) {
      var sumList = ['rounds', 'amount', 'commamount', 'userResult', 'serviceCharge', 'result'];
      var columns = param.columns,
        data = param.data;
      var sums = [];
      columns.forEach(function (column, index) {
        var values = data.map(function (item) {
          var defaultItem = item[column.property] ? "".concat(item[column.property]) : '0';
          var str = defaultItem.replace(/,/g, "");
          return Number(str);
        });
        if (sumList.includes(column.property)) {
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);
            if (!isNaN(value)) {
              return (0, _big.addBigNumbers)(prev, curr);
            } else {
              return prev;
            }
          }, 0);
          sums[index] = sums[index];
        } else {
          sums[index] = '';
        }
      });
      sums[0] = this.$t('合计');
      var sumsTotal = sums.map(function (item) {
        return (0, _big.formatNumberBig)(item);
      });
      return sumsTotal;
    }
  }
};