"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.setPer = setPer;
exports.userMenus = userMenus;
exports.userUnLocked = userUnLocked;
var _request = _interopRequireDefault(require("@/utils/request"));
// 代理权限设定
function setPer(data) {
  return (0, _request.default)({
    url: '/as/account/user/menu',
    method: 'POST',
    data: data,
    showLoading: true
  });
}

// 用户解锁
function userUnLocked(data) {
  return (0, _request.default)({
    url: '/account/user/unlocked',
    method: 'post',
    data: data
  });
}

// 获取用户菜单
function userMenus(data) {
  return (0, _request.default)({
    url: '/yx/getMenus',
    method: 'post',
    data: data
  });
}