"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getChannel = getChannel;
exports.getChannelIds = getChannelIds;
exports.getCurrency = getCurrency;
exports.getTableIds = getTableIds;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取币种
function getCurrency() {
  return (0, _request.default)({
    url: '/yx/configQuery/currency',
    method: 'GET'
  });
}

// 获取渠道
function getChannel() {
  return (0, _request.default)({
    url: '/yx/configQuery/channel',
    method: 'GET'
  });
}

// 获取桌台
function getTableIds() {
  return (0, _request.default)({
    url: '/yx/configQuery/tableIds',
    method: 'GET'
  });
}

// 获取渠道id
function getChannelIds() {
  return (0, _request.default)({
    url: '/yx/configQuery/channelSelect',
    method: 'GET'
  });
}