"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteNotice = deleteNotice;
exports.disabledNotice = disabledNotice;
exports.getNotice = getNotice;
exports.getNoticeList = getNoticeList;
exports.homeNotice = homeNotice;
exports.publishNotice = publishNotice;
exports.saveNotice = saveNotice;
exports.updateNotice = updateNotice;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取通告列表
function getNoticeList(data) {
  return (0, _request.default)({
    url: '/setting/notice/getList',
    method: 'post',
    data: data
  });
}
function deleteNotice(data) {
  return (0, _request.default)({
    url: '/setting/notice/delete',
    method: 'post',
    data: data
  });
}

// 新增通告
function saveNotice(data) {
  return (0, _request.default)({
    url: '/setting/notice/save',
    method: 'post',
    data: data
  });
}

// 发布通告
function publishNotice(data) {
  return (0, _request.default)({
    url: '/setting/notice/publish',
    method: 'post',
    data: data
  });
}

// 更新通告
function updateNotice(data) {
  return (0, _request.default)({
    url: '/setting/notice/update',
    method: 'post',
    data: data
  });
}

// 停用通告
function disabledNotice(data) {
  return (0, _request.default)({
    url: '/setting/notice/disabled',
    method: 'post',
    data: data
  });
}

// 删除通告

// 获取通告-用户端
function getNotice(data) {
  return (0, _request.default)({
    url: '/setting/notice/get',
    method: 'post',
    data: data
  });
}

// 获取通告-用户端
function homeNotice(data) {
  return (0, _request.default)({
    url: '/home/getHomePageList',
    method: 'post',
    data: data
  });
}