var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "100%",
                display: "block",
                "margin-bottom": "10px",
                "font-size": "14px",
              },
            },
            [
              _c("font", { attrs: { color: "red" } }, [
                _vm._v(
                  "[" +
                    _vm._s(
                      _vm.$t("北京时间") +
                        _vm.$t("当日") +
                        "12:00:00 " +
                        _vm.$t("到 次日") +
                        "11:59:59"
                    ) +
                    " ]"
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.queryForm, inline: true },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "date",
                      format: "yyyy-MM-dd",
                      "value-format": "yyyy-MM-dd",
                      placeholder: _vm.$t("开始日期"),
                    },
                    model: {
                      value: _vm.queryForm.gameBeginTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "gameBeginTime", $$v)
                      },
                      expression: "queryForm.gameBeginTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-cascader", {
                    attrs: {
                      placeholder: _vm.$t("请选择桌台"),
                      props: { multiple: true, label: "label" },
                      "collapse-tags": "",
                      clearable: "",
                      "show-all-levels": false,
                      options: _vm.tableIdsList,
                    },
                    model: {
                      value: _vm.queryForm.tableIds,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "tableIds", $$v)
                      },
                      expression: "queryForm.tableIds",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("靴数"),
                    },
                    model: {
                      value: _vm.queryForm.shoeNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "shoeNum", $$v)
                      },
                      expression: "queryForm.shoeNum",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("渠道"),
                        multiple: "",
                        "collapse-tags": "",
                        clearable: "",
                        filterable: "",
                      },
                      model: {
                        value: _vm.queryForm.channelIds,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "channelIds", $$v)
                        },
                        expression: "queryForm.channelIds",
                      },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "select-class",
                          on: { click: _vm.onSelectAll },
                        },
                        [
                          _c("i", { staticClass: "el-icon-document-checked" }),
                          _vm._v(_vm._s(_vm.$t("全选"))),
                        ]
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "select-class",
                          on: { click: _vm.onCounterElection },
                        },
                        [
                          _c("i", { staticClass: "el-icon-document-delete" }),
                          _vm._v(_vm._s(_vm.$t("反选"))),
                        ]
                      ),
                      _vm._l(_vm.channelList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.value },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("币种") },
                      model: {
                        value: _vm.queryForm.currency,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "currency", $$v)
                        },
                        expression: "queryForm.currency",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("全部币种"), value: 0 },
                      }),
                      _vm._l(_vm.currencyList, function (item, index) {
                        return _c("el-option", {
                          key: index,
                          attrs: { label: item.name, value: item.id },
                        })
                      }),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "160px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("荷官"),
                    },
                    model: {
                      value: _vm.queryForm.dealer,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "dealer", $$v)
                      },
                      expression: "queryForm.dealer",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.onSearch },
                    },
                    [_vm._v(_vm._s(_vm.$t("搜索")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-download",
                        size: "mini",
                      },
                      on: { click: _vm.onOutput },
                    },
                    [_vm._v(_vm._s(_vm.$t("导出")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "45px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.tableData.list,
            height: "480px",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "tableNum",
              label: _vm.$t("桌台号"),
              "min-width": "140",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.getTableName(scope.row.tableNum))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "gid",
              label: _vm.$t("局号"),
              "min-width": "160",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer", color: "#409EFF" },
                        on: {
                          click: function ($event) {
                            return _vm.handleOpenPage(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(scope.row.gid))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "channel",
              label: _vm.$t("渠道"),
              "min-width": "100",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "starttime",
              label: _vm.$t("开局日期"),
              "min-width": "160",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: { label: _vm.$t("靴局"), "min-width": "140", sortable: "" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(scope.row.shoenum + "-" + scope.row.gamenum)
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "result",
              label: _vm.$t("结果"),
              "min-width": "100",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(_vm.renderResult(scope.row)))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "playerCard",
              label: _vm.$t("闲家牌"),
              "min-width": "140",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.playerCard
                            ? _vm.renderPlay(
                                scope.row.playerCard,
                                scope.row.tableNum
                              )
                            : ""
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bankerCard",
              label: _vm.$t("庄家牌"),
              "min-width": "140",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          scope.row.bankerCard
                            ? _vm.renderPlay(
                                scope.row.bankerCard,
                                scope.row.tableNum
                              )
                            : ""
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "currency",
              label: _vm.$t("币种"),
              "min-width": "140",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.renderCurrency(scope.row.currency))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "amount",
              label: _vm.$t("输赢金额"),
              "min-width": "200",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bankercount",
              label: _vm.$t("投庄笔数"),
              "min-width": "220",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bankercom",
              label: _vm.$t("投庄洗码量"),
              "min-width": "200",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "playercount",
              label: _vm.$t("投闲笔数"),
              "min-width": "220",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "playercom",
              label: _vm.$t("投闲洗码量"),
              "min-width": "200",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "goodroad",
              label: _vm.$t("好路类型"),
              "min-width": "160",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.goodRoadType(
                            scope.row.goodroad,
                            scope.row.goodroadcount,
                            scope.row.tableNum
                          )
                        )
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "dealer",
              label: _vm.$t("荷官"),
              "min-width": "120",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "gid",
              label: _vm.$t("路纸生成"),
              "min-width": "140",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        staticStyle: { cursor: "pointer", color: "#409EFF" },
                        on: {
                          click: function ($event) {
                            return _vm.handleCreate(scope.row)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            scope.row.tableNum
                              ? scope.row.tableNum
                                  .toString()
                                  .includes("BACCARAT")
                                ? _vm.$t("路纸生成")
                                : ""
                              : ""
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "playerEv",
              label: _vm.$t("闲ev值"),
              "min-width": "140",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bankerEv",
              label: _vm.$t("庄ev值"),
              "min-width": "140",
              sortable: "",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-content" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryForm.page,
              "page-sizes": [10, 20, 30, 40, 50, 100],
              "page-size": _vm.queryForm.limit,
              total: parseInt(_vm.tableData.total),
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          staticStyle: { "margin-top": "20px" },
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "45px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.totalList,
            "show-summary": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "currency",
              label: _vm.$t("币种"),
              "min-width": "140",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.renderCurrency(scope.row.currency))),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "shoenumSum",
              label: _vm.$t("靴数"),
              "min-width": "140",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "gamenumSum",
              label: _vm.$t("局数"),
              "min-width": "200",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "goodroadSum",
              label: _vm.$t("好路局数"),
              "min-width": "220",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bankerResultSum",
              label: _vm.$t("庄结果局数"),
              "min-width": "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "playerResultSum",
              label: _vm.$t("闲结果局数"),
              "min-width": "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "tileResultSum",
              label: _vm.$t("和结果局数"),
              "min-width": "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "amountSum",
              label: _vm.$t("输赢金额"),
              "min-width": "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bankercountSum",
              label: _vm.$t("投庄笔数"),
              "min-width": "220",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bankeramountSum",
              label: _vm.$t("投庄输赢"),
              "min-width": "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "bankercomSum",
              label: _vm.$t("投庄洗码量"),
              "min-width": "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "playercountSum",
              label: _vm.$t("投闲笔数"),
              "min-width": "220",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "playeramountSum",
              label: _vm.$t("投闲输赢"),
              "min-width": "200",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "playercomSum",
              label: _vm.$t("投闲洗码量"),
              "min-width": "200",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }