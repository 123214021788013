"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _channelMng = require("@/api/account/channelMng");
var _common = require("@/api/common");
var _rsaEncrypt = require("@/utils/rsaEncrypt");
var _elementUi = require("element-ui");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _this;
var _default = exports.default = {
  name: 'channelMng',
  props: {},
  data: function data() {
    var _this2 = this;
    var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/;
    return {
      editType: 0,
      accountForm: {
        username: '',
        nickname: '',
        password: '',
        passwordSure: '',
        channel: '',
        status: ''
      },
      dialog: false,
      loading: false,
      tableData: {
        list: [],
        total: 0,
        totalPage: 1
      },
      queryForm: {
        page: 1,
        limit: 10,
        beginTime: '',
        endTime: '',
        userName: '',
        status: 1,
        islock: '',
        channel: ''
      },
      rules: {
        username: [{
          required: true,
          message: _this.$t('请输入'),
          trigger: 'blur'
        }, {
          min: 6,
          max: 12,
          message: _this.$t('6-12位英文+数字'),
          trigger: 'blur'
        }],
        nickname: [{
          required: true,
          message: _this.$t('请输入'),
          trigger: 'blur'
        }, {
          min: 6,
          max: 12,
          message: _this.$t('6-12位英文+数字'),
          trigger: 'blur'
        }],
        password: [{
          required: this.editType == 0 ? true : false,
          validator: function validator(rule, value, callback) {
            if (_this2.editType == 0 && value === '') {
              callback(new Error(_this.$t('请输入')));
            } else if (_this2.editType == 0 && !reg.test(value)) {
              callback(new Error(_this.$t('6-12位英文+数字')));
            } else {
              if (_this2.accountForm.passwordSure !== '') {
                _this2.$refs.addForm.validateField('passwordSure');
              }
              callback();
            }
          },
          trigger: 'blur'
        }],
        passwordSure: [{
          required: this.editType == 0 ? true : false,
          validator: function validator(rule, value, callback) {
            if (_this2.editType == 0 && value === '') {
              callback(new Error(_this.$t('请输入')));
            } else if (_this2.editType == 0 && !reg.test(value)) {
              callback(new Error(_this.$t('6-12位英文+数字')));
            } else if (value !== _this2.accountForm.passwordSure) {
              callback(new Error(_this.$t('passWord.tips4')));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }],
        channel: [{
          required: true,
          message: _this.$t('请输入'),
          trigger: 'blur'
        }, {
          min: 2,
          max: 8,
          message: _this.$t('2-10位英文+数字'),
          trigger: 'blur'
        }]
      },
      channelList: []
    };
  },
  computed: {},
  watch: {},
  beforeCreate: function beforeCreate() {
    _this = this;
  },
  mounted: function mounted() {
    this.queryForm.page = 1;
    this.getChannelList();
    this.getList();
  },
  methods: {
    onSearch: function onSearch() {
      this.queryForm.page = 1;
      this.getList();
    },
    getList: function getList() {
      var _this3 = this;
      this.loading = true;
      var params = _objectSpread({}, this.queryForm);
      if (!params.beginTime) {
        params.beginTime = '';
      }
      if (!params.endTime) {
        params.endTime = '';
      }
      (0, _channelMng.channelManageList)(params).then(function (res) {
        _this3.loading = false;
        if (res.code == 200) {
          _this3.tableData = {
            list: res.data,
            total: res.count,
            totalPage: 1
          };
        } else {
          _this3.$message.error(_this3.$t(res.message));
          _this3.tableData = {
            list: [],
            total: 0,
            totalPage: 1
          };
        }
      }).catch(function (err) {
        _this3.loading = false;
        _this3.tableData = {
          list: [],
          total: 0,
          totalPage: 1
        };
      });
    },
    onSave: function onSave() {
      var _this4 = this;
      this.$refs['addForm'].validate(function (valid) {
        if (valid) {
          var loading = _elementUi.Loading.service({
            fullscreen: true
          });
          var reqParams = _objectSpread({}, _this4.accountForm);
          reqParams.password = (0, _rsaEncrypt.encryptWithRsa)(reqParams.password);
          reqParams.passwordSure = (0, _rsaEncrypt.encryptWithRsa)(reqParams.passwordSure);
          if (_this4.editType === 0) {
            (0, _channelMng.addChannel)(reqParams).then(function (res) {
              if (res.code == 200) {
                _this4.$message.success(_this4.$t('操作成功'));
                _this4.dialog = false;
                _this4.onSearch();
              } else {
                _this4.$message.error(_this4.$t(res.message));
              }
            }).catch(function (err) {}).finally(function () {
              _this4.$nextTick(function () {
                loading.close();
              });
            });
          } else if (_this4.editType === 1) {
            (0, _channelMng.updateChannel)(reqParams).then(function (res) {
              if (res.code == 200) {
                _this4.$message.success(_this4.$t('操作成功'));
                _this4.dialog = false;
                _this4.onSearch();
              } else {
                _this4.$message.error(_this4.$t(res.message));
              }
            }).catch(function (err) {}).finally(function () {
              _this4.$nextTick(function () {
                loading.close();
              });
            });
          } else {
            console.log('error function');
            return;
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 页码size 改变
    handleSizeChange: function handleSizeChange(size) {
      setTimeout(function () {
        window.scrollTo({
          left: 0,
          top: 0
        });
      }, 10);
      this.queryForm.limit = size;
      this.queryForm.page = 1;
      this.getList();
    },
    // 分页数据改变
    handleCurrentChange: function handleCurrentChange(val) {
      window.scrollTo({
        left: 0,
        top: 0
      });
      this.queryForm.page = val;
      this.getList();
    },
    // 新增
    onAdd: function onAdd() {
      this.dialog = true;
      this.editType = 0;
      this.accountForm = {
        username: '',
        nickname: '',
        password: '',
        passwordSure: '',
        channel: '',
        status: 1
      };
    },
    // 编辑
    editHandle: function editHandle(item) {
      this.dialog = true;
      this.editType = 1;
      var username = item.username,
        nickname = item.nickname,
        password = item.password,
        passwordSure = item.passwordSure,
        channel = item.channel,
        status = item.status,
        id = item.id;
      this.accountForm = {
        id: id,
        username: username,
        nickname: nickname,
        password: password,
        passwordSure: passwordSure,
        channel: channel,
        status: status
      };
    },
    deleteHandle: function deleteHandle(item) {
      var _this5 = this;
      this.$confirm(this.$t('是否删除渠道') + item.username, this.$t('信息'), {
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        type: 'warning'
      }).then(function () {
        (0, _channelMng.deleteChannel)({
          id: item.id
        }).then(function (res) {
          if (res) {
            _this5.$message.success(_this5.$t('操作成功'));
            _this5.getList();
          } else {
            _this5.$message.error(_this5.$t(res.message));
          }
        });
      }).catch(function (err) {});
    },
    // 取消
    cancelForm: function cancelForm() {
      this.dialog = false;
      if (this.editType == 0) {
        this.accountForm = {
          username: '',
          nickname: '',
          password: '',
          passwordSure: '',
          channel: '',
          status: 1
        };
      }
    },
    // 解锁渠道
    unlockHandle: function unlockHandle(item) {
      var _this6 = this;
      this.$confirm(this.$t('是否解锁渠道') + item.username, this.$t('信息'), {
        confirmButtonText: this.$t('确定'),
        cancelButtonText: this.$t('取消'),
        type: 'warning'
      }).then(function () {
        (0, _channelMng.unLockChannel)({
          id: item.id,
          islock: 0
        }).then(function (res) {
          if (res) {
            _this6.$message.success(_this6.$t('操作成功'));
            _this6.getList();
          } else {
            _this6.$message.error(_this6.$t(res.message));
          }
        });
      }).catch(function (err) {});
    },
    // 获取所有渠道
    getChannelList: function getChannelList() {
      var _this7 = this;
      (0, _common.getChannel)().then(function (res) {
        if (res.code == 200) {
          _this7.channelList = res.data;
        } else {
          _this7.$message.error(_this7.$t(res.message));
          _this7.channelList = [];
        }
      }).catch(function (err) {
        _this7.channelList = [];
      });
    }
  }
};