"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _gameResultsReport = require("@/api/report/gameResultsReport");
var _common = require("@/api/common");
var _gameProperties = require("@/utils/gameProperties");
var _vuex = require("vuex");
var _downFiles = require("@/utils/downFiles");
var _elementUi = require("element-ui");
var _lodash = _interopRequireDefault(require("lodash"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 游戏结果报表
var _default = exports.default = {
  name: 'gameResultsReport',
  props: {},
  data: function data() {
    return {
      tableIdsList: [],
      channelList: [],
      currencyList: [],
      loading: false,
      tableData: {
        list: [],
        total: 0,
        totalPage: 1
      },
      totalList: [],
      queryForm: {
        page: 1,
        limit: 10,
        gameBeginTime: '',
        gameEndTime: '',
        tableIds: '',
        shoeNum: '',
        channelIds: '',
        currency: 0,
        dealer: ''
      }
    };
  },
  computed: _objectSpread({}, (0, _vuex.mapGetters)(['isAdmin'])),
  watch: {},
  beforeCreate: function beforeCreate() {},
  mounted: function mounted() {
    this.queryForm.page = 1;
    this.queryForm.gameBeginTime = this.formatTime(new Date(), 'Y-M-D');
    this.getList();
    this.getChannelList();
    this.getCurrencyList();
    this.getTableIdsList();
  },
  methods: {
    getTableName: _gameProperties.getTableName,
    goodRoadType: _gameProperties.goodRoadType,
    renderPlay: _gameProperties.renderPlay,
    renderResult: _gameProperties.renderResult,
    renderCurrency: _gameProperties.renderCurrency,
    onSelectAll: function onSelectAll() {
      this.queryForm.channelIds = this.channelList.map(function (val) {
        return val.value;
      });
    },
    editData: function editData(data) {
      var _this = this;
      if (!data) return [];
      var arr = [];
      data.forEach(function (item) {
        var model = _objectSpread({}, item);
        if (model.goodRoad != '') model.goodroad = (0, _gameProperties.goodRoadType)(model.goodroad, model.goodroadcount, model.tableNum);
        if (model.currency) model.currency = (0, _gameProperties.renderCurrency)(model.currency);
        if (model.result) model.result = _this.renderResult(model);
        if (model.tableNum) model.tableNum = (0, _gameProperties.getTableName)(model.tableNum);
        arr.push(model);
      });
      return arr;
    },
    onCounterElection: function onCounterElection() {
      var old = this.queryForm.channelIds;
      this.queryForm.channelIds = this.channelList.filter(function (val) {
        var one = old.find(function (item) {
          return item == val.value;
        });
        if (!one) {
          return val.value;
        }
      }).map(function (val) {
        return val.value;
      });
    },
    handleOpenPage: function handleOpenPage(row) {
      this.$router.push({
        name: 'gameRecord',
        params: {
          gid: row.gid
        }
      });
    },
    handleCreate: function handleCreate(row) {
      var params = {
        gid: row.gid,
        gameNum: row.gamenum
      };
      (0, _gameResultsReport.getGameResult)(params).then(function (res) {
        if (res.code == 200) {
          window.open('https://www.yaxin55.com/history/road/?data=' + res.data);
        }
      }).catch(function (err) {});
    },
    onSearch: function onSearch() {
      this.queryForm.page = 1;
      this.getList();
    },
    onOutput: function onOutput() {
      var _this2 = this;
      var loading = _elementUi.Loading.service({
        fullscreen: true
      });
      var params = this.parseParam(this.getParams());
      (0, _gameResultsReport.output)(params).then(function (res) {
        if (res.code == 200) {
          var columns = _this2.createColums();
          var data = _this2.editData(res.data);
          (0, _downFiles.downFiles)(data, columns, 'gameResultsReport');
        } else {
          _this2.$message.error(_this2.$t(res.message));
        }
      }).catch(function (err) {}).finally(function () {
        _this2.$nextTick(function () {
          loading.close();
        });
      });
    },
    createColums: function createColums() {
      var admin = [{
        "label": this.$t('局id'),
        "key": "gid",
        "control_type": "TEXT"
      }, {
        "label": this.$t('渠道'),
        "key": "channel",
        "control_type": "TEXT"
      }, {
        "label": this.$t('靴数'),
        "key": "shoenum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('局数'),
        "key": "gamenum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('开牌时间'),
        "key": "starttime",
        "control_type": "TEXT"
      }, {
        "label": this.$t('结果'),
        "key": "result",
        "control_type": "TEXT"
      }, {
        "label": this.$t('牌型'),
        "key": "cards",
        "control_type": "TEXT"
      }, {
        "label": this.$t('统计输赢金额'),
        "key": "amount",
        "control_type": "TEXT"
      }, {
        "label": this.$t('统计投庄次数'),
        "key": "bankercount",
        "control_type": "TEXT"
      }, {
        "label": this.$t('统计投庄洗码金额'),
        "key": "bankercom",
        "control_type": "TEXT"
      }, {
        "label": this.$t('统计投庄输赢金额'),
        "key": "bankeramount",
        "control_type": "TEXT"
      }, {
        "label": this.$t('统计投闲次数'),
        "key": "playercount",
        "control_type": "TEXT"
      }, {
        "label": this.$t('统计投闲洗码金额'),
        "key": "playercom",
        "control_type": "TEXT"
      }, {
        "label": this.$t('统计投闲输赢金额'),
        "key": "playeramount",
        "control_type": "TEXT"
      }, {
        "label": this.$t('好路类型'),
        "key": "goodroad",
        "control_type": "TEXT"
      }, {
        "label": this.$t('荷官'),
        "key": "dealer",
        "control_type": "TEXT"
      }, {
        "label": this.$t('类型'),
        "key": "tableNum",
        "control_type": "TEXT"
      }, {
        "label": this.$t('闲家牌'),
        "key": "playerCard",
        "control_type": "TEXT"
      }, {
        "label": this.$t('庄家牌'),
        "key": "bankerCard",
        "control_type": "TEXT"
      }, {
        "label": this.$t('币种'),
        "key": "currency",
        "control_type": "TEXT"
      }, {
        "label": this.$t('庄ev值'),
        "key": "bankerEv",
        "control_type": "TEXT"
      }, {
        "label": this.$t('闲ev值'),
        "key": "playerEv",
        "control_type": "TEXT"
      }];
      return admin;
    },
    parseParam: function parseParam(param) {
      var paramStr = '';
      Object.keys(param).forEach(function (key, index) {
        if (index === 0) {
          paramStr += key + "=" + encodeURIComponent(param[key]);
        } else {
          paramStr += "&" + key + "=" + encodeURIComponent(param[key]);
        }
      });
      return paramStr;
    },
    toDecimal: function toDecimal(x) {
      var a = parseFloat(x);
      if (isNaN(a)) {
        return false;
      }
      var f = Math.round(x * 100) / 100;
      var s = f.toString();
      var rs = s.indexOf('.');
      if (rs < 0) {
        rs = s.length;
        s += '.';
      }
      while (s.length <= rs + 2) {
        s += '0';
      }
      return s;
    },
    getParams: function getParams() {
      var obj = {};
      var keyArr = ['gameBeginTime', 'gameEndTime', 'tableIds', 'channelIds'];
      for (var i in this.queryForm) {
        if (keyArr.includes(i) && (!this.queryForm[i] || this.queryForm[i] instanceof Array && this.queryForm[i].length == 0)) {
          obj[i] = '';
        } else {
          obj[i] = this.queryForm[i];
        }
      }
      if (obj['tableIds']) {
        var str = obj['tableIds'];
        var arr = str.toString().split(',');
        var arr1 = [];
        arr.forEach(function (item, index) {
          if (index % 2 == 1) {
            arr1.push(item);
          }
        });
        obj['tableIds'] = arr1;
      }
      obj['channelIds'] = obj['channelIds'].toString();
      obj['tableIds'] = obj['tableIds'].toString();
      var time = obj['gameBeginTime'];
      if (obj['gameBeginTime']) obj['gameBeginTime'] = this.formatTime(new Date(time), 'Y-M-D') + " 12:00:00";
      obj['gameEndTime'] = this.formatTime(new Date(new Date(time).getTime() + 24 * 60 * 60 * 1000 - 1), 'Y-M-D') + " 11:59:59";
      return obj;
    },
    formatNumber: function formatNumber(n) {
      n = n.toString();
      return n[1] ? n : '0' + n;
    },
    formatTime: function formatTime(time, format) {
      var newArr = [];
      var formatArr = ['Y', 'M', 'D', 'h', 'm', 's'];
      newArr.push(time.getFullYear());
      newArr.push(this.formatNumber(time.getMonth() + 1));
      newArr.push(this.formatNumber(time.getDate()));
      newArr.push(this.formatNumber(time.getHours()));
      newArr.push(this.formatNumber(time.getMinutes()));
      newArr.push(this.formatNumber(time.getSeconds()));
      for (var i in newArr) {
        format = format.replace(formatArr[i], newArr[i]);
      }
      return format;
    },
    getCurrencyList: function getCurrencyList() {
      var _this3 = this;
      (0, _common.getCurrency)().then(function (res) {
        if (res.code == 200) {
          _this3.currencyList = res.data.map(function (val) {
            val.name = (0, _gameProperties.renderCurrency)(val.name);
            return val;
          });
        } else {
          _this3.$message.error(_this3.$t(res.message));
          _this3.currencyList = [];
        }
      }).catch(function (err) {
        _this3.currencyList = [];
      });
    },
    getList: function getList() {
      var _this4 = this;
      this.loading = true;
      var pa = this.getParams();
      var params = this.parseParam(pa);
      (0, _gameResultsReport.getList)(params).then(function (res) {
        _this4.loading = false;
        if (res.code == 200) {
          _this4.tableData = {
            list: res.data,
            total: res.count,
            totalPage: 1
          };
          var form = new FormData();
          for (var i in pa) {
            form.append(i, pa[i]);
          }
          _this4.getSumList(form);
        } else {
          _this4.$message.error(_this4.$t(res.message));
          _this4.tableData = {
            list: [],
            total: 0,
            totalPage: 1
          };
        }
      }).catch(function (err) {
        _this4.loading = false;
        _this4.tableData = {
          list: [],
          total: 0,
          totalPage: 1
        };
      });
    },
    getSumList: function getSumList(params) {
      var _this5 = this;
      (0, _gameResultsReport.getSumList)(params).then(function (res) {
        if (res.code == 200) {
          _this5.totalList = res.data;
        } else {
          _this5.$message.error(_this5.$t(res.message));
          _this5.totalList = [];
        }
      }).catch(function (err) {
        _this5.totalList = [];
      });
    },
    // 页码size 改变
    handleSizeChange: function handleSizeChange(size) {
      setTimeout(function () {
        window.scrollTo({
          left: 0,
          top: 0
        });
      }, 10);
      this.queryForm.limit = size;
      this.queryForm.page = 1;
      this.getList();
    },
    // 分页数据改变
    handleCurrentChange: function handleCurrentChange(val) {
      window.scrollTo({
        left: 0,
        top: 0
      });
      this.queryForm.page = val;
      this.getList();
    },
    getChannelList: function getChannelList() {
      var _this6 = this;
      (0, _common.getChannelIds)().then(function (res) {
        if (res.code == 200) {
          _this6.channelList = res.data;
        } else {
          _this6.$message.error(_this6.$t(res.message));
          _this6.channelList = [];
        }
      }).catch(function (err) {
        _this6.channelList = [];
      });
    },
    getTableIdsList: function getTableIdsList() {
      var _this7 = this;
      (0, _common.getTableIds)().then(function (res) {
        if (res.code == 200) {
          _this7.tableIdsList = (0, _gameProperties.deepgreeTree)(_lodash.default.cloneDeep(res.data));
        } else {
          _this7.$message.error(_this7.$t(res.message));
          _this7.tableIdsList = [];
        }
      }).catch(function (err) {
        _this7.tableIdsList = [];
      });
    }
  }
};