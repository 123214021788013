var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "search" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.queryForm, inline: true },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      clearable: "",
                      size: "small",
                      placeholder: _vm.$t("账户名"),
                    },
                    model: {
                      value: _vm.queryForm.userName,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "userName", $$v)
                      },
                      expression: "queryForm.userName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("账号状态") },
                      model: {
                        value: _vm.queryForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "status", $$v)
                        },
                        expression: "queryForm.status",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("全部"), value: "" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("可用"), value: 1 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("不可用"), value: 0 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: _vm.$t("锁定状态") },
                      model: {
                        value: _vm.queryForm.islock,
                        callback: function ($$v) {
                          _vm.$set(_vm.queryForm, "islock", $$v)
                        },
                        expression: "queryForm.islock",
                      },
                    },
                    [
                      _c("el-option", {
                        attrs: { label: _vm.$t("全部"), value: "" },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("正常"), value: 0 },
                      }),
                      _c("el-option", {
                        attrs: { label: _vm.$t("锁定"), value: 1 },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      "default-time": "12:00:00",
                      placeholder: _vm.$t("开始时间"),
                    },
                    model: {
                      value: _vm.queryForm.beginTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "beginTime", $$v)
                      },
                      expression: "queryForm.beginTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("el-date-picker", {
                    attrs: {
                      type: "datetime",
                      "default-time": "11:59:59",
                      "value-format": "yyyy-MM-dd HH:mm:ss",
                      placeholder: _vm.$t("结束时间"),
                    },
                    model: {
                      value: _vm.queryForm.endTime,
                      callback: function ($$v) {
                        _vm.$set(_vm.queryForm, "endTime", $$v)
                      },
                      expression: "queryForm.endTime",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-search",
                        size: "mini",
                      },
                      on: { click: _vm.onSearch },
                    },
                    [_vm._v(_vm._s(_vm.$t("搜索")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-plus",
                        size: "mini",
                      },
                      on: { click: _vm.onAdd },
                    },
                    [_vm._v(_vm._s(_vm.$t("新增代理")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          attrs: {
            border: "",
            "cell-style": { borderColor: "#e6e6e6" },
            "header-cell-style": {
              background: "#f97802",
              height: "45px",
              color: "rgba(255, 255, 255, 1)",
              borderColor: "#e6e6e6",
            },
            "highlight-current-row": "",
            data: _vm.tableData.list,
            height: "480px",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              prop: "username",
              label: _vm.$t("账户名"),
              "min-width": "120",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "nickname",
              label: _vm.$t("昵称"),
              "min-width": "120",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "channel",
              label: _vm.$t("渠道"),
              "min-width": "100",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "agent",
              label: _vm.$t("代理"),
              "min-width": "140",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "createTime",
              label: _vm.$t("创建时间"),
              "min-width": "200",
              sortable: "",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: _vm.$t("账号状态"),
              "min-width": "80",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status == 1
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("可用")))])
                      : scope.row.status == 0
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.$t("不可用"))),
                        ])
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: _vm.$t("锁定状态"),
              "min-width": "80",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.islock == 1
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(_vm.$t("锁定"))),
                        ])
                      : scope.row.islock == 0
                      ? _c("span", [_vm._v(_vm._s(_vm.$t("正常")))])
                      : _c("span", [_vm._v("-")]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("操作"),
              "min-width": "180",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.islock == 1
                      ? _c(
                          "div",
                          {
                            staticClass: "btn-self-primary",
                            on: {
                              click: function ($event) {
                                return _vm.unlockHandle(scope.row)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.$t("解锁")))]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass: "btn-self-primary",
                        on: {
                          click: function ($event) {
                            return _vm.editHandle(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("修改")))]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "btn-self-danger",
                        on: {
                          click: function ($event) {
                            return _vm.deleteHandle(scope.row)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("删除")))]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination-content" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.queryForm.page,
              "page-sizes": [10, 20, 30, 40, 50],
              "page-size": _vm.queryForm.limit,
              total: parseInt(_vm.tableData.total),
              layout: "total, sizes, prev, pager, next, jumper",
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            "append-to-body": "",
            "close-on-click-modal": false,
            visible: _vm.dialog,
            title: _vm.editType == 0 ? _vm.$t("添加代理") : _vm.$t("编辑代理"),
            width: "400px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                model: _vm.accountForm,
                size: "small",
                "label-width": "100px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("账户名"), prop: "username" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("6-12位英文+数字"),
                      clearable: "",
                      disabled: _vm.editType === 1,
                    },
                    model: {
                      value: _vm.accountForm.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.accountForm, "username", $$v)
                      },
                      expression: "accountForm.username",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("昵称"), prop: "nickname" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("6-12位英文+数字+汉字"),
                      clearable: "",
                    },
                    model: {
                      value: _vm.accountForm.nickname,
                      callback: function ($$v) {
                        _vm.$set(_vm.accountForm, "nickname", $$v)
                      },
                      expression: "accountForm.nickname",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("密码"), prop: "password" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("6-12位英文+数字"),
                      "show-password": "",
                      clearable: "",
                      type: "password",
                    },
                    model: {
                      value: _vm.accountForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.accountForm, "password", $$v)
                      },
                      expression: "accountForm.password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("确认密码"), prop: "passwordSure" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: _vm.$t("6-12位英文+数字"),
                      "show-password": "",
                      clearable: "",
                      type: "password",
                    },
                    model: {
                      value: _vm.accountForm.passwordSure,
                      callback: function ($$v) {
                        _vm.$set(_vm.accountForm, "passwordSure", $$v)
                      },
                      expression: "accountForm.passwordSure",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("代理"), prop: "agent" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        placeholder: _vm.$t("请选择"),
                        clearable: "",
                        filterable: "",
                        disabled: _vm.editType === 1,
                      },
                      model: {
                        value: _vm.accountForm.agent,
                        callback: function ($$v) {
                          _vm.$set(_vm.accountForm, "agent", $$v)
                        },
                        expression: "accountForm.agent",
                      },
                    },
                    _vm._l(_vm.channelList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.name },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { "margin-bottom": "0" },
                  attrs: { label: _vm.$t("是否可用"), prop: "status" },
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.accountForm.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.accountForm, "status", $$v)
                        },
                        expression: "accountForm.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v(_vm._s(_vm.$t("可用"))),
                      ]),
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v(_vm._s(_vm.$t("不可用"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "center" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancelForm } }, [
                _vm._v(_vm._s(_vm.$t("取消"))),
              ]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSave } },
                [_vm._v(_vm._s(_vm.$t("保存")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }