"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.betFailReport = betFailReport;
exports.betRefund = betRefund;
exports.getList = getList;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取列表数据
function getList(params) {
  return (0, _request.default)({
    url: '/yx/betFailReport/list',
    method: 'GET',
    params: params
  });
}

// 退款
function betRefund(params) {
  return (0, _request.default)({
    url: '/yx/betFailReport/refund',
    method: 'GET',
    showLoading: true,
    params: params
  });
}

// 重新结算
function betFailReport(params) {
  return (0, _request.default)({
    url: '/yx/betFailReport/rollin',
    method: 'GET',
    showLoading: true,
    params: params
  });
}