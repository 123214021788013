"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.function.name");
var _requireIcons = _interopRequireDefault(require("./requireIcons"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'IconSelect',
  data: function data() {
    return {
      name: '',
      iconList: _requireIcons.default
    };
  },
  methods: {
    filterIcons: function filterIcons() {
      var _this = this;
      this.iconList = _requireIcons.default;
      if (this.name) {
        this.iconList = this.iconList.filter(function (item) {
          return item.includes(_this.name);
        });
      }
    },
    selectedIcon: function selectedIcon(name) {
      this.$emit('selected', name);
      document.body.click();
    },
    reset: function reset() {
      this.name = '';
      this.iconList = _requireIcons.default;
    }
  }
};