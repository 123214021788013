"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getList = getList;
exports.update = update;
var _request = _interopRequireDefault(require("@/utils/request"));
// 获取列表数据
function getList() {
  return (0, _request.default)({
    url: '/yx/exchangeRate/list',
    method: 'GET'
  });
}

// 修改汇率
function update(data) {
  return (0, _request.default)({
    url: '/yx/exchangeRate/update',
    method: 'POST',
    data: data
  });
}