"use strict";

var _interopRequireDefault = require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.object.get-own-property-descriptors");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _defineProperty2 = _interopRequireDefault(require("/home/jenkins/agent/workspace/test-agent-pc/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _vuex = require("vuex");
function _createForOfIteratorHelper(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (!t) { if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var _n = 0, F = function F() {}; return { s: F, n: function n() { return _n >= r.length ? { done: !0 } : { done: !1, value: r[_n++] }; }, e: function e(r) { throw r; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var o, a = !0, u = !1; return { s: function s() { t = t.call(r); }, n: function n() { var r = t.next(); return a = r.done, r; }, e: function e(r) { u = !0, o = r; }, f: function f() { try { a || null == t.return || t.return(); } finally { if (u) throw o; } } }; }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  name: 'AppMain',
  data: function data() {
    return {
      pagesList: []
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)({
    emptyState: function emptyState(state) {
      return state.app.showEmptyState;
    },
    tabList: function tabList(state) {
      return state.tabList.list;
    }
  })), {}, {
    key: function key() {
      return this.$route.path;
    }
  }),
  watch: {
    tabList: {
      handler: function handler(newValue, oldValue) {
        this.getDiff(newValue, oldValue);
      },
      deep: true
    }
  },
  mounted: function mounted() {},
  methods: {
    getDiff: function getDiff(newValue, oldValue) {
      var _this = this;
      if (oldValue.length > newValue.length) {
        var arr = oldValue.filter(function (item) {
          return !newValue.includes(item);
        });
        arr.forEach(function (val) {
          _this.removeCache(val);
        });
      }
    },
    removeCache: function removeCache() {
      var view = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
      var vnode = this.getVnode();
      if (!vnode) return false;
      var componentInstance = vnode.parent.componentInstance;
      var keyStart = vnode.key.split('/')[0];
      var regKey = "".concat(keyStart).concat(view.path);
      this.closeSelectedTag({
        componentInstance: componentInstance,
        regKey: regKey
      });
    },
    getVnode: function getVnode() {
      if (this.$children.length == 0) return false;
      var vnode;
      var _iterator = _createForOfIteratorHelper(this.$children),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          if (item.$vnode.data.key) {
            vnode = item.$vnode;
            break;
          }
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      return vnode ? vnode : false;
    },
    closeSelectedTag: function closeSelectedTag(_ref) {
      var componentInstance = _ref.componentInstance,
        regKey = _ref.regKey;
      var reg = new RegExp("^".concat(regKey));
      Object.keys(componentInstance.cache).forEach(function (key, i) {
        if (regKey === key) {
          if (componentInstance.cache[key]) {
            componentInstance.cache[key].componentInstance.$destroy();
          }
          delete componentInstance.cache[key];
          componentInstance.keys.splice(i, 1);
        }
      });
    }
  }
};